import { useCardController, useDialog } from '@datanac/datanac-mui-toolkit'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import { Box, FormControl, Grid, InputLabel, Select, Stack, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { AppContext } from 'App'
import BudgetCountyCard from 'components/Budget/components/BudgetCountyCard'
import { BUDGET_STATUS_DRAFT, getBudgetYears } from 'components/Budget/helpers'
import EmptyCard from 'components/Cards/EmptyCard'
import { propUpdateReducer } from 'components/Core/propUpdateReducer'
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import { BudgetIcon } from 'components/Icons/Icons'
import { useBlockerPrompt } from 'components/Menu/useBlockerPrompt'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import _ from 'lodash'
import { useFormStatus } from 'mui-toolkit/useFormStatus'
import { useSnackbar } from 'notistack'
import { useCallback, useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatNumber, formatPrice } from 'utility/formatters'
import { v4 as uuidv4 } from 'uuid'
import { UsersApiHelper } from '../../api/ApiHelper'

export default function BudgetWorkspace() {
    const { globalState, globalDispatch, session } = useContext(AppContext);

    const { budget_id } = useParams();
    const navigate = useNavigate();

    const isXL = useMediaQuery((theme) => theme.breakpoints.up('xl'), { noSsr: true });
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const { component: formStatusComponent, actions: formStatusActions, state: formStatusState } = useFormStatus();

    const [state, dispatch] = useReducer(propUpdateReducer, {
        status: BUDGET_STATUS_DRAFT,
        is_active: true
    });
    const { state: countyCards, actions: countyCardActions } = useCardController();

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const isValid = useCallback(() => {
        return (Boolean(
            state?.id
            && state?.name
            && state?.year
        ));
    }, [state]);
    useBlockerPrompt(!isValid(), "BudgetWorkspace");

    const loadBudgetData = async (_budget_id) => {
        UsersApiHelper.selectBudgets({ id: _budget_id, is_active: true, }, globalState).then(currentBudget => {
            if (currentBudget?.length) {
                dispatch({
                    type: 'updateMany',
                    payload: currentBudget[0]
                });
            }
        });

        UsersApiHelper.selectBudgetCountyPractices({
            budget_id: _budget_id, is_active: true,
        }, globalState)
            .then(currentBudgetCountyPractices => {
                countyCardActions.replace(currentBudgetCountyPractices.filter(cp => cp.is_active));
            })
            .catch(err => {
                console.warn(err);
                enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
            });
    }

    useEffect(() => {
        if (budget_id == 0) {
            dispatch({
                type: 'update',
                payload: { key: "id", value: uuidv4() },
            });
            setIsLoading(false);
        } else {
            setIsLoading(true);
            loadBudgetData(budget_id).then(() => {
                setIsLoading(false);
            });
            dispatch({
                type: 'update',
                payload: { key: "id", value: budget_id },
            });
        }
    }, [globalState?.producer_token, budget_id]);

    const handleChangeStateSelect = (event) => {
        formStatusActions.setIsFormDirty(true, false);

        dispatch({
            type: 'update',
            payload: { key: event.target.name, value: event.target.value },
        });
    }

    const handleChangeState = (event, value) => {
        value = event.target.value

        if (state[event.target.name] != value) {
            formStatusActions.setIsFormDirty(true, false);

            dispatch({
                type: 'update',
                payload: { key: event.target.name, value: value },
            });
        }
    }

    useEffect(() => {
        if (isValid() && globalState?.producer_token && formStatusState?.isFormDirty) {
            formStatusActions.setIsFormDirty(true, true);

            UsersApiHelper.updateBudget({ ...state, checksum: null }, globalState)
                .then(() => {
                    formStatusActions.setIsSaving(false);
                })
                .catch(err => {
                    console.warn(err);
                    formStatusActions.setIsFormDirty(true, false);
                    enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
                });
        }
    }, [state]);

    const handleCardOnChange = async (_newState) => {
        await UsersApiHelper.updateBudgetCountyPractice(_newState, globalState);

        loadBudgetData(_newState.budget_id);
    }

    const handleAddNewClick = () => {
        navigate(`/budget/${state?.id}/0`);
    }

    return (
        <>
            <WorkspaceHeader
                title={state?.name || ""}
                breadCrumbs={[
                    {
                        title: 'Budgets',
                        path: '/budget',
                        icon: <BudgetIcon />
                    },
                ]}
                actions={null}
            />

            <Stack direction="row" spacing={2} className='headerWithMenuButton'>
                <Typography variant='h1'>
                    Budget Details
                </Typography>
                {formStatusComponent}
            </Stack>
            <Grid container spacing={1}>
                <Grid item xs={6} md={3}>
                    <FormControl fullWidth={true}>
                        <StatefulControlledTextField
                            label="Budget Name"
                            name="name"
                            isRequired={true}
                            defaultValue={state.name || ''}
                            variant="outlined"
                            onChange={handleChangeState}
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={2}>
                    <FormControl fullWidth>
                        <InputLabel id='selectYearLabel'>Year</InputLabel>
                        <Select
                            labelId='selectYearLabel'
                            value={state?.year || ''}
                            name={"year"}
                            onChange={handleChangeStateSelect}
                            label={'Year'}
                        >
                            {getBudgetYears()?.map((currentItem, index) => {
                                return <MenuItem key={index} value={currentItem}>{currentItem}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Divider />

            <Typography variant='h1'>
                Crops
            </Typography>

            <Box className='cardContainer'>
                {countyCards.map((currentBudgetCountyPractice, index) =>
                    <BudgetCountyCard key={index} budgetCountyPractice={currentBudgetCountyPractice} onChange={handleCardOnChange} />
                )}

                {!Boolean(countyCards?.length) &&
                    <EmptyCard text="Start by adding a new crop to this budget" isLoading={isLoading} />
                }
            </Box>

            <Box className="pageBreakBefore" sx={{
                ...(isXL && {
                    top: 'auto', bottom: 0, pb: 2, position: 'sticky',
                    width: "100%", backgroundColor: 'white', zIndex: 1000
                })
            }}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Grid container justifyItems='flex-start' spacing={1}>
                    <Grid item xs={12} md={6} xl={1}>
                        <Typography variant='h1'>
                            Totals
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.5}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatNumber(_.sumBy(countyCards, 'reported_acreage'), 2)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Acres</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.5} sx={{ backgroundColor: '#f5f5f5' }}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatPrice(
                                    _.sum(countyCards.map(c => c.revenue_simple)) / _.sum(countyCards.map(c => c.reported_acreage))
                                    , 0)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Rev/Acre</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.7} sx={{ backgroundColor: '#f5f5f5' }}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatPrice(
                                    _.sum(countyCards.map(c => c.revenue_simple))
                                    , 0)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Revenue</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.5}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatPrice(
                                    _.sum(countyCards.map(c => c.production_cost)) / _.sum(countyCards.map(c => c.reported_acreage))
                                    , 0)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Cost/Acre</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.7}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatPrice(
                                    _.sum(countyCards.map(c => c.production_cost))
                                    , 0)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Cost</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.5} sx={{ backgroundColor: '#f5f5f5' }}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatPrice(
                                    (_.sum(countyCards.map(c => (c.revenue_simple - c.production_cost))) / _.sum(countyCards.map(c => c.reported_acreage)))
                                    , 0)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Net Income/Acre</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.5} sx={{ backgroundColor: '#f5f5f5' }}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5' sx={{ fontWeight: 'bold !important' }}>
                                {formatPrice(
                                    _.sum(countyCards.map(c => (c.revenue_simple - c.production_cost)))
                                    , 0)}
                            </Typography>
                            <Typography variant='body1' sx={{ fontWeight: 'bold !important', textTransform: isSmall ? '' : 'uppercase' }}>Net Income</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>

            <WorkspaceActionBar content={
                <Button variant="contained"
                    disabled={!isValid()}
                    onClick={handleAddNewClick}
                >
                    <AddCircleOutline sx={{ mr: 1 }} />
                    New Crop
                </Button>
            } />

            {confirmDialogComponent}
        </>
    )
}
