import { useDialog } from '@datanac/datanac-mui-toolkit'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import { Box, CircularProgress, Divider, Grid, Menu, MenuItem, Stack, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { AppContext } from 'App'
import { UsersApiHelper } from 'api/ApiHelper'
import { getBudgetYears } from 'components/Budget/helpers'
import { MASK_NUMERIC, StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import { FinancialIcon } from 'components/Icons/Icons'
import InputDropdownPair from 'components/InputDropdownPair'
import InputTextPair from 'components/InputTextPair'
import { MODULE_FINANCIAL } from 'components/Menu/NavigationMenu'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { SkeletonWrapper } from 'mui-toolkit/SkeletonWrapper'
import { useFormStatus } from 'mui-toolkit/useFormStatus'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatNumber, formatPercentage, formatPrice } from 'utility/formatters'
import { v4 as uuidv4 } from 'uuid'
import { OBJECT_NAME_LOAN_ORIGINATION_MEMO } from './FinancialHelpers'
import { ITEM_NAME_BALANCE_SHEET_EQUITY_RETAINED_CAPITAL } from './forms/BalanceSheetHelper'
import useFinanceReport from './useFinanceReport'

export default function LoanOriginationMemoPage() {
    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();
    const {
        producer_token, year
    } = useParams();

    const isPrint = useMediaQuery('print');
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true }) && !isPrint;
    const { enqueueSnackbar } = useSnackbar();
    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const { component: formStatusComponent, actions: formStatusActions } = useFormStatus();

    const [currentYear, setCurrentYear] = useState(year || globalState?.crop_year);

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || err, { preventDuplicate: true, variant: 'error' });
    }

    const {
        isLoading,

        isLoadingBudget,
        budget,

        isLoadingArcPlc,
        arcPlc,

        isLoadingInsuranceScenario,
        insuranceScenario,

        isLoadingBalanceSheet,
        balanceSheet,

        isLoadingIncomeStatement,
        incomeStatement,

        isLoadingLoanOriginationMemo,
        loanOriginationMemo,
        loadLoanOriginationMemo,

        isLoadingDebtRatios,
        debtRatios,

        isLoadingRatios,
        ratios,
    } = useFinanceReport({
        onError: handleError,
        currentYear,

        producerState: producer_token ? { ...globalState, producer_token } : null
    })

    // ---

    const handleChangeState_LineItem_Select = async (event, _value, _currentDocumentDictionary) => {
        const newEvent = {
            target: {
                name: event?.target?.name,
                value: _value
            }
        };
        handleChangeState_LineItem(newEvent, _currentDocumentDictionary);
    }

    const handleChangeState_LineItem = async (event, _currentDocumentDictionary) => {
        if (event?.target?.value != loanOriginationMemo?.values.find(v => v.id == _currentDocumentDictionary?.id)?.value) {
            formStatusActions.setIsFormDirty(true, true);

            if (!_currentDocumentDictionary) {
                // New dictionary entry:
                _currentDocumentDictionary = {
                    document_id: loanOriginationMemo?.document_id,
                    section: "",
                    module: MODULE_FINANCIAL,
                    object: OBJECT_NAME_LOAN_ORIGINATION_MEMO,
                    container: "",
                    item: event?.target?.name,
                    value: null
                };
            }

            const _newValue = {
                ..._currentDocumentDictionary,
                id: _currentDocumentDictionary?.id || uuidv4(),
                value: event?.target?.value || 0
            }

            const _uploadFormData = {
                producer_token: globalState.producer_token,
                agent_token: globalState.agent_token,
                Document: {
                    id: _newValue?.document_id || uuidv4(),
                    values: [_newValue]
                }
            };

            await UsersApiHelper.updateFinancialDocument(loanOriginationMemo, globalState);

            UsersApiHelper.updateDocumentDictionary(_uploadFormData, globalState)
                .then(() => {
                    formStatusActions.setIsSaving(false);
                    loadLoanOriginationMemo();
                })
                .catch(err => {
                    console.warn(err);
                    formStatusActions.setIsFormDirty(true, false);
                    enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
                });
        }
    }

    // ---

    const [perAcreMultiplier, setPerAcreMultiplier] = useState(0);
    useEffect(() => {
        if (budget?.reported_acreage && budget?.reported_acreage != 0) {
            setPerAcreMultiplier(1 / budget.reported_acreage);
        }
    }, [budget?.reported_acreage])

    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'crop_year',
                value: currentYear
            },
        });
    }, [currentYear])

    // ---

    const [activeProducer, setActiveProducer] = useState();
    const selectActiveProducer = async () => {
        let _producer = await UsersApiHelper.users_selectObject("Member", { producer_token: globalState.producer_token }, globalState);
        if (_producer?.length) {
            setActiveProducer(_producer[0]);
        }
    };

    useEffect(() => {
        selectActiveProducer();
    }, [globalState?.producer_token])

    // --- --- ---

    return (
        <>
            <WorkspaceHeader
                title='Loan Origination Memo'
                breadCrumbs={[
                    {
                        title: 'Loan Book',
                        path: '/lender/loan_book',
                        icon: <FinancialIcon />
                    },
                ]}
            />

            <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton navigation'>
                <Typography variant='h1'>
                    Year
                </Typography>
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <>
                            <Button variant="outlined" {...bindTrigger(popupState)}>
                                {currentYear} <ArrowDropDownOutlined />
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                {getBudgetYears().map((year, index) =>
                                    <MenuItem key={index} onClick={() => {
                                        setCurrentYear(year);
                                        popupState.close();
                                    }}>
                                        {year}
                                    </MenuItem>)}
                            </Menu>
                        </>
                    )}
                </PopupState>
                {(isLoadingLoanOriginationMemo || isLoadingBudget || isLoadingBalanceSheet || isLoadingIncomeStatement
                    || isLoadingArcPlc
                    || isLoadingRatios || isLoadingDebtRatios
                ) && <CircularProgress />}
            </Stack>

            <Grid container columnSpacing={2} rowSpacing={2} className='reportTable gridded'>
                <Grid item xs={12} sm={4}>
                    <Stack direction="column" spacing={0.5}>
                        <InputTextPair label="CECL Rating" value={
                            !Boolean(debtRatios?.kpiGrades?.expected_loss?.value) ? "--" :
                                debtRatios?.kpiGrades?.expected_loss?.grade
                        } isLoading={isLoadingDebtRatios} disabled={true} isRequired={true} doFetch={false} />
                        <InputTextPair label="CECL EL" value={
                            !Boolean(debtRatios?.kpiGrades?.expected_loss?.value) ? "--" :
                                formatPercentage(debtRatios?.kpiGrades?.expected_loss?.value, 2)
                        } disabled={true} isLoading={isLoadingDebtRatios} />
                        <InputTextPair label="CECL PD" value={
                            !Boolean(debtRatios?.kpiGrades?.probability_of_default?.value) ? "--" :
                                formatNumber(debtRatios?.kpiGrades?.probability_of_default, 2)
                        } disabled={true} isLoading={isLoadingDebtRatios} />
                        <InputTextPair label="CECL LGD" value={
                            !Boolean(debtRatios?.kpiGrades?.loss_given_default?.value) ? "--" :
                                formatNumber(debtRatios?.kpiGrades?.loss_given_default, 2)
                        } disabled={true} isLoading={isLoadingDebtRatios} />
                    </Stack>

                    <Divider />

                    <Stack direction="column" spacing={0.5}>
                        <InputTextPair label="Year" value={String(currentYear)} disabled={true} inputProps={{ sx: { textAlign: "right" } }} />
                        <InputTextPair label="Current Ratio" value={formatNumber(balanceSheet?.current_ratio, 2)} disabled={true} isLoading={isLoadingBalanceSheet} inputProps={{ sx: { textAlign: "right" } }} />
                        <InputTextPair label="Working Capital" value={formatNumber(balanceSheet?.working_capital)} disabled={true} isLoading={isLoadingBalanceSheet} inputProps={{ sx: { textAlign: "right" } }} />
                        <InputTextPair label="WC / Gross Revenue" value={formatPercentage(ratios?.working_capital_to_gross_revenue_ratio)} disabled={true} isLoading={isLoadingRatios} inputProps={{ sx: { textAlign: "right" } }} />
                        <InputTextPair label="WC / Loan Request" value={formatPercentage(ratios?.working_capital_to_loan_value_ratio)} disabled={true} isLoading={isLoadingRatios} inputProps={{ sx: { textAlign: "right" } }} />
                        <InputTextPair label="Cash Flow" value={formatPrice(ratios?.cash_flow)} isLoading={isLoadingRatios} disabled={true} inputProps={{ sx: { textAlign: "right" } }} />
                        <InputTextPair label="Reqd Principal & Interest" value={formatPrice(ratios?.excess_cash_flow)} disabled={true} isLoading={isLoadingRatios} inputProps={{ sx: { textAlign: "right" } }} />
                        <InputTextPair label="CF / Required P&I + T" value={formatNumber(ratios?.excess_cash_flow_ratio) + "x"} disabled={true} isLoading={isLoadingRatios} inputProps={{ sx: { textAlign: "right" } }} />
                        <InputTextPair label="Net Cash Flow" value={formatPrice(ratios?.net_cash_flow)} isLoading={isLoadingRatios} disabled={true} inputProps={{ sx: { textAlign: "right" } }} />
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Stack direction="column" spacing={0.5}>
                        <InputTextPair label="Purpose" name="purpose"
                            value={loanOriginationMemo?.purpose} isLoading={isLoadingLoanOriginationMemo}
                            onChange={(e) => handleChangeState_LineItem(e, loanOriginationMemo?.values?.find(v => v.item == "purpose"))} />
                        <InputTextPair label="Request" value={formatPrice(budget?.expenses?.total)} isLoading={isLoadingBudget} disabled={true} inputProps={{ sx: { textAlign: "right" } }} />
                        <InputTextPair label="Projected Acres" value={formatNumber(budget?.reported_acreage, 0)} isLoading={isLoadingBudget} disabled={true} inputProps={{ sx: { textAlign: "right" } }} />
                        <InputTextPair label="Request/Acre" value={formatPrice(budget?.expenses?.total * perAcreMultiplier)} disabled={true} isLoading={isLoadingBudget} inputProps={{ sx: { textAlign: "right" } }} />
                        <InputTextPair label="Funded Interim" name="funded_interim"
                            value={loanOriginationMemo?.funded_interim} isLoading={isLoadingLoanOriginationMemo}
                            onChange={(e) => handleChangeState_LineItem(e, loanOriginationMemo?.values?.find(v => v.item == "funded_interim"))}
                            inputProps={{ sx: { textAlign: "right" } }} />
                        <InputTextPair label="Prior Year Carry Over" name="prior_year_carry_over"
                            value={loanOriginationMemo?.prior_year_carry_over} isLoading={isLoadingLoanOriginationMemo}
                            onChange={(e) => handleChangeState_LineItem(e, loanOriginationMemo?.values?.find(v => v.item == "prior_year_carry_over"))}
                            inputProps={{ sx: { textAlign: "right" } }} />
                        <InputTextPair label="Interest Rate" name="interest_rate"
                            value={loanOriginationMemo?.interest_rate} isLoading={isLoadingLoanOriginationMemo}
                            onChange={(e) => handleChangeState_LineItem(e, loanOriginationMemo?.values?.find(v => v.item == "interest_rate"))}
                            inputProps={{ sx: { textAlign: "right" } }} />

                        <Stack direction="row" spacing={2}>
                            <InputTextPair label="Floor" name="interest_rate_floor"
                                value={loanOriginationMemo?.interest_rate_floor} isLoading={isLoadingLoanOriginationMemo}
                                onChange={(e) => handleChangeState_LineItem(e, loanOriginationMemo?.values?.find(v => v.item == "interest_rate_floor"))}
                                inputProps={{ sx: { textAlign: "right" } }} />
                            <InputTextPair label="Ceiling" name="interest_rate_ceiling"
                                value={loanOriginationMemo?.interest_rate_ceiling} isLoading={isLoadingLoanOriginationMemo}
                                onChange={(e) => handleChangeState_LineItem(e, loanOriginationMemo?.values?.find(v => v.item == "interest_rate_ceiling"))}
                                inputProps={{ sx: { textAlign: "right" } }} />
                        </Stack>

                        <InputDropdownPair label="Adjusted" name="adjusted" doFetch={false}
                            value={loanOriginationMemo?.adjusted} isLoading={isLoadingLoanOriginationMemo}
                            onChange={(e, v) => handleChangeState_LineItem_Select(e, v, loanOriginationMemo?.values?.find(v => v.item == "adjusted"))}
                            values={[
                                { value: "Daily" },
                                { value: "Monthly" },
                            ]} />
                        <InputTextPair label="Fees" name="fees"
                            value={loanOriginationMemo?.fees} isLoading={isLoadingLoanOriginationMemo}
                            onChange={(e) => handleChangeState_LineItem(e, loanOriginationMemo?.values?.find(v => v.item == "fees"))}
                            maskType={MASK_NUMERIC}
                            inputProps={{ sx: { textAlign: "right" } }} />
                        <InputDropdownPair label="Maturity" name="maturity" doFetch={false}
                            value={loanOriginationMemo?.maturity} isLoading={isLoadingLoanOriginationMemo}
                            onChange={(e, v) => handleChangeState_LineItem_Select(e, v, loanOriginationMemo?.values?.find(v => v.item == "maturity"))}
                            values={[
                                { value: "12 Months" },
                                { value: "18 Months" },
                            ]} />
                        <InputDropdownPair label="Payment Type" name="payment_type" doFetch={false}
                            value={loanOriginationMemo?.payment_type} isLoading={isLoadingLoanOriginationMemo}
                            onChange={(e, v) => handleChangeState_LineItem_Select(e, v, loanOriginationMemo?.values?.find(v => v.item == "payment_type"))}
                            values={[
                                { value: "Principal & Interest" },
                                { value: "Interest Only" },
                            ]} />
                        <InputDropdownPair label="Frequency" name="frequency"
                            value={loanOriginationMemo?.frequency} isLoading={isLoadingLoanOriginationMemo}
                            onChange={(e, v) => handleChangeState_LineItem_Select(e, v, loanOriginationMemo?.values?.find(v => v.item == "frequency"))}
                            values={[
                                { value: "At Maturity" },
                            ]} />
                        <InputDropdownPair label="Amortization" name="amortization"
                            value={loanOriginationMemo?.amortization} isLoading={isLoadingLoanOriginationMemo}
                            onChange={(e, v) => handleChangeState_LineItem_Select(e, v, loanOriginationMemo?.values?.find(v => v.item == "amortization"))}
                            values={[
                                { value: "None" },
                            ]} />
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Stack direction="column" spacing={0.5}>
                        <InputTextPair label="Date" name="date"
                            value={loanOriginationMemo?.date} isLoading={isLoadingLoanOriginationMemo}
                            onChange={(e) => handleChangeState_LineItem(e, loanOriginationMemo?.values?.find(v => v.item == "date"))} />
                        <InputTextPair label="Loan Officer" name="loan_officer"
                            value={loanOriginationMemo?.loan_officer} isLoading={isLoadingLoanOriginationMemo}
                            onChange={(e) => handleChangeState_LineItem(e, loanOriginationMemo?.values?.find(v => v.item == "loan_officer"))} />

                        <Divider />

                        <InputTextPair label="Borrower" value={activeProducer?.business_name
                            ? activeProducer?.business_name
                            : activeProducer?.first_name + " " + activeProducer?.last_name
                        } isLoading={!Boolean(activeProducer)} disabled={true} />
                        <InputTextPair label="FICO" name="fico"
                            value={loanOriginationMemo?.fico} isLoading={isLoadingLoanOriginationMemo}
                            onChange={(e) => handleChangeState_LineItem(e, loanOriginationMemo?.values?.find(v => v.item == "fico"))} />
                        <InputTextPair label="Address" value={activeProducer?.address1} isLoading={!Boolean(activeProducer)} disabled={true} />
                        <InputTextPair label="" value={activeProducer?.city + ", " + activeProducer?.state_abbreviation} isLoading={!Boolean(activeProducer)} disabled={true} />
                        <InputTextPair label="Phone" value={activeProducer?.phone} isLoading={!Boolean(activeProducer)} disabled={true} />

                        <Divider />

                        <InputTextPair label="Co-Borrower" name="co_borrower_name"
                            value={loanOriginationMemo?.co_borrower_name} isLoading={isLoadingLoanOriginationMemo}
                            onChange={(e) => handleChangeState_LineItem(e, loanOriginationMemo?.values?.find(v => v.item == "co_borrower_name"))} />
                        <InputTextPair label="FICO" name="co_borrower_fico"
                            value={loanOriginationMemo?.co_borrower_fico} isLoading={isLoadingLoanOriginationMemo}
                            onChange={(e) => handleChangeState_LineItem(e, loanOriginationMemo?.values?.find(v => v.item == "co_borrower_fico"))} />
                        <InputTextPair label="Address" name="co_borrower_address1"
                            value={loanOriginationMemo?.co_borrower_address1} isLoading={isLoadingLoanOriginationMemo}
                            onChange={(e) => handleChangeState_LineItem(e, loanOriginationMemo?.values?.find(v => v.item == "co_borrower_address1"))} />
                        <InputTextPair label="" name="co_borrower_address2"
                            value={loanOriginationMemo?.co_borrower_address2} isLoading={isLoadingLoanOriginationMemo}
                            onChange={(e) => handleChangeState_LineItem(e, loanOriginationMemo?.values?.find(v => v.item == "co_borrower_address2"))} />
                        <InputTextPair label="Phone" name="co_borrower_phone"
                            value={loanOriginationMemo?.co_borrower_phone} isLoading={isLoadingLoanOriginationMemo}
                            onChange={(e) => handleChangeState_LineItem(e, loanOriginationMemo?.values?.find(v => v.item == "co_borrower_phone"))} />
                    </Stack>
                </Grid>

                <Grid item xs={12} className=''>
                    <Grid container columnSpacing={2} rowSpacing={0} className='reportTable'>
                        <Grid item xs={12} sm={6}>
                            <Table className='reportTable'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            <Box sx={{ display: "flex" }}>
                                                <Box sx={{ flex: 1 }}>
                                                    <Typography variant="h6">
                                                        Farm Operations Projection
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    <Typography>$ / acre</Typography>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ borderBottom: "1px solid #c0c0c0" }}>
                                            Projected Crop Income
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: "1px solid #c0c0c0" }}>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(budget?.revenue, 0)} isLoading={isLoadingBudget}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell align="right" sx={{ borderBottom: "1px solid #c0c0c0" }}>
                                            <SkeletonWrapper isLoading={isLoadingBudget} fullWidth>
                                                <Typography variant='subtitle2' noWrap>
                                                    {formatPrice(budget?.revenue * perAcreMultiplier, 2)}
                                                </Typography>
                                            </SkeletonWrapper>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            Crop Insurance Guarantee
                                        </TableCell>
                                        <TableCell>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(insuranceScenario?.totals?.liability_amount, 0)} isLoading={isLoadingInsuranceScenario}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <SkeletonWrapper isLoading={isLoadingInsuranceScenario || isLoadingBudget} fullWidth>
                                                <Typography variant='subtitle2' noWrap>
                                                    {formatPrice(insuranceScenario?.totals?.liability_amount * perAcreMultiplier, 2)}
                                                </Typography>
                                            </SkeletonWrapper>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            ARC/PLC Projected Payments
                                        </TableCell>
                                        <TableCell>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(arcPlc?.totals?.programElections?.totalPayment, 0)} isLoading={isLoadingArcPlc}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <SkeletonWrapper isLoading={isLoadingArcPlc || isLoadingArcPlc} fullWidth>
                                                <Typography variant='subtitle2' noWrap>
                                                    {formatPrice(arcPlc?.totals?.programElections?.totalPayment * perAcreMultiplier, 2)}
                                                </Typography>
                                            </SkeletonWrapper>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            Livestock Income
                                        </TableCell>
                                        <TableCell>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(incomeStatement?.revenue_livestock?.total, 0)} isLoading={isLoadingIncomeStatement}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <SkeletonWrapper isLoading={isLoadingIncomeStatement || isLoadingBudget} fullWidth>
                                                <Typography variant='subtitle2' noWrap>
                                                    {formatPrice(incomeStatement?.revenue_livestock?.total * perAcreMultiplier, 2)}
                                                </Typography>
                                            </SkeletonWrapper>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            Other Income
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: "1px solid #c0c0c0 !important" }}>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(incomeStatement?.revenue_other?.total + incomeStatement?.revenue_other_operations?.total, 0)} isLoading={isLoadingIncomeStatement}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <SkeletonWrapper isLoading={isLoadingIncomeStatement} fullWidth>
                                                <Typography variant='subtitle2' noWrap>
                                                    {formatPrice((incomeStatement?.revenue_other?.total + incomeStatement?.revenue_other_operations?.total) * perAcreMultiplier, 2)}
                                                </Typography>
                                            </SkeletonWrapper>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow sx={{ borderBottom: "2px solid #c0c0c0" }}>
                                        <TableCell>
                                            Total Revenues
                                        </TableCell>
                                        <TableCell>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(ratios?.revenue_guaranteed, 0)} isLoading={isLoadingRatios}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <SkeletonWrapper isLoading={isLoadingRatios} fullWidth>
                                                <Typography variant='subtitle2' noWrap>
                                                    {formatPrice(ratios?.revenue_guaranteed * perAcreMultiplier, 2)}
                                                </Typography>
                                            </SkeletonWrapper>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            Operating Expense
                                        </TableCell>
                                        <TableCell>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(ratios?.expenses_operations, 0)} isLoading={isLoadingRatios}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <SkeletonWrapper isLoading={isLoadingRatios} fullWidth>
                                                <Typography variant='subtitle2' noWrap>
                                                    {formatPrice(ratios?.expenses_operations * perAcreMultiplier, 2)}
                                                </Typography>
                                            </SkeletonWrapper>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            Interest Expense
                                        </TableCell>
                                        <TableCell>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(incomeStatement?.expenses_interest?.total, 0)} isLoading={isLoadingIncomeStatement}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <SkeletonWrapper isLoading={isLoadingIncomeStatement} fullWidth>
                                                <Typography variant='subtitle2' noWrap>
                                                    {formatPrice(incomeStatement?.expenses_interest?.total * perAcreMultiplier, 2)}
                                                </Typography>
                                            </SkeletonWrapper>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            Term Debt Service
                                        </TableCell>
                                        <TableCell>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(incomeStatement?.expenses_debt_service?.total, 0)} isLoading={isLoadingIncomeStatement}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <SkeletonWrapper isLoading={isLoadingIncomeStatement} fullWidth>
                                                <Typography variant='subtitle2' noWrap>
                                                    {formatPrice(incomeStatement?.expenses_debt_service?.total * perAcreMultiplier, 2)}
                                                </Typography>
                                            </SkeletonWrapper>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            Living Expenses
                                        </TableCell>
                                        <TableCell>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(incomeStatement?.expenses_living_expenses?.total, 0)} isLoading={isLoadingIncomeStatement}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <SkeletonWrapper isLoading={isLoadingIncomeStatement} fullWidth>
                                                <Typography variant='subtitle2' noWrap>
                                                    {formatPrice(incomeStatement?.expenses_living_expenses?.total * perAcreMultiplier, 2)}
                                                </Typography>
                                            </SkeletonWrapper>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            Taxes
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: "1px solid #c0c0c0 !important" }}>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(incomeStatement?.expenses_taxes?.total, 0)} isLoading={isLoadingIncomeStatement}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <SkeletonWrapper isLoading={isLoadingIncomeStatement} fullWidth>
                                                <Typography variant='subtitle2' noWrap>
                                                    {formatPrice(incomeStatement?.expenses_taxes?.total * perAcreMultiplier, 2)}
                                                </Typography>
                                            </SkeletonWrapper>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow sx={{ borderBottom: "2px solid #c0c0c0" }}>
                                        <TableCell>
                                            Total Expenses
                                        </TableCell>
                                        <TableCell>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(budget?.expenses?.total + incomeStatement?.expenses?.total, 0)} isLoading={isLoadingIncomeStatement || isLoadingBudget}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <SkeletonWrapper isLoading={isLoadingIncomeStatement || isLoadingBudget} fullWidth>
                                                <Typography variant='subtitle2' noWrap>
                                                    {formatPrice((budget?.expenses?.total + incomeStatement?.expenses?.total) * perAcreMultiplier, 2)}
                                                </Typography>
                                            </SkeletonWrapper>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            Net Farm Income (Guaranteed)
                                        </TableCell>
                                        <TableCell>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(ratios?.net_income_guaranteed)} isLoading={isLoadingRatios}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <SkeletonWrapper isLoading={isLoadingRatios} fullWidth>
                                                <Typography variant='subtitle2' noWrap>
                                                    {formatPrice(ratios?.net_income_guaranteed * perAcreMultiplier, 2)}
                                                </Typography>
                                            </SkeletonWrapper>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Table className='reportTable'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            <Box sx={{ display: "flex" }}>
                                                <Box sx={{ flex: 1 }}>
                                                    <Typography variant="h6">
                                                        Farm Balance Sheet
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                                                    &nbsp;
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell width="50%">
                                            Current Assets
                                        </TableCell>
                                        <TableCell width="40%">
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(balanceSheet?.assets_current?.total, 0)} isLoading={isLoadingBalanceSheet}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell width="10%" align="right">
                                            <SkeletonWrapper isLoading={isLoadingBalanceSheet} fullWidth>
                                                <Typography variant='subtitle2' noWrap>
                                                    {formatPercentage(
                                                        balanceSheet?.assets?.total != 0
                                                            ? balanceSheet?.assets_current?.total / balanceSheet?.assets?.total
                                                            : 0
                                                        , 2)}
                                                </Typography>
                                            </SkeletonWrapper>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            Long Term Assets
                                        </TableCell>
                                        <TableCell>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(balanceSheet?.assets_noncurrent?.total, 0)} isLoading={isLoadingBalanceSheet}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <SkeletonWrapper isLoading={isLoadingBalanceSheet} fullWidth>
                                                <Typography variant='subtitle2' noWrap>
                                                    {formatPercentage(
                                                        balanceSheet?.assets?.total != 0
                                                            ? balanceSheet?.assets_noncurrent?.total / balanceSheet?.assets?.total
                                                            : 0
                                                        , 2)}
                                                </Typography>
                                            </SkeletonWrapper>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            Personal Assets
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: "1px solid #c0c0c0 !important" }}>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(balanceSheet?.assets_personal?.total, 0)} isLoading={isLoadingBalanceSheet}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <SkeletonWrapper isLoading={isLoadingBalanceSheet} fullWidth>
                                                <Typography variant='subtitle2' noWrap>
                                                    {formatPercentage(
                                                        balanceSheet?.assets?.total != 0
                                                            ? balanceSheet?.assets_personal?.total / balanceSheet?.assets?.total
                                                            : 0
                                                        , 2)}
                                                </Typography>
                                            </SkeletonWrapper>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow sx={{ borderBottom: "2px solid #c0c0c0" }}>
                                        <TableCell>
                                            Total Assets
                                        </TableCell>
                                        <TableCell>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(balanceSheet?.assets?.total, 0)} isLoading={isLoadingBalanceSheet}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell>
                                            &nbsp;
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            Current Liabilities
                                        </TableCell>
                                        <TableCell>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(balanceSheet?.liabilities_current?.total, 0)} isLoading={isLoadingBalanceSheet}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <SkeletonWrapper isLoading={isLoadingBalanceSheet} fullWidth>
                                                <Typography variant='subtitle2' noWrap>
                                                    {formatPercentage(
                                                        balanceSheet?.liabilities?.total != 0
                                                            ? balanceSheet?.liabilities_current?.total / balanceSheet?.liabilities?.total
                                                            : 0
                                                        , 2)}
                                                </Typography>
                                            </SkeletonWrapper>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            Long Term Liabilities
                                        </TableCell>
                                        <TableCell>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(balanceSheet?.liabilities_noncurrent?.total, 0)} isLoading={isLoadingBalanceSheet}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <SkeletonWrapper isLoading={isLoadingBalanceSheet} fullWidth>
                                                <Typography variant='subtitle2' noWrap>
                                                    {formatPercentage(
                                                        balanceSheet?.liabilities?.total != 0
                                                            ? balanceSheet?.liabilities_noncurrent?.total / balanceSheet?.liabilities?.total
                                                            : 0
                                                        , 2)}
                                                </Typography>
                                            </SkeletonWrapper>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            Personal Liabilities
                                        </TableCell>
                                        <TableCell>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(balanceSheet?.liabilities_personal?.total, 0)} isLoading={isLoadingBalanceSheet}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <SkeletonWrapper isLoading={isLoadingBalanceSheet} fullWidth>
                                                <Typography variant='subtitle2' noWrap>
                                                    {formatPercentage(
                                                        balanceSheet?.liabilities?.total != 0
                                                            ? balanceSheet?.liabilities_personal?.total / balanceSheet?.liabilities?.total
                                                            : 0
                                                        , 2)}
                                                </Typography>
                                            </SkeletonWrapper>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            Tax Liabilities
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: "1px solid #c0c0c0 !important" }}>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(balanceSheet?.liabilities_tax?.total, 0)} isLoading={isLoadingBalanceSheet}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <SkeletonWrapper isLoading={isLoadingBalanceSheet} fullWidth>
                                                <Typography variant='subtitle2' noWrap>
                                                    {formatPercentage(
                                                        balanceSheet?.liabilities?.total != 0
                                                            ? balanceSheet?.liabilities_tax?.total / balanceSheet?.liabilities?.total
                                                            : 0
                                                        , 2)}
                                                </Typography>
                                            </SkeletonWrapper>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            Total Liabilities
                                        </TableCell>
                                        <TableCell>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(balanceSheet?.liabilities?.total, 0)} sx={{ fontWeight: "bold !important" }} isLoading={isLoadingBalanceSheet}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell>
                                            &nbsp;
                                        </TableCell>
                                    </TableRow>

                                    <TableRow sx={{ borderBottom: "2px solid #c0c0c0" }}>
                                        <TableCell>
                                            Total Equity
                                        </TableCell>
                                        <TableCell>
                                            <StatefulControlledTextField label="" isEditable={false}
                                                defaultValue={formatPrice(balanceSheet?.equity?.total, 0)} sx={{ fontWeight: "bold !important" }} isLoading={isLoadingBalanceSheet}
                                                className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                                        </TableCell>
                                        <TableCell>
                                            &nbsp;
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            <Grid container spacing={1} columnSpacing={4}>
                                                <Grid item xs={12} sm={6}>
                                                    <InputTextPair label="Debt/Equity"
                                                        value={formatPercentage(debtRatios?.debt_to_equity_ratio)} isLoading={isLoadingDebtRatios} disabled={true}
                                                        inputProps={{ sx: { textAlign: "right" } }} />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <InputTextPair label="Debt/Assets"
                                                        value={formatPercentage(balanceSheet?.debt_to_assets_ratio)} isLoading={isLoadingBalanceSheet} disabled={true}
                                                        inputProps={{ sx: { textAlign: "right" } }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <InputTextPair label="Equity/Assets"
                                                        value={formatPercentage(balanceSheet?.equity_to_assets_ratio)} isLoading={isLoadingBalanceSheet} disabled={true}
                                                        inputProps={{ sx: { textAlign: "right" } }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <InputTextPair label="ROA"
                                                        value={formatPercentage(ratios?.return_on_assets_ratio)} isLoading={isLoadingRatios} disabled={true}
                                                        inputProps={{ sx: { textAlign: "right" } }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <InputTextPair label="Owner Equity"
                                                        value={formatPrice(balanceSheet?.equity[ITEM_NAME_BALANCE_SHEET_EQUITY_RETAINED_CAPITAL], 0)} isLoading={isLoadingBalanceSheet}
                                                        inputProps={{ sx: { textAlign: "right" } }} disabled={true}
                                                    />
                                                    {JSON.stringify(balanceSheet?.equity[ITEM_NAME_BALANCE_SHEET_EQUITY_RETAINED_CAPITAL + "_equity"])}
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <InputTextPair label="ROE"
                                                        value={formatPercentage(ratios?.return_on_equity_ratio)} isLoading={isLoadingRatios} disabled={true}
                                                        inputProps={{ sx: { textAlign: "right" } }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <InputTextPair label="Rev/Exp"
                                                        value={formatPercentage(ratios?.operating_expense_ratio)} isLoading={isLoadingRatios} disabled={true}
                                                        inputProps={{ sx: { textAlign: "right" } }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <InputTextPair label="Farm DTI"
                                                        value={formatPercentage(debtRatios?.debt_to_income_ratio)} isLoading={isLoadingDebtRatios} disabled={true}
                                                        inputProps={{ sx: { textAlign: "right" } }} />
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Divider className="navigation" />

            <Grid container columnSpacing={0} rowSpacing={0} className='pageBreakAvoid'>
                <Grid item xs={12} className=''>
                    <Typography variant="h6">
                        Planting Projections & Income
                    </Typography>
                </Grid>

                <Table className='reportTable'>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ textAlign: "right" }}>
                                <Typography variant='subtitle2' noWrap sx={{ textAlign: "left" }}>
                                    County
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <Typography variant='subtitle2' noWrap sx={{ textAlign: "left" }}>
                                    Crop
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "left" }}>
                                <Typography variant='subtitle2' noWrap>
                                    Practice
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                <Typography variant='subtitle2' noWrap>
                                    Acres
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                <Typography variant='subtitle2' noWrap>
                                    Yield
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                <Typography variant='subtitle2' noWrap>
                                    Price
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "left" }}>
                                <Typography variant='subtitle2' noWrap>
                                    Unit
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <Typography variant='subtitle2' noWrap>
                                    Income /acre
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <Typography variant='subtitle2' noWrap>
                                    Projected Income
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {budget?.budgetCountyPractices?.map((cp, index) => <TableRow key={index}>
                            <TableCell sx={{ textAlign: "left" }}>
                                <Typography variant='subtitle2' noWrap>
                                    {cp?.county_name}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "left" }}>
                                <Typography variant='subtitle2' noWrap>
                                    {cp?.commodity_name}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "left" }}>
                                <Typography variant='subtitle2' noWrap>
                                    {cp?.practice_name}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                <Typography variant='subtitle2' noWrap>
                                    {formatNumber(cp?.reported_acreage, 0)}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                <Typography variant='subtitle2' noWrap>
                                    {formatNumber(cp?.yield, 2)}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                <Typography variant='subtitle2' noWrap>
                                    {formatPrice(cp?.projected_price, 2)}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "left" }}>
                                <Typography variant='subtitle2' noWrap>
                                    {cp?.unit_of_measure_abbreviation}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <Typography variant='subtitle2' noWrap>
                                    {formatPrice(cp?.yield * cp?.projected_price, 2)}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <Typography variant='subtitle2' noWrap>
                                    {formatPrice(cp?.yield * cp?.projected_price * cp?.reported_acreage, 0)}
                                </Typography>
                            </TableCell>
                        </TableRow>)}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <TableCell sx={{ textAlign: "right" }}>
                                <Typography variant='subtitle2' noWrap sx={{ textAlign: "left" }}>
                                    Total
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                &nbsp;
                            </TableCell>
                            <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                &nbsp;
                            </TableCell>
                            <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingBudget} fullWidth>
                                    <Typography variant='subtitle2' noWrap sx={{ borderTop: "1px solid #c0c0c0" }}>
                                        {formatNumber(budget?.reported_acreage, 0)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                &nbsp;
                            </TableCell>
                            <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                &nbsp;
                            </TableCell>
                            <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "left" }}>
                                &nbsp;
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingBudget} fullWidth>
                                    <Typography variant='subtitle2' noWrap sx={{ borderTop: "1px solid #c0c0c0" }}>
                                        {budget?.reported_acreage != 0 ? formatPrice(budget?.revenue / budget?.reported_acreage, 2) : "--"}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingBudget} fullWidth>
                                    <Typography variant='subtitle2' noWrap sx={{ borderTop: "1px solid #c0c0c0" }}>
                                        {formatPrice(budget?.revenue, 0)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </Grid>

            <Divider />

            <Grid container columnSpacing={2} rowSpacing={0} className=''>
                <Grid item xs={12} className=''>
                    <Typography variant="h6">
                        Collateral
                    </Typography>
                    <Table className='reportTable'>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    &nbsp;
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                    <Typography variant='subtitle2' sx={{ paddingRight: 0.5 }} noWrap>
                                        Equipment
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                    <Typography variant='subtitle2' sx={{ paddingRight: 0.5 }} noWrap>
                                        Real Estate
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                    <Typography variant='subtitle2' sx={{ paddingRight: 0.5 }} noWrap>
                                        Livestock
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                    <Typography variant='subtitle2' sx={{ paddingRight: 0.5 }} noWrap>
                                        Crop Income
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                    <Typography variant='subtitle2' sx={{ paddingRight: 0.5 }} noWrap>
                                        Govt Payments
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ textAlign: "right" }}>
                                    Total
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    Asset Value
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null }}>
                                    <StatefulControlledTextField label="" className="noMargin" fullWidth
                                        defaultValue={formatPrice(debtRatios?.equipment?.assets)} isLoading={isLoadingDebtRatios} isEditable={false}
                                        inputProps={{ sx: { textAlign: "right" } }} />
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null }}>
                                    <StatefulControlledTextField label="" className="noMargin" fullWidth
                                        defaultValue={formatPrice(debtRatios?.real_estate?.assets)} isLoading={isLoadingDebtRatios} isEditable={false}
                                        inputProps={{ sx: { textAlign: "right" } }} />
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null }}>
                                    <StatefulControlledTextField label="" className="noMargin" fullWidth
                                        defaultValue={formatPrice(debtRatios?.livestock?.assets)} isLoading={isLoadingDebtRatios} isEditable={false}
                                        inputProps={{ sx: { textAlign: "right" } }} />
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null }}>
                                    <StatefulControlledTextField label="" className="noMargin" fullWidth
                                        defaultValue={formatPrice(debtRatios?.crops?.assets)} isLoading={isLoadingDebtRatios} isEditable={false}
                                        inputProps={{ sx: { textAlign: "right" } }} />
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null }}>
                                    <StatefulControlledTextField label="" className="noMargin" fullWidth
                                        defaultValue={formatPrice(debtRatios?.arc_plc?.assets)} isLoading={isLoadingDebtRatios} isEditable={false}
                                        inputProps={{ sx: { textAlign: "right" } }} />
                                </TableCell>
                                <TableCell sx={{ textAlign: "right" }}>
                                    <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                        <Typography variant='subtitle2' noWrap>
                                            {formatPrice(debtRatios?.total?.assets, 0)}
                                        </Typography>
                                    </SkeletonWrapper>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Debt Balance
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null }}>
                                    <StatefulControlledTextField label="" className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }}
                                        defaultValue={formatPrice(debtRatios?.equipment?.liabilities, 0)} isLoading={isLoadingDebtRatios} isEditable={false}
                                    />
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null }}>
                                    <StatefulControlledTextField label="" className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }}
                                        defaultValue={formatPrice(debtRatios?.real_estate?.liabilities, 0)} isLoading={isLoadingDebtRatios} isEditable={false}
                                    />
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null }}>
                                    <StatefulControlledTextField label="" className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }}
                                        defaultValue={formatPrice(debtRatios?.livestock?.liabilities, 0)} isLoading={isLoadingDebtRatios} isEditable={false}
                                    />
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null }}>
                                    <StatefulControlledTextField label="" className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }}
                                        defaultValue={formatPrice(debtRatios?.crops?.liabilities, 0)} isLoading={isLoadingDebtRatios} isEditable={false}
                                    />
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null }}>
                                    <StatefulControlledTextField label="" defaultValue="N/A" className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} isEditable={false} />
                                </TableCell>
                                <TableCell sx={{ textAlign: "right" }}>
                                    <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                        <Typography variant='subtitle2' noWrap>
                                            {formatPrice(debtRatios?.total?.liabilities, 0)}
                                        </Typography>
                                    </SkeletonWrapper>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    LTV
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                    <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                        <Typography variant='subtitle2' sx={{ paddingRight: 0.5 }} noWrap>
                                            {formatPercentage(debtRatios?.equipment?.loan_to_value, 2)}
                                        </Typography>
                                    </SkeletonWrapper>
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                    <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                        <Typography variant='subtitle2' sx={{ paddingRight: 0.5 }} noWrap>
                                            {formatPercentage(debtRatios?.real_estate?.loan_to_value, 2)}
                                        </Typography>
                                    </SkeletonWrapper>
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                    <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                        <Typography variant='subtitle2' sx={{ paddingRight: 0.5 }} noWrap>
                                            {formatPercentage(debtRatios?.livestock?.loan_to_value, 2)}
                                        </Typography>
                                    </SkeletonWrapper>
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                    <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                        <Typography variant='subtitle2' sx={{ paddingRight: 0.5 }} noWrap>
                                            {formatPercentage(debtRatios?.crops?.loan_to_value, 2)}
                                        </Typography>
                                    </SkeletonWrapper>
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                    <Typography variant='subtitle2' sx={{ paddingRight: 0.5 }} noWrap>
                                        N/A
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ textAlign: "right" }}>
                                    <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                        <Typography variant='subtitle2' noWrap>
                                            {formatPercentage(debtRatios?.total?.loan_to_value, 2)}
                                        </Typography>
                                    </SkeletonWrapper>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ borderBottom: "1px solid #c0c0c0" }}>
                                    Equity
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, borderBottom: "1px solid #c0c0c0" }}>
                                    <StatefulControlledTextField label="" className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }}
                                        defaultValue={formatPrice(debtRatios?.equipment?.equity, 0)} isLoading={isLoadingDebtRatios} isEditable={false}
                                    />
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, borderBottom: "1px solid #c0c0c0" }}>
                                    <StatefulControlledTextField label="" className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }}
                                        defaultValue={formatPrice(debtRatios?.real_estate?.equity, 0)} isLoading={isLoadingDebtRatios} isEditable={false}
                                    />
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, borderBottom: "1px solid #c0c0c0" }}>
                                    <StatefulControlledTextField label="" className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }}
                                        defaultValue={formatPrice(debtRatios?.livestock?.equity, 0)} isLoading={isLoadingDebtRatios} isEditable={false}
                                    />
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, borderBottom: "1px solid #c0c0c0" }}>
                                    <StatefulControlledTextField label="" className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }}
                                        defaultValue={formatPrice(debtRatios?.crops?.equity, 0)} isLoading={isLoadingDebtRatios} isEditable={false}
                                    />
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, borderBottom: "1px solid #c0c0c0" }}>
                                    <StatefulControlledTextField label="" className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }}
                                        defaultValue={formatPrice(debtRatios?.arc_plc?.equity, 0)} isLoading={isLoadingDebtRatios} isEditable={false}
                                    />
                                </TableCell>
                                <TableCell sx={{ borderBottom: "1px solid #c0c0c0", textAlign: "right" }}>
                                    <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                        <Typography variant='subtitle2' noWrap>
                                            {formatPrice(debtRatios?.total?.equity, 0)}
                                        </Typography>
                                    </SkeletonWrapper>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    Margin Rate
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                    <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                        <Typography variant='subtitle2' sx={{ paddingRight: 0.5 }} noWrap>
                                            {formatPercentage(debtRatios?.equipment?.margin_rate, 0)}
                                        </Typography>
                                    </SkeletonWrapper>
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                    <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                        <Typography variant='subtitle2' sx={{ paddingRight: 0.5 }} noWrap>
                                            {formatPercentage(debtRatios?.real_estate?.margin_rate, 0)}
                                        </Typography>
                                    </SkeletonWrapper>
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                    <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                        <Typography variant='subtitle2' sx={{ paddingRight: 0.5 }} noWrap>
                                            {formatPercentage(debtRatios?.livestock?.margin_rate, 0)}
                                        </Typography>
                                    </SkeletonWrapper>
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                    <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                        <Typography variant='subtitle2' sx={{ paddingRight: 0.5 }} noWrap>
                                            {formatPercentage(debtRatios?.crops?.margin_rate, 0)}
                                        </Typography>
                                    </SkeletonWrapper>
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                    <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                        <Typography variant='subtitle2' sx={{ paddingRight: 0.5 }} noWrap>
                                            {formatPercentage(debtRatios?.arc_plc?.margin_rate, 0)}
                                        </Typography>
                                    </SkeletonWrapper>
                                </TableCell>
                                <TableCell sx={{ textAlign: "right" }}>
                                    <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                        <Typography variant='subtitle2' noWrap>
                                            {formatPercentage(debtRatios?.total?.margin_rate, 0)}
                                        </Typography>
                                    </SkeletonWrapper>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    Margin Value
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null }}>
                                    <StatefulControlledTextField label="" className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }}
                                        defaultValue={formatPrice(debtRatios?.equipment?.margin_value, 0)} isLoading={isLoadingDebtRatios} isEditable={false}
                                    />
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null }}>
                                    <StatefulControlledTextField label="" className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }}
                                        defaultValue={formatPrice(debtRatios?.real_estate?.margin_value, 0)} isLoading={isLoadingDebtRatios} isEditable={false}
                                    />
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null }}>
                                    <StatefulControlledTextField label="" className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }}
                                        defaultValue={formatPrice(debtRatios?.livestock?.margin_value, 0)} isLoading={isLoadingDebtRatios} isEditable={false}
                                    />
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null }}>
                                    <StatefulControlledTextField label="" className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }}
                                        defaultValue={formatPrice(debtRatios?.crops?.margin_value, 0)} isLoading={isLoadingDebtRatios} isEditable={false}
                                    />
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null }}>
                                    <StatefulControlledTextField label="" className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }}
                                        defaultValue={formatPrice(debtRatios?.arc_plc?.margin_value, 0)} isLoading={isLoadingDebtRatios} isEditable={false}
                                    />
                                </TableCell>
                                <TableCell sx={{ textAlign: "right" }}>
                                    <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                        <Typography variant='subtitle2' noWrap>
                                            {formatPrice(debtRatios?.total?.margin_value, 0)}
                                        </Typography>
                                    </SkeletonWrapper>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    LTMV
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                    <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                        <Typography variant='subtitle2' sx={{ paddingRight: 0.5 }} noWrap>
                                            {formatPercentage(debtRatios?.equipment?.loan_to_margin_value, 2)}
                                        </Typography>
                                    </SkeletonWrapper>
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                    <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                        <Typography variant='subtitle2' sx={{ paddingRight: 0.5 }} noWrap>
                                            {formatPercentage(debtRatios?.real_estate?.loan_to_margin_value, 2)}
                                        </Typography>
                                    </SkeletonWrapper>
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                    <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                        <Typography variant='subtitle2' sx={{ paddingRight: 0.5 }} noWrap>
                                            {formatPercentage(debtRatios?.livestock?.loan_to_margin_value, 2)}
                                        </Typography>
                                    </SkeletonWrapper>
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                    <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                        <Typography variant='subtitle2' sx={{ paddingRight: 0.5 }} noWrap>
                                            {formatPercentage(debtRatios?.crops?.loan_to_margin_value, 2)}
                                        </Typography>
                                    </SkeletonWrapper>
                                </TableCell>
                                <TableCell sx={{ display: isSmall ? "none" : null, textAlign: "right" }}>
                                    <Typography variant='subtitle2' sx={{ paddingRight: 0.5 }} noWrap>
                                        N/A
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ textAlign: "right" }}>
                                    <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                        <Typography variant='subtitle2' noWrap>
                                            {formatPercentage(debtRatios?.total?.loan_to_margin_value, 2)}
                                        </Typography>
                                    </SkeletonWrapper>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>

            <Divider />

            <Grid container columnSpacing={0} rowSpacing={0} className='pageBreakAvoid'>
                <Grid item xs={6} className=''>
                    <Typography variant="h6">
                        CECL Analysis
                    </Typography>
                </Grid>
                <Grid item xs={6} className=''>
                    <Typography variant="h6" sx={{ textAlign: "right" }}>
                        &nbsp;
                    </Typography>
                </Grid>

                <Table className='reportTable'>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h6">
                                    Probability of Default
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", width: "200px" }}>
                                &nbsp;
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", width: "200px", display: isSmall ? "none" : null }}>
                                Grade
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", width: "200px", display: isSmall ? "none" : null }}>
                                Score
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", width: "200px" }}>
                                Weighted Score
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow>
                            <TableCell>
                                FICO Credit Score
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingLoanOriginationMemo} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {loanOriginationMemo?.fico}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", display: isSmall ? "none" : null }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {debtRatios?.kpiGrades?.fico?.grade}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", display: isSmall ? "none" : null }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatNumber(debtRatios?.kpiGrades?.fico?.probability_of_default, 2)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatNumber(debtRatios?.kpiGrades?.fico?.probability_of_default_weighted, 4)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Revenue / Required Payments
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatNumber(ratios?.revenue_to_payments_ratio, 2)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", display: isSmall ? "none" : null }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {debtRatios?.kpiGrades?.revenue_to_payments_ratio?.grade}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", display: isSmall ? "none" : null }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatNumber(debtRatios?.kpiGrades?.revenue_to_payments_ratio?.probability_of_default, 2)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatNumber(debtRatios?.kpiGrades?.revenue_to_payments_ratio?.probability_of_default_weighted, 4)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Cash Flow Available / (Required P&I + Taxes)
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatNumber(ratios?.excess_cash_flow_ratio) + "x"}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", display: isSmall ? "none" : null }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {debtRatios?.kpiGrades?.excess_cash_flow_ratio?.grade}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", display: isSmall ? "none" : null }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatNumber(debtRatios?.kpiGrades?.excess_cash_flow_ratio?.probability_of_default, 2)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatNumber(debtRatios?.kpiGrades?.excess_cash_flow_ratio?.probability_of_default_weighted, 4)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Prior Year Carryover / Total Assets
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatPercentage(debtRatios?.prior_year_carry_over_ratio, 2)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", display: isSmall ? "none" : null }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {debtRatios?.kpiGrades?.prior_year_carry_over_ratio?.grade}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", display: isSmall ? "none" : null }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatNumber(debtRatios?.kpiGrades?.prior_year_carry_over_ratio?.probability_of_default, 2)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatNumber(debtRatios?.kpiGrades?.prior_year_carry_over_ratio?.probability_of_default_weighted, 4)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Equity / Assets
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingBalanceSheet} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatPercentage(balanceSheet?.equity_to_assets_ratio)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", display: isSmall ? "none" : null }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {debtRatios?.kpiGrades?.equity_to_assets_ratio?.grade}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", display: isSmall ? "none" : null }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatNumber(debtRatios?.kpiGrades?.equity_to_assets_ratio?.probability_of_default, 2)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatNumber(debtRatios?.kpiGrades?.equity_to_assets_ratio?.probability_of_default_weighted, 4)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                        </TableRow>
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <TableCell>
                                &nbsp;
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                &nbsp;
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", display: isSmall ? "none" : null }}>
                                &nbsp;
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", display: isSmall ? "none" : null }}>
                                PD Cumulative Score
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography sx={{ borderTop: "1px solid #c0c0c0 !important" }}>
                                        {formatNumber(debtRatios?.kpiGrades?.probability_of_default, 2)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
                <Table className='reportTable'>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h6">
                                    Loss Given Default
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", width: "200px" }}>
                                &nbsp;
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", width: "200px", display: isSmall ? "none" : null }}>
                                Grade
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", width: "200px", display: isSmall ? "none" : null }}>
                                Score
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", width: "200px" }}>
                                Weighted Score
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow>
                            <TableCell>
                                Loan to Value
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatPercentage(debtRatios?.total?.loan_to_value, 2)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", display: isSmall ? "none" : null }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {debtRatios?.kpiGrades?.loan_to_value?.grade}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", display: isSmall ? "none" : null }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatNumber(debtRatios?.kpiGrades?.loan_to_value?.loss_given_default, 2)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatNumber(debtRatios?.kpiGrades?.loan_to_value?.loss_given_default_weighted, 4)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Debt/Equity
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatPercentage(debtRatios?.debt_to_equity_ratio)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", display: isSmall ? "none" : null }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {debtRatios?.kpiGrades?.debt_to_equity_ratio?.grade}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", display: isSmall ? "none" : null }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatNumber(debtRatios?.kpiGrades?.debt_to_equity_ratio?.loss_given_default, 2)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatNumber(debtRatios?.kpiGrades?.debt_to_equity_ratio?.loss_given_default_weighted, 4)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Working Capital / Gross Revenues
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatPercentage(ratios?.working_capital_to_gross_revenue_ratio)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", display: isSmall ? "none" : null }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {debtRatios?.kpiGrades?.working_capital_to_gross_revenue_ratio?.grade}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", display: isSmall ? "none" : null }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatNumber(debtRatios?.kpiGrades?.working_capital_to_gross_revenue_ratio?.loss_given_default, 2)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='subtitle2' noWrap>
                                        {formatNumber(debtRatios?.kpiGrades?.working_capital_to_gross_revenue_ratio?.loss_given_default_weighted, 4)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                        </TableRow>
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <TableCell>
                                &nbsp;
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                &nbsp;
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", display: isSmall ? "none" : null }}>
                                &nbsp;
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", display: isSmall ? "none" : null }}>
                                LGD Cumulative Score
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography sx={{ borderTop: "1px solid #c0c0c0 !important" }}>
                                        {formatNumber(debtRatios?.kpiGrades?.loss_given_default, 2)}
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>

                <Table className='reportTable'>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h6">
                                    Expected Loss
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", width: "200px", display: isSmall ? "none" : null }}>
                                &nbsp;
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", width: "400px", display: isSmall ? "none" : null }} colSpan={2}>
                                Observed risk reserves rate:
                            </TableCell>
                            <TableCell sx={{ textAlign: "right", width: "200px" }}>
                                <SkeletonWrapper isLoading={isLoadingDebtRatios} fullWidth>
                                    <Typography variant='h6' sx={{}}>
                                        {!Boolean(debtRatios?.kpiGrades?.expected_loss?.value) ? "--" :
                                            formatPercentage(debtRatios?.kpiGrades?.expected_loss?.value, 2)
                                        }
                                    </Typography>
                                </SkeletonWrapper>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell sx={{ textAlign: "right" }} colSpan={5}>
                                EL = (PD x LGD)
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </Grid>

            {/* 
            <Divider />

            <Typography variant="h6" className="">
                Historical Planting Production
            </Typography>
            <Table className='reportTable'>
                <TableHead>
                    <TableRow>
                        <TableCell>County</TableCell>
                        <TableCell>Crop</TableCell>
                        <TableCell>Practice</TableCell>

                        <TableCell sx={{}}>{currentYear - 5}</TableCell>
                        <TableCell sx={{}}></TableCell>

                        <TableCell sx={{}}>{currentYear - 4}</TableCell>
                        <TableCell sx={{}}></TableCell>

                        <TableCell sx={{}}>{currentYear - 3}</TableCell>
                        <TableCell sx={{}}></TableCell>

                        <TableCell sx={{}}>{currentYear - 2}</TableCell>
                        <TableCell sx={{}}></TableCell>

                        <TableCell sx={{}}>{currentYear - 1}</TableCell>
                        <TableCell sx={{}}></TableCell>

                        <TableCell sx={{ textAlign: "right" }}>Avg</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Armstrong</TableCell>
                        <TableCell>Cotton</TableCell>
                        <TableCell>Non-Irrigated</TableCell>

                        <TableCell sx={{}}>{currentYear - 5}...</TableCell>
                        <TableCell sx={{}}>...%</TableCell>

                        <TableCell sx={{}}>{currentYear - 4}...</TableCell>
                        <TableCell sx={{}}>...%</TableCell>

                        <TableCell sx={{}}>{currentYear - 3}...</TableCell>
                        <TableCell sx={{}}>...%</TableCell>

                        <TableCell sx={{}}>{currentYear - 2}...</TableCell>
                        <TableCell sx={{}}>...%</TableCell>

                        <TableCell>{currentYear - 1}...</TableCell>
                        <TableCell>...%</TableCell>

                        <TableCell sx={{ textAlign: "right" }}>Avg</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell>&nbsp;</TableCell>

                        <TableCell sx={{}}>{currentYear - 5}...</TableCell>
                        <TableCell sx={{}}>...%</TableCell>

                        <TableCell sx={{}}>{currentYear - 4}...</TableCell>
                        <TableCell sx={{}}>...%</TableCell>

                        <TableCell sx={{}}>{currentYear - 3}...</TableCell>
                        <TableCell sx={{}}>...%</TableCell>

                        <TableCell sx={{}}>{currentYear - 2}...</TableCell>
                        <TableCell sx={{}}>...%</TableCell>

                        <TableCell sx={{}}>{currentYear - 1}...</TableCell>
                        <TableCell sx={{}}>...%</TableCell>

                        <TableCell sx={{ textAlign: "right" }}>....avg</TableCell>
                    </TableRow>


                    <TableRow>
                        <TableCell>Armstrong</TableCell>
                        <TableCell>Corn</TableCell>
                        <TableCell>Irrigated</TableCell>

                        <TableCell sx={{}}>{currentYear - 5}...</TableCell>
                        <TableCell sx={{}}>...%</TableCell>

                        <TableCell sx={{}}>{currentYear - 4}...</TableCell>
                        <TableCell sx={{}}>...%</TableCell>

                        <TableCell sx={{}}>{currentYear - 3}...</TableCell>
                        <TableCell sx={{}}>...%</TableCell>

                        <TableCell sx={{}}>{currentYear - 2}...</TableCell>
                        <TableCell sx={{}}>...%</TableCell>

                        <TableCell sx={{}}>{currentYear - 1}...</TableCell>
                        <TableCell sx={{}}>...%</TableCell>

                        <TableCell sx={{ textAlign: "right" }}>Avg</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell>&nbsp;</TableCell>

                        <TableCell sx={{}}>{currentYear - 5}...</TableCell>
                        <TableCell sx={{}}>...%</TableCell>

                        <TableCell sx={{}}>{currentYear - 4}...</TableCell>
                        <TableCell sx={{}}>...%</TableCell>

                        <TableCell sx={{}}>{currentYear - 3}...</TableCell>
                        <TableCell sx={{}}>...%</TableCell>

                        <TableCell sx={{}}>{currentYear - 2}...</TableCell>
                        <TableCell sx={{}}>...%</TableCell>

                        <TableCell sx={{}}>{currentYear - 1}...</TableCell>
                        <TableCell sx={{}}>...%</TableCell>

                        <TableCell sx={{ textAlign: "right" }}>....avg</TableCell>
                    </TableRow>
                </TableBody>
            </Table> 
            */}

            <WorkspaceActionBar content={<>
            </>} />

            {confirmDialogComponent}
        </>
    )
}
