import EastOutlined from '@mui/icons-material/EastOutlined';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router';

const getNameSummary = params => {
    const farmRow = params.row;

    const nameLabel = farmRow?.business_name ? farmRow.business_name : farmRow.last_name;
    return nameLabel;
}

const getStateCountySummary = params => {
    const farmRow = params.row;

    const label = `${farmRow?.location_county_name}, ${farmRow?.location_state_name}`;
    return label;
}

const getFarmRowSummary = params => {
    const farmRow = params.row;

    const nameLabel = farmRow?.business_name ? farmRow.business_name : farmRow.last_name;
    const commodityLabel = `${farmRow?.commodity_name} ${farmRow?.practice_name}`
    return String(`${nameLabel} ${farmRow.location_county_name} / ${farmRow.fsa_farm_number} T${farmRow.fsa_tract_number} (${farmRow.fsa_field_number}) / ${farmRow.basic_unit_number}-${farmRow.optional_unit_number} / ${commodityLabel}`);
}

export default function ProducerFarmFieldCropDataGrid({
    producerFarmFieldCrops,
}) {
    const navigate = useNavigate();

    const COLUMNS_PRODUCERFARMFIELDCROP = [
        {
            field: 'crop_year',
            headerName: 'Year',
            flex: 1
        },
        {
            field: 'commodity_name',
            headerName: 'Commodity',
            flex: 1
        },
        {
            field: 'type_name',
            headerName: 'Type',
            flex: 1
        },
        {
            field: 'practice_name',
            headerName: 'Practice',
            flex: 1
        },
        {
            field: 'approved_yield',
            headerName: 'APH',
            flex: 1
        },
        {
            field: 'rate_yield',
            headerName: 'Rate',
            flex: 1
        },
        {
            field: 'reported_acreage',
            headerName: 'Acres',
            flex: 1
        },
        {
            field: 'id',
            headerName: '',
            renderCell: (params) => {
                const currentProducerFarmFieldCrop = params.row;
                return <Box display="flex" justifyContent='flex-end'>
                    <IconButton onClick={() => { navigate(`./${currentProducerFarmFieldCrop?.id}`) }}>
                        <EastOutlined />
                    </IconButton>
                </Box>;
            },
            flex: 0
        },
    ];

    const COLUMNS_PRODUCERFARMFIELDCROP_SMALL = [
        {
            field: "fsa_farm_number",
            headerName: 'Unit',
            valueGetter: getFarmRowSummary,
            flex: 1
        },
    ];

    return (
        <>
            <DataGrid
                rows={producerFarmFieldCrops}
                columns={COLUMNS_PRODUCERFARMFIELDCROP}
                initialState={{
                    pagination: { paginationModel: { pageSize: 5 } },
                }}
                pageSizeOptions={[5, 10, 25]}
                autoHeight
            />
        </>
    );
}
