import ErrorOutlineOutlined from '@mui/icons-material/ErrorOutlineOutlined'
import { useMediaQuery } from '@mui/material'
import { AppContext } from 'App'
import EmptyCard from 'components/Cards/EmptyCard'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { useSnackbar } from 'notistack'
import { useContext, useEffect } from 'react'
import { useNavigate, useRouteError } from 'react-router-dom'

export default function ErrorPanel({
    title
}) {

    const { globalState, globalDispatch } = useContext(AppContext);

    const navigate = useNavigate();
    let error = useRouteError();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        console.warn(error);
    }, [error])

    return (
        <>
            <WorkspaceHeader
                title={title}
                breadCrumbs={[]}
            />

            <EmptyCard
                text='An error has occurred'
                secondaryText='Please select an option from the menu or try again.'
                icon={<ErrorOutlineOutlined color="error" fontSize="large" />} />
        </>
    )
}
