import { ProducerFarmHelper } from '@datanac/datanac-api-toolkit'
import { useCardController, useDialog } from '@datanac/datanac-mui-toolkit'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import CloudDownloadOutlined from '@mui/icons-material/CloudDownloadOutlined'
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined'
import PrintOutlined from '@mui/icons-material/PrintOutlined'
import UploadFileOutlined from '@mui/icons-material/UploadFileOutlined'
import { Box, CircularProgress, Dialog, Divider, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { AppContext } from 'App'
import { UsersApiHelper } from 'api/ApiHelper'
import { getBudgetYears } from 'components/Budget/helpers'
import EmptyCard from 'components/Cards/EmptyCard'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProducerFarmFieldCropDialog } from './ProducerFarmFieldCropDialog'
import FarmCountyCard from './components/FarmCountyCard'
import ImportUploadFileDialog from './components/Import/ImportUploadFileDialog'
import ProducerFarmCopyAipPassDialog from './components/Import/ProducerFarmCopyAipPassDialog'
import ProducerFarmCopyDialog from './components/Import/ProducerFarmCopyDialog'
import { getUniqueCounties } from './helpers'

export default function VaultCurrentWorkspace() {
    const producerFarmHelper = new ProducerFarmHelper();

    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [isCopyYearDialogOpen, setIsCopyYearDialogOpen] = useState(false);
    const handleCopyYearDialogClose = (_crop_year) => {
        setIsCopyYearDialogOpen(false);
        if (_crop_year && _crop_year != currentYear) {
            setCurrentYear(_crop_year);
        } else {
            loadCurrent();
        }
    }

    const [isCopyAipPassDialogOpen, setIsCopyAipPassDialogOpen] = useState(false);
    const handleCopyAipPassDialogClose = (_crop_year) => {
        setIsCopyAipPassDialogOpen(false);
        if (_crop_year) {
            setCurrentYear(_crop_year);
        } else {
            loadCurrent();
        }
    }

    const [isUploadFileDialogOpen, setIsUploadFileDialogOpen] = useState(false);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const [currentYear, setCurrentYear] = useState(globalState?.crop_year);

    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'crop_year',
                value: currentYear
            },
        });
    }, [currentYear])

    const [isLoadingCurrent, setIsLoadingCurrent] = useState(true);
    const { state: currentCards, actions: currentActions } = useCardController();
    const loadCurrent = async () => {
        try {
            setIsLoadingCurrent(true);

            const producerFilter = {
                producer_token: globalState.producer_token,
                crop_year: currentYear,
                is_active: true
            };
            const farms = await UsersApiHelper.selectProducerFarms(producerFilter, globalState);
            const countyPractices = getUniqueCounties(farms);
            currentActions.replace(countyPractices);

            setIsLoadingCurrent(false);
        } catch (err) {
            setIsLoadingCurrent(false);
            currentActions.replace([]);
            console.warn(err);
            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'crop_year',
                value: currentYear
            },
        });
    }, [currentYear])

    useEffect(() => {
        if (currentYear && globalState?.producer_token) {
            loadCurrent();
        }
    }, [currentYear, globalState?.producer_token]);

    const handlePrintClick = async () => {
        navigate(`./print/${currentYear}`)
    }

    // ---

    const [isAddCropDialogOpen, setIsAddCropDialogOpen] = useState(false);

    // ---

    return (
        <>
            <WorkspaceHeader
                title='Farm Vault'
                breadCrumbs={[]}
            />

            <Stack direction="row" spacing={2} className='headerWithMenuButton'>
                <Typography variant='h1'>
                    Planted Acres
                </Typography>
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <>
                            <Button variant="outlined" {...bindTrigger(popupState)}>
                                {currentYear} <ArrowDropDownOutlined />
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                {getBudgetYears().map((year, index) =>
                                    <MenuItem key={index} onClick={() => {
                                        setCurrentYear(year);
                                        popupState.close();
                                    }}>
                                        {year}
                                    </MenuItem>)}
                            </Menu>
                        </>
                    )}
                </PopupState>
                {isLoadingCurrent && <CircularProgress />}
            </Stack>

            <Box className='cardContainer'>
                {currentCards.map((currentCountyPractice, index) =>
                    <FarmCountyCard currentCounty={currentCountyPractice} crop_year={currentYear} key={index} />
                )}
                {!Boolean(currentCards?.length) &&
                    <EmptyCard
                        text="No farms listed"
                        secondaryText="Get started by importing history or adding a farm"
                        isLoading={isLoadingCurrent}
                    />}
            </Box>

            <WorkspaceActionBar content={<>
                <Button variant="contained" onClick={() => setIsAddCropDialogOpen(true)}>
                    <AddCircleOutline sx={{ mr: 1 }} />
                    Add {!isSmall && " Crop"}
                </Button>
                {/* {[USER_ROLE_ADMIN, USER_ROLE_AGENT].includes(globalState?.user_role) && */}
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <>
                            <Button variant="contained" {...bindTrigger(popupState)}>
                                <ArrowDropDownOutlined sx={{ mr: 1 }} />
                                Import
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                <MenuItem onClick={() => {
                                    setIsCopyYearDialogOpen(true);
                                    popupState.close();
                                }}>
                                    <ContentCopyOutlined sx={{ mr: 1 }} /> Copy Prior Season ...
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    setIsUploadFileDialogOpen(true);
                                    popupState.close();
                                }}>
                                    <UploadFileOutlined sx={{ mr: 1 }} /> Upload file ...
                                </MenuItem>

                                <Divider />

                                <MenuItem onClick={() => {
                                    setIsCopyAipPassDialogOpen(true);
                                    popupState.close();
                                }}>
                                    <CloudDownloadOutlined sx={{ mr: 1 }} /> Import from History ...
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </PopupState>

                <Button variant="contained" onClick={handlePrintClick}>
                    <PrintOutlined />
                </Button>
            </>} />


            {isAddCropDialogOpen && <Dialog open={isAddCropDialogOpen} PaperProps={{ sx: { minHeight: "5in" } }}>
                <ProducerFarmFieldCropDialog
                    crop_year={currentYear}
                    location_state_name={null}
                    location_county_name={null}
                    onClose={() => { setIsAddCropDialogOpen(false); loadCurrent(); }}
                />
            </Dialog>}


            {isCopyYearDialogOpen && <Dialog open={isCopyYearDialogOpen} fullWidth={isSmall} PaperProps={{ sx: { minHeight: "80vh" } }}>
                <ProducerFarmCopyDialog onClose={handleCopyYearDialogClose} />
            </Dialog>}

            {isUploadFileDialogOpen && <Dialog open={isUploadFileDialogOpen} fullWidth={isSmall} PaperProps={{ sx: {} }}>
                <ImportUploadFileDialog year={currentYear} onClose={() => { setIsUploadFileDialogOpen(false); loadCurrent(); }} />
            </Dialog>}

            {isCopyAipPassDialogOpen && <Dialog open={isCopyAipPassDialogOpen} fullWidth={isSmall} PaperProps={{ sx: { minHeight: "80vh" } }}>
                <ProducerFarmCopyAipPassDialog onClose={handleCopyAipPassDialogClose} />
            </Dialog>}

            {confirmDialogComponent}
        </>
    )
}
