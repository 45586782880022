import { DOCUMENT_FORM_NAME_BALANCE_SHEET } from '@datanac/datanac-api-toolkit';
import { DOCUMENT_FORM_NAME_BASE_ACRES, DOCUMENT_FORM_NAME_DEBT_SCHEDULE, DOCUMENT_FORM_NAME_EQUIPMENT_INVENTORY, DOCUMENT_FORM_NAME_FARMS, DOCUMENT_FORM_NAME_INCOME_STATEMENT, DOCUMENT_FORM_NAME_SCHEDULE_F } from '@datanac/datanac-api-toolkit';
import { CONTENT_TYPE_EXCEL_XLSX, CONTENT_TYPE_PDF } from 'api-toolkit/FilesHelper';

export const DOCUMENT_FORM_NAME_LOAN_ORIGINATION_MEMO = "Loan Origination Memo";
export const OBJECT_NAME_LOAN_ORIGINATION_MEMO = "loan_origination_memo";

export const DOCUMENT_FORM_NAME_INSURANCE_SCHEDULE = "Schedule of Insurance";
export const DOCUMENT_FORM_NAME_ACTUAL_PRODUCTION_HISTORY = "Actual Production History";

export const TEMPLATE_URL_PATH = "templates";

export const DOCUMENT_CATEGORY_FARMS = "farm production";
export const DOCUMENT_CATEGORY_FINANCIAL = "financials";
export const DOCUMENT_CATEGORY_INSURANCE = "crop insurance";
export const DOCUMENT_CATEGORY_TAX = "federal taxes";

export const FINANCIAL_DOCUMENTS = [
    // Farms
    {
        document_type_name: DOCUMENT_FORM_NAME_FARMS,
        title: DOCUMENT_FORM_NAME_FARMS,
        category: DOCUMENT_CATEGORY_FARMS,
        subHeader: "FSA 578",
        templateFileName: "Farms 578 - Template.xlsx",
        content_type: CONTENT_TYPE_EXCEL_XLSX,
    },
    {
        document_type_name: DOCUMENT_FORM_NAME_BASE_ACRES,
        title: DOCUMENT_FORM_NAME_BASE_ACRES,
        category: DOCUMENT_CATEGORY_FARMS,
        subHeader: "CCC 866",
        templateFileName: "Base Acres - Template.xlsx",
        content_type: CONTENT_TYPE_EXCEL_XLSX,
    },

    // Insurance:
    {
        document_type_name: DOCUMENT_FORM_NAME_INSURANCE_SCHEDULE,
        title: DOCUMENT_FORM_NAME_INSURANCE_SCHEDULE,
        category: DOCUMENT_CATEGORY_INSURANCE,
        subHeader: null,
        templateFileName: null,
        content_type: CONTENT_TYPE_PDF,
    },
    {
        document_type_name: DOCUMENT_FORM_NAME_ACTUAL_PRODUCTION_HISTORY,
        title: DOCUMENT_FORM_NAME_ACTUAL_PRODUCTION_HISTORY,
        category: DOCUMENT_CATEGORY_INSURANCE,
        subHeader: "APH Report",
        templateFileName: null,
        content_type: CONTENT_TYPE_PDF,
    },

    // Financial:
    {
        document_type_name: DOCUMENT_FORM_NAME_BALANCE_SHEET,
        title: DOCUMENT_FORM_NAME_BALANCE_SHEET,
        category: DOCUMENT_CATEGORY_FINANCIAL,
        subHeader: null,
        templateFileName: "Balance Sheet - Template.xlsx",
        content_type: CONTENT_TYPE_EXCEL_XLSX,
    },
    {
        document_type_name: DOCUMENT_FORM_NAME_INCOME_STATEMENT,
        title: DOCUMENT_FORM_NAME_INCOME_STATEMENT,
        category: DOCUMENT_CATEGORY_FINANCIAL,
        subHeader: null,
        templateFileName: "Income Statement - Template.xlsx",
        content_type: CONTENT_TYPE_EXCEL_XLSX,
    },
    {
        document_type_name: DOCUMENT_FORM_NAME_DEBT_SCHEDULE,
        title: DOCUMENT_FORM_NAME_DEBT_SCHEDULE,
        category: DOCUMENT_CATEGORY_FINANCIAL,
        subHeader: null,
        templateFileName: "Debt Schedule - Template.xlsx",
        content_type: CONTENT_TYPE_EXCEL_XLSX,
    },
    {
        document_type_name: DOCUMENT_FORM_NAME_EQUIPMENT_INVENTORY,
        title: DOCUMENT_FORM_NAME_EQUIPMENT_INVENTORY,
        category: DOCUMENT_CATEGORY_FINANCIAL,
        subHeader: null,
        templateFileName: "Equipment Inventory - Template.xlsx",
        content_type: CONTENT_TYPE_EXCEL_XLSX,
    },

    // Tax:
    {
        document_type_name: DOCUMENT_FORM_NAME_SCHEDULE_F,
        title: DOCUMENT_FORM_NAME_SCHEDULE_F,
        category: DOCUMENT_CATEGORY_TAX,
        subHeader: "Form 1040",
        templateFileName: "Schedule F - Template.xlsx",
        content_type: CONTENT_TYPE_EXCEL_XLSX,
    },

    // {
    //     document_type_name: "Crop Inventories",
    //     title: "Crop Inventories",
    //     subHeader: "Schedule 1",
    //     templateFileName: "CropInventories-Template.xlsx"
    // },

    // {
    //     document_type_name: "Livestock Inventories",
    //     title: "Livestock Inventories",
    //     subHeader: "Schedule 2",
    //     templateFileName: "LivestockInventories-Template.xlsx"
    // },

    // {
    //     document_type_name: "Equipment Inventories",
    //     title: "Equipment Inventories",
    //     subHeader: "Schedule 3",
    //     templateFileName: "MachineEquipmentInventories-Template.xlsx"
    // },

    // {
    //     document_type_name: "Real Estate Inventories",
    //     title: "Real Estate Inventories",
    //     subHeader: "Schedule 4",
    //     templateFileName: "RealEstateInventories-Template.xlsx"
    // },

    // {
    //     document_type_name: "Deferred Taxes",
    //     title: "Deferred Taxes",
    //     subHeader: "Schedule 6",
    //     templateFileName: "DeferredTaxes-Template.xlsx"
    // },
];