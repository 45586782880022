import { useCardController, useDialog } from '@datanac/datanac-mui-toolkit'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import { Box, CircularProgress, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { AppContext } from 'App'
import { UsersApiHelper } from 'api/ApiHelper'
import { BUDGET_STATUS_ACTIVE, BUDGET_STATUS_DRAFT, getBudgetYears } from 'components/Budget/helpers'
import EmptyCard from 'components/Cards/EmptyCard'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import WorkspaceViewSelector from 'components/Workspaces/WorkspaceModeSelector'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { InsuranceService } from './InsuranceService'
import InsuranceSummaryCard from './components/InsuranceSummaryCard'

export default function InsuranceSummaryWorkspace() {
    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const handleInsuranceServiceError = (err) => {
        console.warn(err);
        // enqueueSnackbar(err.detail || err.message || err, { preventDuplicate: true, variant: 'error' });
    }

    const [currentYear, setCurrentYear] = useState(globalState?.crop_year);

    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'crop_year',
                value: currentYear
            },
        });
    }, [currentYear])

    const { state: insuranceScenarios, actions: insuranceScenarioActions } = useCardController();
    const loadInsuranceScenarios = async () => {
        try {
            setIsLoading(true);

            const producerFilter = {
                producer_token: globalState.producer_token,
                crop_year: currentYear,
                is_active: true
            };
            const _insuranceScenarios = (await UsersApiHelper.users_selectObject("InsuranceScenario", producerFilter, globalState))
                ?.sort((a, b) =>
                    a.name?.localeCompare(b.name)
                );

            if (_insuranceScenarios?.length) {
                const _InsuranceService = new InsuranceService({
                    insuranceScenarios: _insuranceScenarios,
                    crop_year: currentYear,
                    onError: handleInsuranceServiceError,
                    apiConfig: globalState
                });
                const _insuranceScenarioPopulated = await _InsuranceService.actions.loadInsuranceScenario()
                if (_insuranceScenarioPopulated?.length) {
                    insuranceScenarioActions.replace(_insuranceScenarioPopulated);
                } else {
                    insuranceScenarioActions.replace([]);
                    setIsLoading(false);
                }

                const _loadPremiums = async () => {
                    for (var ii = 0; ii < _insuranceScenarioPopulated?.length; ii++) {
                        const _currentInsuranceScenario = _insuranceScenarioPopulated[ii];

                        const calculatePremiumsResponseInsuranceScenario = await _InsuranceService.actions.calculatePremiums(_currentInsuranceScenario);
                        _insuranceScenarioPopulated[ii] = calculatePremiumsResponseInsuranceScenario;
                    }

                    _insuranceScenarioPopulated.forEach(_scenario => {
                        UsersApiHelper.updateInsurancePlans({ InsurancePlans: _scenario?.totals?.insurancePlans }, globalState);
                    })
                }

                if (_insuranceScenarioPopulated?.length) {
                    _loadPremiums()
                        .then(_loadPremiumsResponse => {
                            setIsLoading(false);
                            insuranceScenarioActions.replace(_insuranceScenarioPopulated);
                        })
                        .catch(err => {
                            console.warn(err);
                            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
                        });
                } else {
                    insuranceScenarioActions.replace([]);
                    setIsLoading(false);
                }
            } else {
                insuranceScenarioActions.replace([]);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
            insuranceScenarioActions.replace([]);
            console.warn(err);
            enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
        }
    }

    // --- --- ---

    useEffect(() => {
        if (currentYear && globalState?.producer_token) {
            loadInsuranceScenarios();
        }
    }, [currentYear, globalState?.producer_token]);

    const handleInsuranceScenarioCardOnChange = async (_newInsuranceScenario) => {
        try {
            if (_newInsuranceScenario.status == BUDGET_STATUS_ACTIVE) {
                var previousActive = null;
                insuranceScenarios.forEach(s => {
                    if (s?.status == BUDGET_STATUS_ACTIVE && s?.id != _newInsuranceScenario?.id) {
                        previousActive = s;
                        previousActive.status = BUDGET_STATUS_DRAFT;
                    }
                });
                if (previousActive) {
                    await UsersApiHelper.updateInsuranceScenarios({ InsuranceScenarios: [previousActive] }, globalState);
                }
            }
            await UsersApiHelper.updateInsuranceScenarios({ InsuranceScenarios: [_newInsuranceScenario] }, globalState);
        } catch (err) {
            console.warn(err);
            // formStatusActions.setIsFormDirty(true, false);
            enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
        }

        loadInsuranceScenarios();
    }

    const handleAddNewClick = () => {
        navigate(`./0`);
    }

    return (
        <>
            <WorkspaceHeader
                title='Insurance'
                breadCrumbs={[]}
            />

            <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                <Typography variant='h1'>
                    Scenarios
                </Typography>
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <>
                            <Button variant="outlined" {...bindTrigger(popupState)}>
                                {currentYear} <ArrowDropDownOutlined />
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                {getBudgetYears().map((year, index) =>
                                    <MenuItem key={index} onClick={() => {
                                        setCurrentYear(year);
                                        popupState.close();
                                    }}>
                                        {year}
                                    </MenuItem>)}
                            </Menu>
                        </>
                    )}
                </PopupState>
                <WorkspaceViewSelector workspaceMode={workspaceMode} setWorkspaceMode={setWorkspaceMode} />
                {isLoading && <CircularProgress />}
            </Stack>

            <Box className='cardContainer'>
                {insuranceScenarios.map((currentScenario, index) =>
                    <InsuranceSummaryCard key={index}
                        insuranceScenario={currentScenario}
                        onChange={handleInsuranceScenarioCardOnChange}
                        isLoading={isLoading} />
                )}
                {!Boolean(insuranceScenarios?.length) &&
                    <EmptyCard
                        text="No scenarios listed"
                        secondaryText="Get started by adding a scenario"
                        isLoading={isLoading}
                    />}
            </Box>

            <WorkspaceActionBar content={<>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={handleAddNewClick}>
                        <AddCircleOutline sx={{ mr: 1 }} />
                        New Scenario
                    </Button>
                </Stack>
            </>} />

            {confirmDialogComponent}
        </>
    )
}
