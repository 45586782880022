import CloseOutlined from '@mui/icons-material/CloseOutlined'
import SearchOutlined from '@mui/icons-material/SearchOutlined'
import { Box, CircularProgress, DialogActions, DialogContent, DialogTitle, InputAdornment, List, Skeleton, Stack, Tooltip, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { AppContext } from 'App'
import { AipPassApiHelper, UsersApiHelper } from 'api/ApiHelper'
import EmptyCard from 'components/Cards/EmptyCard'
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import MemberDataTable, { MEMBER_ACTION_MANAGE_ACCOUNT } from 'components/Member/components/MemberDataTable'
import UserGrantItem from 'components/UserGrants/components/UserGrantItem'
import { USER_ROLE_ADMIN, USER_ROLE_AGENT } from 'core/useSession'
import { uniqBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'

export default function ProducerSearchDialog({
    onItemSelect,
    onClose
}) {
    const { globalState, globalDispatch } = useContext(AppContext);
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [searchText, setSearchText] = useState('');
    const handleSearchOnChange = (event) => {
        setSearchText(event.target.value);
    }

    const [producers, setProducers] = useState([]);
    const searchProducers = async () => {
        try {
            const __authTokens = { ...globalState };
            if (globalState.user_role == USER_ROLE_ADMIN) {
                delete __authTokens.agent_token;
            }
            delete __authTokens.producer_token;

            let _producersLastName = AipPassApiHelper.searchProducers({ last_name: searchText }, __authTokens);
            let _producersBusinessName = AipPassApiHelper.searchProducers({ business_name: searchText }, __authTokens);

            const _producers = [...(await _producersLastName), ...(await _producersBusinessName)];
            const _uniqueProducers = uniqBy(_producers, p => p.producer_token);

            const searchTokens = searchText
                ?.split(/,| /)
                ?.filter(t => t)
                ?.map(t => t?.toLowerCase());
            if (searchTokens?.length) {
                _uniqueProducers.forEach(p => {
                    let searchScore = (searchTokens.filter(t => t == p.first_name?.toLowerCase()))?.length;
                    searchScore += (searchTokens.filter(t => t == p.last_name?.toLowerCase()))?.length;
                    searchScore += (searchTokens.filter(t => p.business_name?.toLowerCase().includes(t)))?.length;
                    p.searchScore = searchScore;
                });
            }

            const _sortProducers = _uniqueProducers.sort((a, b) => (b?.searchScore || 0) - (a?.searchScore || 0));

            setProducers(_sortProducers);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setProducers([]);
            console.warn(err);
            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
        }
    };

    const [userGrants, setUserGrants] = useState([]);
    const loadUserGrants = async () => {
        try {
            const _userGrants = await UsersApiHelper.selectUserGrants(
                { user_id: globalState.agent_token },
                { user_role: globalState?.user_role, agent_token: globalState?.agent_token }
            );
            setUserGrants(_userGrants);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setUserGrants([]);
            console.warn(err);
            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
        }
    }

    const calculateActiveUserGrants = () => {
        if (userGrants?.length) {
            return userGrants
                .filter(ug => ug.is_active)
                .filter(ug => ug.producer_description?.toLowerCase()?.includes(searchText));
        } else {
            return [];
        }
    }

    const calculatePendingUserGrants = () => {
        if (userGrants?.length) {
            return userGrants.filter(ug => ug.is_active == null);
        } else {
            return [];
        }
    }

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const handleAddNewClick = () => {
        setIsDialogOpen(true);
    }

    const isValid = (currentUserGrant) => {
        return (
            currentUserGrant?.id
            && (currentUserGrant?.producer_token || currentUserGrant?.producer_email)
        );
    }
    // useBlockerPrompt(!isValid(), "Portfolio");

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true);

        if (globalState.user_role) {
            if (searchText && globalState.user_role == USER_ROLE_ADMIN) {
                searchProducers();
                // searchMembers();
            } else if (globalState.user_role != USER_ROLE_ADMIN) {
                loadUserGrants();
            } else {
                setIsLoading(false);
            }
        }
    }, [searchText, globalState.user_role, globalState.agent_token])

    const handleProducerItemClick = (currentProducer) => {
        const _userGrant = userGrants?.find(ug => ug?.producer_token?.toLowerCase() == currentProducer?.producer_token?.toLowerCase());
        if (_userGrant) {
        }
    }

    const handlePortfolioItemClick = (currentProducer) => {
        onItemSelect && onItemSelect(currentProducer);
    }

    const handleItemAction = (currentMember, action) => {
        if (action == MEMBER_ACTION_MANAGE_ACCOUNT) {
        }
    }

    return (
        <>
            <DialogTitle>
                Search Producers
            </DialogTitle>
            <DialogContent>
                <Stack spacing={1}>
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                        <StatefulControlledTextField
                            onChange={handleSearchOnChange}
                            defaultValue=''
                            fullWidth={isSmall}
                            sx={{ minWidth: '2in', maxWidth: '100%' }}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton edge="end" color="primary">
                                        <Tooltip title='Search'>
                                            <SearchOutlined />
                                        </Tooltip>
                                    </IconButton>
                                </InputAdornment>
                            } />

                        {isLoading && <CircularProgress />}
                    </Box>

                    {Boolean(calculateActiveUserGrants()?.length || producers?.length) && <>
                        <div>
                            <Divider />
                        </div>
                        {globalState.user_role != USER_ROLE_ADMIN &&
                            <Typography variant='h5'>
                                Active Members:
                            </Typography>}
                        {globalState.user_role == USER_ROLE_ADMIN &&
                            <Typography variant='h5'>
                                Search Results:
                            </Typography>}
                        <div>
                            <MemberDataTable title="Producers"
                                itemSelectLabel={"Select"}
                                members={producers || calculateActiveUserGrants()}
                                onItemSelect={(currentMember) => handlePortfolioItemClick(currentMember)} />
                        </div>
                    </>}
                    {isLoading
                        && !Boolean(calculateActiveUserGrants()?.length || producers?.length)
                        && !Boolean(calculatePendingUserGrants()?.length) && <>
                            <Typography variant="h1">
                                <Skeleton />
                            </Typography><Typography variant="h1">
                                <Skeleton />
                            </Typography><Typography variant="h1">
                                <Skeleton />
                            </Typography>
                        </>}

                    {globalState?.user_role == USER_ROLE_AGENT
                        && !isLoading
                        && !Boolean(calculateActiveUserGrants()?.length || producers?.length)
                        && !Boolean(calculatePendingUserGrants()?.length) && <>
                            <EmptyCard text='No active members' secondaryText='Start by inviting a member' />
                        </>}

                    {globalState?.user_role == USER_ROLE_ADMIN
                        && !isLoading
                        && !Boolean(calculateActiveUserGrants()?.length || producers?.length)
                        && !Boolean(calculatePendingUserGrants()?.length) && <>
                            <EmptyCard text='No search results' secondaryText='Search for a producer' />
                        </>}

                    {Boolean(calculatePendingUserGrants()?.length) && <>
                        <Divider />
                        <div>
                            <Typography variant='h5'>
                                Pending Invitations:
                            </Typography>
                            <List dense sx={{ p: 0 }}>
                                {calculatePendingUserGrants()?.map((ug, index) => (
                                    <UserGrantItem userGrant={ug} isSelected={false} key={index}
                                        onClick={() => handleProducerItemClick(ug)}
                                    />
                                ))}
                            </List>
                        </div>
                    </>}
                </Stack>
            </DialogContent>

            <DialogActions>
                <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                    <Button variant='contained' color='secondary' autoFocus onClick={onClose}>
                        <CloseOutlined sx={{ mr: 1 }} />
                        Cancel
                    </Button>
                </Stack>
            </DialogActions>
        </>
    )
}
