import { useDialog } from '@datanac/datanac-mui-toolkit'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import { Box, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { AppContext } from 'App'
import { UsersApiHelper } from 'api/ApiHelper'
import { getBudgetYears } from 'components/Budget/helpers'
import EmptyCard from 'components/Cards/EmptyCard'
import { getUniqueCounties } from 'components/Vault/Farm/helpers'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import _ from 'lodash'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ArcPlcFarmCountyCard from './components/ArcPlcFarmCountyCard'

export default function ArcPlcCountyListWorkspace() {
    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();

    const isXL = useMediaQuery((theme) => theme.breakpoints.up('xl'), { noSsr: true });
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const [currentYear, setCurrentYear] = useState(globalState?.crop_year);

    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'crop_year',
                value: currentYear
            },
        });
    }, [currentYear])

    const [baseAcres, setBaseAcres] = useState([]);
    const [isLoadingBaseAcres, setIsLoadingBaseAcres] = useState(true);

    const loadProducerFarmBaseAcres = async () => {
        try {
            setIsLoading(true);
            setIsLoadingBaseAcres(true);

            const producerFilter = {
                producer_token: globalState.producer_token,
                crop_year: currentYear,
                is_active: true
            };
            const _baseAcres = await UsersApiHelper.selectProducerFarmBaseAcres(producerFilter, globalState);
            setBaseAcres(_baseAcres);

            setIsLoadingBaseAcres(false);
        } catch (err) {
            setIsLoadingBaseAcres(false);
            producerFarmActions.replace([]);
            console.warn(err);
            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (currentYear && globalState?.producer_token) {
            loadProducerFarmBaseAcres();
        }
    }, [currentYear, globalState?.producer_token]);

    // --- --- ---

    const [countyPractices, setCountyPractices] = useState([]);
    const [isLoadingCountyPractices, setIsLoadingCountyPractices] = useState(true);

    const loadCountyPractices = async () => {
        try {
            setIsLoadingCountyPractices(true);

            const _countyPractices = getUniqueCounties(baseAcres);
            setCountyPractices(_countyPractices);
            setIsLoadingCountyPractices(false);
        } catch (err) {
            setCountyPractices(null);
            setIsLoadingCountyPractices(false);

            console.warn(err);
            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (baseAcres?.length) {
            loadCountyPractices();
        } else {
            setCountyPractices(null);
            setIsLoadingCountyPractices(false);
        }
    }, [globalState?.producer_token, baseAcres])

    // --- --- ---

    const [countyPractice_union_baseAcres, setCountyPractice_union_baseAcres] = useState([]);
    useEffect(() => {
        if (countyPractices?.length && !baseAcres?.length) {
            setCountyPractice_union_baseAcres(countyPractices);
        } else if (countyPractices?.length && (baseAcres?.length || baseAcres?.length)) {
            const loadUnionProducerFarmBaseAcres = async () => {
                // Union:
                const _baseAcresUnion = [...countyPractices];
                _baseAcresUnion.forEach(currentCountyPractice => {
                    currentCountyPractice.BaseAcres = baseAcres.filter(ba =>
                        ba.location_state_name == currentCountyPractice.location_state_name
                        && ba.location_county_name == currentCountyPractice.location_county_name
                    );

                    currentCountyPractice.CropProfiles = _.uniqBy(currentCountyPractice?.BaseAcres, pfc =>
                        pfc.commodity_name
                    )
                        ?.sort((a, b) =>
                            a.commodity_name?.localeCompare(b.commodity_name)
                        );

                    currentCountyPractice.CropProfiles_Sort = currentCountyPractice.CropProfiles?.reduce((accumulator, currentValue) =>
                        (accumulator?.length > 0 ? ", " : "") + currentValue?.commodity_name
                        , "");
                });

                setCountyPractice_union_baseAcres(_baseAcresUnion);
            }
            loadUnionProducerFarmBaseAcres();
        } else {
            setIsLoading(false);
        }
    }, [globalState?.producer_token, countyPractices, baseAcres]);

    // --- --- ---

    const [totals, setTotals] = useState({});
    const calculateTotals = async () => {
        const _base_acreage = baseAcres.reduce((accumulator, currentValue) =>
            accumulator + currentValue?.base_acreage, 0);

        setTotals({
            base_acreage: _base_acreage
        });
    }

    useEffect(() => {
        if (baseAcres?.length) {
            calculateTotals();
        }
    }, [globalState?.producer_token, baseAcres]);

    // --- --- ---

    return (
        <>
            <WorkspaceHeader
                title='ARC/PLC Calculator'
                breadCrumbs={[]}
            />
            <Stack direction="row" spacing={2} className='headerWithMenuButton'>
                <Typography variant='h1'>
                    Projected Payments
                </Typography>
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <>
                            <Button variant="outlined" {...bindTrigger(popupState)}>
                                {currentYear} <ArrowDropDownOutlined />
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                {getBudgetYears().map((year, index) =>
                                    <MenuItem key={index} onClick={() => {
                                        setCurrentYear(year);
                                        popupState.close();
                                    }}>
                                        {year}
                                    </MenuItem>)}
                            </Menu>
                        </>
                    )}
                </PopupState>
            </Stack>
            <Box className='cardContainer'>
                {(Boolean(countyPractices?.length)) && countyPractices.map((currentCountyPractice, index) =>
                    <ArcPlcFarmCountyCard key={index}
                        currentCounty={currentCountyPractice}
                        crop_year={currentYear} />
                )}

                {!Boolean(countyPractices?.length) &&
                    <EmptyCard
                        text="No farms listed"
                        secondaryText="Add farms and base acres to your Farm Vault"
                        isLoading={isLoadingBaseAcres || isLoadingCountyPractices}
                    />}
            </Box>

            {/* <Box sx={{
                ...(isXL && {
                    top: 'auto', bottom: 0, pb: 2, position: 'sticky',
                    width: "100%", backgroundColor: 'white', zIndex: 1000
                })
            }}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Grid container justifyItems='flex-start' spacing={1}>
                    <Grid item xs={12} md={6} xl={1.5}>
                        <Typography variant='h1'>
                            Totals
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.5}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatNumber(totals?.base_acreage, 0)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Base Acres</Typography>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={6} xl={1.5} sx={{ backgroundColor: '#f5f5f5' }}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatPrice(
                                    (totals?.yield * totals?.projected_price) - 0
                                    , 2)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>ARC-CO Payment/Acre</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.5} sx={{ backgroundColor: '#f5f5f5' }}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5' sx={{ fontWeight: 'bold !important' }}>
                                {formatPrice(
                                    ((totals?.yield * totals?.projected_price) - 0) * totals?.reported_acreage
                                    , 0)}
                            </Typography>
                            <Typography variant='body1' sx={{ fontWeight: 'bold !important', textTransform: isSmall ? '' : 'uppercase' }}>ARC-CO Payment</Typography>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={6} xl={1.5}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatPrice(
                                    (totals?.yield * totals?.projected_price) - 0
                                    , 2)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>PLC Payment/Acre</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.5}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5' sx={{ fontWeight: 'bold !important' }}>
                                {formatPrice(
                                    ((totals?.yield * totals?.projected_price) - 0) * totals?.reported_acreage
                                    , 0)}
                            </Typography>
                            <Typography variant='body1' sx={{ fontWeight: 'bold !important', textTransform: isSmall ? '' : 'uppercase' }}>PLC Payment</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Box> */}


            {confirmDialogComponent}
        </>
    )
}
