import { getProducerFarmCropKml } from '@datanac/datanac-api-toolkit/dist/maps/kml-helper'
import { useDialog } from '@datanac/datanac-mui-toolkit'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, Button, Chip, CircularProgress, Fade, Link, useMediaQuery } from '@mui/material'
import {
    Aggregate,
    AggregateColumnDirective,
    AggregateColumnsDirective,
    AggregateDirective,
    AggregatesDirective,
    ColumnDirective,
    ColumnsDirective,
    ExcelExport,
    Filter,
    GridComponent,
    Group,
    Inject,
    Page,
    PdfExport,
    Sort,
    Toolbar,
} from '@syncfusion/ej2-react-grids'
import { AppContext } from 'App'
import { RmaApiHelper } from 'api/ApiHelper'
import EmptyCard from 'components/Cards/EmptyCard'
import { propUpdateReducer } from 'components/Core/propUpdateReducer'
import { FarmVaultIcon, FinancialIcon } from 'components/Icons/Icons'
import { getUniqueProducerFarmCrops } from 'components/Vault/Farm/helpers'
import { useFarmController } from 'components/Vault/Farm/useFarmController'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'
import ReactLeafletKml from "react-leaflet-kml";
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { formatNumber } from 'utility/formatters'
import 'leaflet/dist/leaflet.css';


export default function AcresMapReport() {
    const { globalState } = useContext(AppContext);
    const {
        crop_year,
    } = useParams();
    const navigate = useNavigate();

    const location = useLocation();

    // ---

    const [filterLocationStateName, setFilterLocationStateName] = useState(null);
    const [filterLocationCountyName, setFilterLocationCountyName] = useState(null);

    //useEffect for location?.search - querystring changes
    useEffect(() => {
        const searchParams = new URLSearchParams(location?.search);
        const location_state_name = searchParams.get('location_state_name');
        if (location_state_name?.length) {
            setFilterLocationStateName(location_state_name);
        } else {
            setFilterLocationStateName(null);
        }
    }, [location?.search]);
    useEffect(() => {
        const searchParams = new URLSearchParams(location?.search);
        const location_county_name = searchParams.get('location_county_name');
        if (location_county_name?.length) {
            setFilterLocationCountyName(location_county_name);
        } else {
            setFilterLocationCountyName(null);
        }
    }, [location?.search]);

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || err, { preventDuplicate: true, variant: 'error' });
    }

    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const [currentYear, setCurrentYear] = useState(crop_year);
    const [filter, dispatchFilter] = useReducer(propUpdateReducer, {
        commodity_name: [],
        practice_name: [],
    });

    const {
        counties,
        // countyPractices,
        // producerFarms,
        // producerFarmFieldCrops,
        // producerFarm_union_Crops,
        // isLoading,
        isLoadingCounties,
    } = useFarmController({
        crop_year: currentYear,
        filter: filter,
        onError: handleError
    });

    const [allProducerFarmCrops, setAllProducerFarmCrops] = useState([]);

    useEffect(() => {
        // Reducer counties[ii].ProducerFarmCrops
        var producerFarmCrops = [];
        counties
            ?.filter((county) => {
                const isCountyMatch = filterLocationCountyName == null || county?.location_county_name == filterLocationCountyName;
                const isStateMatch = filterLocationStateName == null || county?.location_state_name == filterLocationStateName;
                return isCountyMatch && isStateMatch;
            })
            ?.forEach((county) => {
                county?.ProducerFarms?.forEach((producerFarm) => {
                    producerFarm?.ProducerFarmFieldCrops?.forEach((producerFarmFieldCrop) => {
                        producerFarmCrops.push({
                            ...producerFarmFieldCrop,
                            CustomerName: producerFarm?.customer_name,
                            OrderDate: producerFarm?.order_date,
                            Freight: producerFarm?.freight,
                            ShippedDate: producerFarm?.shipped_date,
                        });
                    });
                });
            });

        const _uniqueProducerFarmCrops = getUniqueProducerFarmCrops(producerFarmCrops);
        setAllProducerFarmCrops(_uniqueProducerFarmCrops)
        // setAllProducerFarmCrops(producerFarmCrops);
    }, [counties, filterLocationStateName, filterLocationCountyName]);

    // ---
    // Map KML data

    // useState for mapKml
    const [mapKml, setMapKml] = useState(null);
    const [isLoadingMapKml, setIsLoadingMapKml] = useState(true);

    const loadXml = async () => {
        const countyPracticesPrices = await RmaApiHelper.selectCountyPracticesAreaPricesByProducerFarmCrops(allProducerFarmCrops);
        const countyPracticesData = await RmaApiHelper.selectCountyPracticesAreaDataByProducerFarmCrops(allProducerFarmCrops);

        const kml = getProducerFarmCropKml(allProducerFarmCrops, countyPracticesPrices, countyPracticesData);

        setMapKml(kml);
        setIsLoadingMapKml(false);
    }

    // useMemo for allproducerFarmCrops
    useMemo(() => {
        if (allProducerFarmCrops) {
            loadXml();
        }
    }, [allProducerFarmCrops]);

    // ---


    const filterHandleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        dispatchFilter({
            type: 'update',
            payload: { key: event.target.name, value: value },
        });
    }

    // ---

    const handleFarmNavigateClick = (_currentProducerFarm) => {
        navigate(`/vault/${crop_year}/${_currentProducerFarm?.location_state_name}/${_currentProducerFarm?.location_county_name}/${_currentProducerFarm?.id}`)
    }

    // ---

    const [mockKml, setMockKml] = useState(null);

    useEffect(() => {
        fetch(
            // "https://raw.githubusercontent.com/aviklai/react-leaflet-kml/master/src/assets/example1.kml"
            "/mock-map.kml.xml"
        )
            .then((res) => res.text())
            .then((kmlText) => {
                const parser = new DOMParser();
                const kml = parser.parseFromString(kmlText, "text/xml");
                setMockKml(kml);
            })
    }, []);

    // ---

    return (<>
        <WorkspaceHeader
            title={"Acres Map Report"}
            breadCrumbs={[
                {
                    title: 'Farm Vault ' + crop_year,
                    path: '/vault',
                    icon: <FarmVaultIcon fontSize='3pt' />
                },
            ]}
        />

        {!isLoadingMapKml && !Boolean(mapKml?.length) && <>
            <EmptyCard text="No results were found" secondaryText="We could not find anything in the Vault" />
        </>}

        {isLoadingMapKml && <CircularProgress />}


        {mockKml && <MapContainer
            style={{ height: "500px", width: "100%" }}
            zoom={17}
            // center={[37.422, -122.084]}//TEST: CA
            center={[35.95375319700008, -90.82071589099985]}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {mockKml && <ReactLeafletKml kml={mockKml} />}
        </MapContainer>}

        {confirmDialogComponent}
    </>);
}