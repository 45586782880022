import { MODULE_FINANCIAL } from "components/Menu/NavigationMenu";

export const OBJECT_NAME_BALANCE_SHEET = "balance_sheet";

// ---

export const CONTAINER_NAME_BALANCE_SHEET_ASSETS_CURRENT = "assets_current";

export const ITEM_NAME_BALANCE_SHEET_ASSETS_ACCOUNTS_RECEIVABLE_CROPS = "accounts_receivable_crops";
export const ITEM_NAME_BALANCE_SHEET_ASSETS_ACCOUNTS_RECEIVABLE_LIVESTOCK = "accounts_receivable_livestock";
export const ITEM_NAME_BALANCE_SHEET_ASSETS_CASH = "cash";
export const ITEM_NAME_BALANCE_SHEET_ASSETS_CASH_INVESTMENT_IN_GROWING_CROPS = "cash_investment_in_growing_crops";
export const ITEM_NAME_BALANCE_SHEET_ASSETS_HEDGING_ACCOUNT_EQUITY = "hedging_account_equity";
export const ITEM_NAME_BALANCE_SHEET_ASSETS_INVENTORIES_CROPS = "inventories_crops";
export const ITEM_NAME_BALANCE_SHEET_ASSETS_INVENTORIES_FEED = "inventories_feed";
export const ITEM_NAME_BALANCE_SHEET_ASSETS_INVENTORIES_LIVESTOCK = "inventories_livestock";
export const ITEM_NAME_BALANCE_SHEET_ASSETS_INVENTORIES_SUPPLIES = "inventories_supplies";
export const ITEM_NAME_BALANCE_SHEET_ASSETS_CURRENT_OTHER = "other";
export const ITEM_NAME_BALANCE_SHEET_ASSETS_PREPAID_EXPENSES = "prepaid_expenses";

// ---

export const CONTAINER_NAME_BALANCE_SHEET_ASSETS_NONCURRENT = "assets_noncurrent";

export const ITEM_NAME_BALANCE_SHEET_ASSETS_BREEDING_LIVESTOCK = "breeding_livestock";
export const ITEM_NAME_BALANCE_SHEET_ASSETS_BUILDINGS_IMPROVEMENTS = "buildings_improvements";
export const ITEM_NAME_BALANCE_SHEET_ASSETS_INVESTMENTS_COOPERATIVES = "investments_cooperatives";
export const ITEM_NAME_BALANCE_SHEET_ASSETS_INVESTMENTS_OTHER_ENTITIES = "investments_other_entities";
export const ITEM_NAME_BALANCE_SHEET_ASSETS_MACHINERY_EQUIPMENT = "machinery_equipment";
export const ITEM_NAME_BALANCE_SHEET_ASSETS_NONCURRENT_OTHER = "other";
export const ITEM_NAME_BALANCE_SHEET_ASSETS_REAL_ESTATE = "real_estate";
export const ITEM_NAME_BALANCE_SHEET_ASSETS_RIGHT_OF_USE = "right_of_use";

// ---

export const CONTAINER_NAME_BALANCE_SHEET_ASSETS_PERSONAL = "assets_personal";

export const ITEM_NAME_BALANCE_SHEET_ASSETS_PERSONAL = "personal";

// ---

export const CONTAINER_NAME_BALANCE_SHEET_EQUITY = "equity";

export const ITEM_NAME_BALANCE_SHEET_EQUITY_RETAINED_CAPITAL = "retained_capital";
export const ITEM_NAME_BALANCE_SHEET_EQUITY_VALUATION_EQUITY = "valuation_equity";

// ---

export const CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_CURRENT = "liabilities_current";

export const ITEM_NAME_BALANCE_SHEET_LIABILITIES_ACCOUNTS_PAYABLE_CROPS = "accounts_payable_crops";
export const ITEM_NAME_BALANCE_SHEET_LIABILITIES_ACCOUNTS_PAYABLE_LIVESTOCK = "accounts_payable_livestock";
export const ITEM_NAME_BALANCE_SHEET_LIABILITIES_ACCRUED_INTEREST_FINANCE_LEASES = "accrued_interest_finance_leases";
export const ITEM_NAME_BALANCE_SHEET_LIABILITIES_ACCRUED_INTEREST_NONCURRENT_LOANS = "accrued_interest_noncurrent_loans";
export const ITEM_NAME_BALANCE_SHEET_LIABILITIES_ACCRUED_INTEREST_NOTES_DUE_WITHIN_ONE_YEAR = "accrued_interest_notes_due_within_one_year";
export const ITEM_NAME_BALANCE_SHEET_LIABILITIES_CURRENT_PORTION_OF_FINANCE_LEASES = "current_portion_of_finance_leases";
export const ITEM_NAME_BALANCE_SHEET_LIABILITIES_CURRENT_PORTION_OF_TERM_DEBT = "current_portion_of_term_debt";
export const ITEM_NAME_BALANCE_SHEET_LIABILITIES_INCOME_TAXES_PAYABLE = "income_taxes_payable";
export const ITEM_NAME_BALANCE_SHEET_LIABILITIES_NOTES_DUE_WITHIN_ONE_YEAR = "notes_due_within_one_year";
export const ITEM_NAME_BALANCE_SHEET_LIABILITIES_CURRENT_OTHER = "other";
export const ITEM_NAME_BALANCE_SHEET_LIABILITIES_OTHER_ACCRUED_EXPENSES = "other_accrued_expenses";
export const ITEM_NAME_BALANCE_SHEET_LIABILITIES_OVERDRAFTS = "overdrafts";

// ---

export const CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_NONCURRENT = "liabilities_noncurrent";

export const ITEM_NAME_BALANCE_SHEET_LIABILITIES_DEFERRED_INCOME_TAX = "deferred_income_tax";
export const ITEM_NAME_BALANCE_SHEET_LIABILITIES_FINANCE_LEASES = "finance_leases";
export const ITEM_NAME_BALANCE_SHEET_LIABILITIES_NOTES_PAYABLE = "notes_payable";
export const ITEM_NAME_BALANCE_SHEET_LIABILITIES_NONCURRENT_OTHER = "other";
export const ITEM_NAME_BALANCE_SHEET_LIABILITIES_REAL_ESTATE_DEBT = "real_estate_debt";

// ---

export const CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_PERSONAL = "liabilities_personal";

export const ITEM_NAME_BALANCE_SHEET_LIABILITIES_PERSONAL_LIABILITIES_AND_TAXES = "personal_liabilities_and_taxes";

// ---

export const CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_TAX = "liabilities_tax";

export const ITEM_NAME_BALANCE_SHEET_LIABILITIES_ESTIMATED_INCOME_TAX = "estimated_income_tax";

// --- --- ---

export const DOCUMENT_DICTIONARY_DEFAULT_VALUES_BALANCE_SHEET = [
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_ASSETS_ACCOUNTS_RECEIVABLE_CROPS,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_ASSETS_ACCOUNTS_RECEIVABLE_LIVESTOCK,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_ASSETS_CASH,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_ASSETS_CASH_INVESTMENT_IN_GROWING_CROPS,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_ASSETS_HEDGING_ACCOUNT_EQUITY,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_ASSETS_INVENTORIES_CROPS,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_ASSETS_INVENTORIES_FEED,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_ASSETS_INVENTORIES_LIVESTOCK,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_ASSETS_INVENTORIES_SUPPLIES,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_ASSETS_CURRENT_OTHER,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_ASSETS_PREPAID_EXPENSES,
    },

    //

    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_NONCURRENT,
        item: ITEM_NAME_BALANCE_SHEET_ASSETS_BREEDING_LIVESTOCK,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_NONCURRENT,
        item: ITEM_NAME_BALANCE_SHEET_ASSETS_BUILDINGS_IMPROVEMENTS,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_NONCURRENT,
        item: ITEM_NAME_BALANCE_SHEET_ASSETS_INVESTMENTS_COOPERATIVES,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_NONCURRENT,
        item: ITEM_NAME_BALANCE_SHEET_ASSETS_INVESTMENTS_OTHER_ENTITIES,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_NONCURRENT,
        item: ITEM_NAME_BALANCE_SHEET_ASSETS_MACHINERY_EQUIPMENT,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_NONCURRENT,
        item: ITEM_NAME_BALANCE_SHEET_ASSETS_NONCURRENT_OTHER,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_NONCURRENT,
        item: ITEM_NAME_BALANCE_SHEET_ASSETS_REAL_ESTATE,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_NONCURRENT,
        item: ITEM_NAME_BALANCE_SHEET_ASSETS_RIGHT_OF_USE,
    },

    //

    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_PERSONAL,
        item: ITEM_NAME_BALANCE_SHEET_ASSETS_PERSONAL,
    },

    //

    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_EQUITY,
        item: ITEM_NAME_BALANCE_SHEET_EQUITY_RETAINED_CAPITAL,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_EQUITY,
        item: ITEM_NAME_BALANCE_SHEET_EQUITY_VALUATION_EQUITY,
    },

    //

    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_ACCOUNTS_PAYABLE_CROPS,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_ACCOUNTS_PAYABLE_LIVESTOCK,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_ACCRUED_INTEREST_FINANCE_LEASES,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_ACCRUED_INTEREST_NONCURRENT_LOANS,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_ACCRUED_INTEREST_NOTES_DUE_WITHIN_ONE_YEAR,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_CURRENT_PORTION_OF_FINANCE_LEASES,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_CURRENT_PORTION_OF_TERM_DEBT,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_INCOME_TAXES_PAYABLE,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_NOTES_DUE_WITHIN_ONE_YEAR,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_CURRENT_OTHER,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_OTHER_ACCRUED_EXPENSES,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_CURRENT,
        item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_OVERDRAFTS,
    },

    //

    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_NONCURRENT,
        item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_DEFERRED_INCOME_TAX,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_NONCURRENT,
        item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_FINANCE_LEASES,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_NONCURRENT,
        item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_NOTES_PAYABLE,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_NONCURRENT,
        item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_NONCURRENT_OTHER,
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_NONCURRENT,
        item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_REAL_ESTATE_DEBT,
    },

    //

    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_PERSONAL,
        item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_PERSONAL_LIABILITIES_AND_TAXES,
    },

    //

    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_TAX,
        item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_ESTIMATED_INCOME_TAX,
    },
]
