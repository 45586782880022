import { useDialog } from '@datanac/datanac-mui-toolkit'
import { Box, CircularProgress, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography, useMediaQuery } from '@mui/material'
import { AppContext } from 'App'
import { UsersApiHelper } from 'api/ApiHelper'
import { BUDGET_STATUS_DRAFT, getBudgetYears } from 'components/Budget/helpers'
import EmptyCard from 'components/Cards/EmptyCard'
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import { propUpdateReducer } from 'components/Core/propUpdateReducer'
import { InsuranceIcon } from 'components/Icons/Icons'
import { useBlockerPrompt } from 'components/Menu/useBlockerPrompt'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import WorkspaceViewSelector from 'components/Workspaces/WorkspaceModeSelector'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import { SkeletonWrapper } from 'mui-toolkit/SkeletonWrapper'
import { useFormStatus } from 'mui-toolkit/useFormStatus'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { InsuranceService } from './InsuranceService'
import InsuranceCountyPracticeCard from './components/InsuranceCountyPracticeCard'

export default function InsuranceCountyPracticeWorkspace() {

    const { globalState } = useContext(AppContext);
    const { insurance_scenario_id } = useParams();
    const navigate = useNavigate();

    // const INSURANCE_PLAN_COUNT_THRESHOLD_GROUP = 10;


    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const { component: formStatusComponent, actions: formStatusActions } = useFormStatus();

    // --- --- ---

    const [isLoadingState, setIsLoadingState] = useState(true);
    const [state, dispatch] = useReducer(propUpdateReducer, {
        status: BUDGET_STATUS_DRAFT,
        is_active: true
    });

    const handleChangeState = (event, value) => {
        value = event.target.value

        if (state[event.target.name] != value) {
            formStatusActions.setIsFormDirty(true, false);

            dispatch({
                type: 'update',
                payload: { key: event.target.name, value: value },
            });
        }
    }

    const handleChangeStateSelect = (event) => {
        formStatusActions.setIsFormDirty(true, false);

        dispatch({
            type: 'update',
            payload: { key: event.target.name, value: event.target.value },
        });
    }

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || err, { preventDuplicate: true, variant: 'error' });
    }

    const loadInsuranceScenarioState = async (_insurance_scenario_id) => {
        const producerFilter = {
            producer_token: globalState.producer_token,
            id: _insurance_scenario_id,
            is_active: true
        };
        const _insuranceScenarios = await UsersApiHelper.users_selectObject("InsuranceScenario", producerFilter, globalState);
        if (_insuranceScenarios?.length) {
            dispatch({
                type: 'updateMany',
                payload: _insuranceScenarios[0]
            });
        }

        setIsLoadingState(false);
    }

    const [insuranceScenario, setInsuranceScenario] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const loadInsuranceScenario = async (_insurance_scenario_id, _crop_year) => {
        try {
            if (state?.id != 0) {
                const _InsuranceService = new InsuranceService({
                    crop_year: _crop_year,
                    insuranceScenarios: [
                        { id: state?.id }
                    ],
                    onError: handleError,
                    apiConfig: globalState
                });
                const _insuranceScenarioPopulated = await _InsuranceService.actions.loadInsuranceScenario();
                if (_insuranceScenarioPopulated?.length) {
                    setInsuranceScenario(_insuranceScenarioPopulated[0]);
                } else {
                    setInsuranceScenario({});
                    setIsLoading(false);
                }

                _InsuranceService.actions.calculatePremiums(_insuranceScenarioPopulated[0])
                    .then((calculatePremiumsResponseInsuranceScenario) => {
                        if (calculatePremiumsResponseInsuranceScenario) {
                            setInsuranceScenario(calculatePremiumsResponseInsuranceScenario);
                            setIsLoading(false);
                        } else {
                            setInsuranceScenario({});
                            setIsLoading(false);
                        }
                    })
            } else {
                setIsLoadingState(false);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoadingState(false);
            setIsLoading(false);
            console.warn(err);
            enqueueSnackbar(err.detail || err.message || err, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (isValid() && globalState.producer_token) {
            formStatusActions.setIsFormDirty(true, true);

            state.producer_token = globalState?.producer_token;
            UsersApiHelper.updateInsuranceScenarios({ InsuranceScenarios: [state] }, globalState)
                .then(() => {
                    formStatusActions.setIsSaving(false);
                })
                .catch(err => {
                    console.warn(err);
                    formStatusActions.setIsFormDirty(true, false);
                    enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
                });
        }
    }, [state, globalState.producer_token]);

    useEffect(() => {
        if (globalState?.producer_token) {
            if (insurance_scenario_id == 0) {
                if (!state?.id) {
                    dispatch({
                        type: 'update',
                        payload: { key: "id", value: uuidv4() },
                    });
                }
                setIsLoadingState(false);
            } else {
                loadInsuranceScenarioState(insurance_scenario_id);
                dispatch({
                    type: 'update',
                    payload: { key: "id", value: insurance_scenario_id },
                });
            }
        }
    }, [globalState?.producer_token, insurance_scenario_id, state?.crop_year]);

    useEffect(() => {
        if (globalState?.producer_token && state?.crop_year) {
            if (insurance_scenario_id == 0) {
                if (!state?.id) {
                    dispatch({
                        type: 'update',
                        payload: { key: "id", value: uuidv4() },
                    });
                }
                setIsLoading(false);
            } else {
                loadInsuranceScenario(insurance_scenario_id, state?.crop_year);
                dispatch({
                    type: 'update',
                    payload: { key: "id", value: insurance_scenario_id },
                });
            }
        }
    }, [globalState?.producer_token, insurance_scenario_id, state?.crop_year]);

    useEffect(() => {
        if (state?.id && state?.crop_year) {
            loadInsuranceScenario(state?.id, state?.crop_year);
        }
    }, [state?.id, state?.crop_year, globalState, insurance_scenario_id])

    const isValid = () => {
        return (Boolean(
            state?.id
            && state?.id !== 0
            && state?.name
            && state?.crop_year
        ));
    }
    useBlockerPrompt(!isValid(), "InsuranceCountyPracticeWorkspace");

    // --- --- ---

    const handleAddNewClick = () => {
        // navigate(`/`);
    }

    return (
        <>
            <WorkspaceHeader
                title={state?.name || "Scenario"}
                breadCrumbs={[
                    {
                        title: 'Insurance',
                        path: '/insurance',
                        icon: <InsuranceIcon />
                    },
                ]}
            />

            <Stack direction="row" spacing={2} className='headerWithMenuButton'>
                <Typography variant='h1'>
                    Insurance Scenario
                </Typography>
                {formStatusComponent}
            </Stack>
            <Grid container spacing={1}>
                <Grid item xs={6} md={3}>
                    <FormControl fullWidth={true}>
                        <SkeletonWrapper isLoading={isLoadingState}>
                            <StatefulControlledTextField
                                label="Scenario Name"
                                name="name"
                                isRequired={true}
                                defaultValue={state?.name || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </SkeletonWrapper>
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={2}>
                    <FormControl fullWidth>
                        <SkeletonWrapper isLoading={isLoadingState}>
                            <InputLabel id='selectYearLabel'>Year</InputLabel>
                            <Select
                                labelId='selectYearLabel'
                                value={state?.crop_year || ''}
                                name={"crop_year"}
                                onChange={handleChangeStateSelect}
                                label={'Year'}
                            >
                                {getBudgetYears()?.map((currentItem, index) => {
                                    return <MenuItem key={index} value={currentItem}>{currentItem}</MenuItem>
                                })}
                            </Select>
                        </SkeletonWrapper>
                    </FormControl>
                </Grid>
            </Grid>

            <Divider />

            <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                <Typography variant='h1'>
                    Crop Plans
                </Typography>
                <WorkspaceViewSelector workspaceMode={workspaceMode} setWorkspaceMode={setWorkspaceMode} />
                {isLoading && <CircularProgress />}
            </Stack>

            {Boolean(insuranceScenario?.budgetCountyPractices?.length
                && insuranceScenario?.budgetCountyPractices?.length > 1
                && insuranceScenario?.totals?.counties.length >= 1
            ) &&
                insuranceScenario?.totals?.counties?.map((currentCounty, countyIndex) => {
                    const _countyScenarios = insuranceScenario?.budgetCountyPractices?.filter(ins =>
                        ins.state_name == currentCounty.state_name
                        && ins.county_name == currentCounty.county_name
                    );
                    return <div key={countyIndex}>
                        <Typography variant='h6' sx={{ mb: 1 }}>
                            {currentCounty?.county_name + ', ' + currentCounty?.state_name}
                        </Typography>
                        <Box className='cardContainer' key={countyIndex}>
                            {_countyScenarios.map((currentCounty, index) =>
                                <InsuranceCountyPracticeCard key={countyIndex + "/" + index}
                                    insuranceScenario={insuranceScenario} budgetCountyPractice={currentCounty}
                                    isLoading={isLoading}
                                />
                            )}
                        </Box>
                        <br />
                    </div>
                })}

            {Boolean(insuranceScenario?.budgetCountyPractices?.length
                && (insuranceScenario?.budgetCountyPractices?.length == 1 || (insuranceScenario?.totals?.counties || 0) < 1)
            ) && <>
                    <Box className='cardContainer'>
                        {insuranceScenario?.budgetCountyPractices.map((currentCounty, index) =>
                            <InsuranceCountyPracticeCard key={index}
                                insuranceScenario={insuranceScenario} budgetCountyPractice={currentCounty}
                                isLoading={isLoading} />
                        )}
                    </Box>
                </>}

            {!isValid() && !Boolean(insuranceScenario?.budgetCountyPractices?.length) &&
                <Box className='cardContainer'>
                    <EmptyCard
                        text="No crop plans listed"
                        secondaryText="Provide a name and select a year"
                        isLoading={isLoading}
                    />
                </Box>}

            {isValid() && !Boolean(insuranceScenario?.budgetCountyPractices?.length) &&
                <Box className='cardContainer'>
                    <EmptyCard
                        text="No crop plans listed"
                        secondaryText="First, setup your crop plans in the farm vault & create a budget"
                        isLoading={isLoading}
                    />
                </Box>}

            <WorkspaceActionBar content={<></>} />

            {confirmDialogComponent}
        </>
    )
}
