import { useDialog } from '@datanac/datanac-mui-toolkit'
import { useCardController } from '@datanac/datanac-mui-toolkit/dist/hooks'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { Box, Card, CardActions, CardContent, Chip, Divider, Grid, Skeleton, Stack, Typography, useMediaQuery } from '@mui/material'
import { AppContext } from 'App'
import { useArcPlc } from 'api-toolkit/useArcPlc'
import { UsersApiHelper } from 'api/ApiHelper'
import EmptyCard from 'components/Cards/EmptyCard'
import { ArcPlcIcon } from 'components/Icons/Icons'
import { MODULE_ARC_PLC } from 'components/Menu/NavigationMenu'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { WORKSPACE_VIEW_CARD } from 'components/Workspaces/helpers'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatNumber, formatPrice } from 'utility/formatters'
import { v4 as uuidv4 } from 'uuid'
import { PROGRAM_NAME_ARCCO, PROGRAM_NAME_PLC } from './ArcPlcService'
import ArcPlcProducerFarmCropCard from './components/ArcPlcProducerFarmCropCard'

export default function ArcPlcCountyBaseAcreWorkspace() {
    const { globalState, globalDispatch, session } = useContext(AppContext);

    const {
        crop_year,
        location_state_name, location_county_name,
        commodity_name
    } = useParams();
    const navigate = useNavigate();

    const [state, setState] = useState({});

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingBaseAcres, setIsLoadingBaseAcres] = useState(true);

    // --- --- ---

    const [baseAcresWorkspaceMode, setBaseAcresWorkspaceMode] = useState(WORKSPACE_VIEW_CARD);
    const { state: baseAcres, actions: baseAcreActions } = useCardController([]);
    const arcPlc = useArcPlc({ baseAcres });

    const loadBaseAcres = async () => {
        try {
            setIsLoadingBaseAcres(false);

            const baseAcresFilter = {
                crop_year,
                producer_token: globalState.producer_token,
                location_state_name, location_county_name,
                commodity_name,
                is_active: true
            };
            const baseAcres = await UsersApiHelper.selectProducerFarmBaseAcres(baseAcresFilter, globalState);
            baseAcreActions.replace(baseAcres);

            setIsLoadingBaseAcres(false);
        } catch (err) {
            setIsLoadingBaseAcres(false);
            baseAcreActions.replace([]);
            console.warn(err);
            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (location_state_name && location_county_name && globalState?.producer_token) {
            loadBaseAcres();
        }
    }, [location_state_name, location_county_name, globalState?.producer_token])

    // --- --- ---

    const [programElection, setProgramElection] = useState(null);
    const [isLoadingProgramElection, setIsLoadingProgramElection] = useState(true);
    const loadProgramElection = async () => {
        try {
            setIsLoadingProgramElection(true);

            const programElectionFilter = {
                crop_year,
                state_name: location_state_name,
                county_name: location_county_name,
                commodity_name,
                program_category: MODULE_ARC_PLC,
                is_active: true
            };
            const _programElections = await UsersApiHelper.users_selectObject("ProgramElection", programElectionFilter, globalState);
            if (_programElections?.length) {
                setProgramElection(_programElections[0]);
            } else {
                setProgramElection(null);
            }

            setIsLoadingProgramElection(false);
        } catch (err) {
            setIsLoadingProgramElection(false);
            setProgramElection(null);
            console.warn(err);
            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (crop_year && location_state_name && location_county_name && commodity_name && globalState?.producer_token) {
            loadProgramElection();
        } else {
            setProgramElection(null);
        }
    }, [crop_year, location_state_name, location_county_name, commodity_name, globalState?.producer_token])

    // --- --- ---

    const [totals, setTotals] = useState({});
    const calculateTotals = async () => {
        const _base_acreage = baseAcres.reduce((accumulator, currentValue) =>
            accumulator + currentValue?.base_acreage, 0);

        setTotals({
            base_acreage: _base_acreage
        });
    }

    useEffect(() => {
        if (baseAcres?.length) {
            calculateTotals();
        }
    }, [globalState?.producer_token, baseAcres]);

    // --- --- ---

    const handleProgramElectionClick = async (_new_program_name) => {
        const PROGRAM_ELECTION_DEFAULT = {
            crop_year,
            state_name: location_state_name,
            county_name: location_county_name,
            commodity_name,
            program_category: MODULE_ARC_PLC,
            is_active: true,
            producer_token: globalState?.producer_token,
        }

        const _newProgramElection = programElection || PROGRAM_ELECTION_DEFAULT;
        _newProgramElection.program_election = _new_program_name;

        if (!_newProgramElection.id) {
            _newProgramElection.id = uuidv4();
        }

        UsersApiHelper.updateProgramElection(_newProgramElection, globalState)
            .then(() => {
                loadProgramElection();
            })
            .catch(err => {
                console.warn(err);
                // formStatusActions.setIsFormDirty(true, false);
                enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
            });
    }

    // --- --- ---

    const calculateArcPlcResponseRecord = (producer_farm_base_acres_id) => {
        if (arcPlc?.responses?.length) {
            const _response = arcPlc.responses.find(_arcplc => _arcplc.baseAcres?.id == producer_farm_base_acres_id)?.arcPlcResponse;
            return _response;
        }
    }

    // --- --- ---

    return (
        <>
            <WorkspaceHeader
                title={!(commodity_name) ? "ARC/PLC"
                    : commodity_name
                }
                breadCrumbs={[
                    {
                        title: 'ARC/PLC ' + crop_year,
                        path: '/arcplc',
                        icon: <ArcPlcIcon fontSize='3pt' />
                    },
                    {
                        title: location_county_name + ", " + location_state_name,
                        path: `/arcplc/${crop_year}/${location_state_name}/${location_county_name}`,
                        icon: null
                    }
                ]}
            />

            <Typography variant='h1'>
                Program Comparison by County / Crop
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={5} xl={3}>
                    <Card sx={{ height: "100%" }}>
                        <CardContent>
                            <Stack direction="column" spacing={1} sx={{ justifyContent: "space-between", height: "100%" }}>
                                {!arcPlc?.isLoading && <>
                                    {(arcPlc?.totals?.arcPayment == null) &&
                                        <Typography variant='h1'>--</Typography>}
                                    {(arcPlc?.totals?.arcPayment == 0) &&
                                        <Chip icon={<InfoOutlined />} label={"No ARC-CO payment projected"} />}
                                    {Boolean(arcPlc?.totals?.arcPayment) &&
                                        <Typography variant='h1'>{formatPrice(arcPlc?.totals?.arcPayment, 0)}</Typography>}
                                </>}
                                {arcPlc?.isLoading &&
                                    <Typography variant='h1'>
                                        <Skeleton />
                                    </Typography>}
                                <Typography variant='body1'>
                                    ARC-CO<br />
                                    PAYMENT
                                </Typography>
                            </Stack>
                        </CardContent>
                        <CardActions>
                            <Chip
                                label={programElection?.program_election == PROGRAM_NAME_ARCCO ? 'Participating' : 'Not Participating'}
                                color={programElection?.program_election == PROGRAM_NAME_ARCCO ? 'secondary' : 'default'}
                                onClick={() => handleProgramElectionClick(PROGRAM_NAME_ARCCO)}
                            />
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6} lg={5} xl={3}>
                    <Card sx={{ height: "100%" }}>
                        <CardContent>
                            <Stack direction="column" spacing={1} sx={{ justifyContent: "space-between", height: "100%" }}>
                                {!arcPlc?.isLoading && <>
                                    {(arcPlc?.totals?.plcPayment == null) &&
                                        <Typography variant='h1'>--</Typography>}
                                    {(arcPlc?.totals?.plcPayment == 0) &&
                                        <Chip icon={<InfoOutlined />} label={"No PLC payment projected"} />}
                                    {Boolean(arcPlc?.totals?.plcPayment) &&
                                        <Typography variant='h1'>{formatPrice(arcPlc?.totals?.plcPayment, 0)}</Typography>}
                                </>}
                                {arcPlc?.isLoading &&
                                    <Typography variant='h1'>
                                        <Skeleton />
                                    </Typography>}
                                <Typography variant='body1'>
                                    PLC<br />
                                    PAYMENT
                                </Typography>
                            </Stack>
                        </CardContent>
                        <CardActions>
                            <Chip
                                label={programElection?.program_election == PROGRAM_NAME_PLC ? 'Participating' : 'Not Participating'}
                                color={programElection?.program_election == PROGRAM_NAME_PLC ? 'secondary' : 'default'}
                                onClick={() => handleProgramElectionClick(PROGRAM_NAME_PLC)}
                            />
                        </CardActions>
                    </Card>
                </Grid>
            </Grid >

            <Divider />

            <Typography variant='h1'>
                Based On
            </Typography>
            <Stack direction="row" spacing={1}>
                <Stack direction="column">
                    <Typography variant='h1'>
                        {!arcPlc?.isLoading && <>
                            {formatNumber(arcPlc?.totals?.base_acreage, 0)}
                        </>}
                        {arcPlc?.isLoading && <>
                            <Skeleton />
                        </>}
                    </Typography>
                    <Typography variant='body1'>
                        BASE ACRES
                    </Typography>
                </Stack>

                <Divider orientation='vertical' flexItem />

                <Stack direction="column">
                    <Typography variant='h1'>
                        {!arcPlc?.isLoading && <>
                            {formatNumber(arcPlc?.totals?.plc_yield, 0)}
                        </>}
                        {arcPlc?.isLoading && <>
                            <Skeleton />
                        </>}
                    </Typography>
                    <Typography variant='body1'>
                        PLC YIELD
                    </Typography>
                </Stack>
            </Stack>

            <Divider />

            <Typography variant='h1' sx={{ textTransform: 'capitalize' }}>
                Base Acres
            </Typography>

            {
                baseAcresWorkspaceMode == WORKSPACE_VIEW_CARD &&
                <Box className='cardContainer'>
                    {baseAcres.map((_currentBaseAcres, index) => {
                        const _currentArcPlcResponse = calculateArcPlcResponseRecord(_currentBaseAcres?.id);
                        return <ArcPlcProducerFarmCropCard key={index}
                            currentBaseAcres={_currentBaseAcres}
                            currentArcPlc={_currentArcPlcResponse}
                            isLoadingArcPlc={arcPlc?.isLoading}
                            isErrorArcPlc={arcPlc?.isError} />
                    })}
                    {!Boolean(baseAcres?.length) &&
                        <EmptyCard
                            text="No base acres listed"
                            secondaryText="Get started by importing or adding base acres"
                            isLoading={isLoadingBaseAcres}
                        />}
                </Box>
            }

            {confirmDialogComponent}
        </>
    )
}
