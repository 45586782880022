import { ProducerFarmHelper } from '@datanac/datanac-api-toolkit'
import { useCardController, useDialog } from '@datanac/datanac-mui-toolkit'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import CloudDownloadOutlined from '@mui/icons-material/CloudDownloadOutlined'
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined'
import UploadFileOutlined from '@mui/icons-material/UploadFileOutlined'
import { Box, Dialog, Divider, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { AppContext } from 'App'
import { UsersApiHelper } from 'api/ApiHelper'
import EmptyCard from 'components/Cards/EmptyCard'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FarmCountyCard from './components/FarmCountyCard'
import ImportUploadFileDialog from './components/Import/ImportUploadFileDialog'
import ProducerFarmCopyAipPassDialog from './components/Import/ProducerFarmCopyAipPassDialog'
import ProducerFarmCopyDialog from './components/Import/ProducerFarmCopyDialog'
import { getUniqueCounties } from './helpers'

export default function VaultPotentialWorkspace() {
    const producerFarmHelper = new ProducerFarmHelper();

    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);

    const [isCopyYearDialogOpen, setIsCopyYearDialogOpen] = useState(false);
    const handleCopyYearDialogClose = (_crop_year) => {
        setIsCopyYearDialogOpen(false);
        // if (_crop_year && _crop_year != currentYear) {
        //     setCurrentYear(_crop_year);
        // } else {
        //     loadCurrent();
        // }
    }

    const [isCopyAipPassDialogOpen, setIsCopyAipPassDialogOpen] = useState(false);
    const handleCopyAipPassDialogClose = (_crop_year) => {
        setIsCopyAipPassDialogOpen(false);
        loadPotential();
    }

    const [isUploadFileDialogOpen, setIsUploadFileDialogOpen] = useState(false);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const [isLoadingPotential, setIsLoadingPotential] = useState(true);
    const { state: potentialCards, actions: potentialActions } = useCardController();
    const loadPotential = async () => {
        try {
            const producerFilter = {
                producer_token: globalState.producer_token,
                is_active: true
            };
            const farms = await UsersApiHelper.selectProducerFarms(producerFilter, globalState);
            const potentials = getUniqueCounties(farms);
            potentialActions.replace(potentials);

            setIsLoadingPotential(false);
        } catch (err) {
            setIsLoadingPotential(false);
            potentialActions.replace([]);
            console.warn(err);
            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (globalState?.producer_token) {
            loadPotential();
        }
    }, [globalState?.producer_token]);

    const handleAddNewClick = () => {
        // navigate(`/`);
    }

    return (
        <>
            <WorkspaceHeader
                title='Farm Vault'
                breadCrumbs={[]}
            />

            <Typography variant='h1'>
                Farm Potential
            </Typography>
            <Box className='cardContainer'>
                {potentialCards.map((currentCountyPractice, index) =>
                    <FarmCountyCard currentCounty={currentCountyPractice} key={index} />
                )}
                {!Boolean(potentialCards?.length) &&
                    <EmptyCard
                        text="No farms listed"
                        secondaryText="Get started by importing history or adding a farm"
                        isLoading={isLoadingPotential}
                    />}
            </Box>

            <WorkspaceActionBar content={<>
                <Stack direction="row" spacing={2}>

                    {/* {[USER_ROLE_ADMIN, USER_ROLE_AGENT].includes(globalState?.user_role) && */}
                    <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                            <>
                                <Button variant="contained" {...bindTrigger(popupState)}>
                                    <ArrowDropDownOutlined sx={{ mr: 1 }} />
                                    Import
                                </Button>
                                <Menu {...bindMenu(popupState)}>
                                    <MenuItem onClick={() => {
                                        setIsCopyYearDialogOpen(true);
                                        popupState.close();
                                    }}>
                                        <ContentCopyOutlined sx={{ mr: 1 }} /> Copy Prior Season ...
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        setIsUploadFileDialogOpen(true);
                                        popupState.close();
                                    }}>
                                        <UploadFileOutlined sx={{ mr: 1 }} /> Upload file ...
                                    </MenuItem>

                                    <Divider />

                                    <MenuItem onClick={() => {
                                        setIsCopyAipPassDialogOpen(true);
                                        popupState.close();
                                    }}>
                                        <CloudDownloadOutlined sx={{ mr: 1 }} /> Import from History ...
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                    </PopupState>

                    {/* <Button variant="contained">
                        <AddCircleOutline sx={{ mr: 1 }} />
                        New Farm
                    </Button> */}
                </Stack>
            </>} />

            {isCopyYearDialogOpen && <Dialog open={isCopyYearDialogOpen} fullWidth={isSmall} PaperProps={{ sx: { minHeight: "80vh" } }}>
                <ProducerFarmCopyDialog onClose={handleCopyYearDialogClose} />
            </Dialog>}

            {isUploadFileDialogOpen && <Dialog open={isUploadFileDialogOpen} fullWidth={isSmall} PaperProps={{ sx: {} }}>
                <ImportUploadFileDialog year={currentYear} onClose={() => { setIsUploadFileDialogOpen(false); loadPotential(); }} />
            </Dialog>}



            {isCopyAipPassDialogOpen && <Dialog open={isCopyAipPassDialogOpen} fullWidth={isSmall} PaperProps={{ sx: { minHeight: "80vh" } }}>
                <ProducerFarmCopyAipPassDialog onClose={handleCopyAipPassDialogClose} />
            </Dialog>}

            {confirmDialogComponent}
        </>
    )
}
