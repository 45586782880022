import { useCardController } from '@datanac/datanac-mui-toolkit'
import { AppContext } from 'App'
import { UsersApiHelper } from 'api/ApiHelper'
import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { formatPracticeName } from 'utility/formatters'
import { getUniqueCounties } from './helpers'
import { _getUniqueCountyPractices } from '@datanac/datanac-api-toolkit/dist/utility/ProducerFarmHelper'

export const useFarmController = ({
    crop_year,

    filter,

    onError
}) => {
    const { globalState } = useContext(AppContext);

    const [isLoading, setIsLoading] = useState(true);

    // ---

    // Load (1) Crops/Fields (2) Farms (3) CountyPractices

    const [isLoadingProducerFarmFieldCrops, setIsLoadingProducerFarmFieldCrops] = useState(null);
    const [producerFarmFieldCrops, setProducerFarmFieldCrops] = useState(null);
    const loadProducerFarmFieldCrop = async () => {
        try {
            const producerFilter = {
                producer_token: globalState.producer_token,
                crop_year,
                is_active: true
            };
            const crops = await UsersApiHelper.selectProducerFarmFieldCrops(producerFilter, globalState);

            if (crops?.length) {
                setProducerFarmFieldCrops(crops
                    ?.filter(c => (
                        Boolean(!filter?.commodity_name?.length)
                        || filter?.commodity_name?.includes(c.commodity_name)
                    ))
                    ?.filter(c => (
                        Boolean(!filter?.practice_name?.length)
                        || filter?.practice_name?.includes(c.practice_name)
                    ))
                    ?.sort((a, b) =>
                        (a?.fsa_farm_number || 0) - (b?.fsa_farm_number || 0)
                        || (a?.fsa_tract_number || 0) - (b?.fsa_tract_number || 0)
                        || a.fsa_field_number?.localeCompare(b.fsa_field_number)
                    )
                    ?.sort((a, b) =>
                        a.commodity_name?.localeCompare(b.commodity_name)
                        || a.type_name?.localeCompare(b.type_name)
                        || a.practice_name?.localeCompare(b.typpractice_namee_name)
                    )
                );
            } else {
                setProducerFarmFieldCrops(null);
            }

            setIsLoadingProducerFarmFieldCrops(false);
        } catch (err) {
            setIsLoading(false);

            setIsLoadingProducerFarmFieldCrops(false);
            setProducerFarmFieldCrops(null);
            console.warn(err);
            onError && onError(err);
        }
    }

    useEffect(() => {
        if (globalState?.producer_token) {
            loadProducerFarmFieldCrop();
        }
    }, [globalState?.producer_token, filter]);

    // ---

    const [producerFarms, setProducerFarms] = useState(null);
    const loadProducerFarms = async () => {
        try {
            const producerFilter = {
                producer_token: globalState.producer_token,
                crop_year,
                is_active: true
            };
            const farms = await UsersApiHelper.selectProducerFarms(producerFilter, globalState);
            if (farms?.length) {
                if (producerFarmFieldCrops?.length) {
                    farms.forEach(f => {
                        f.ProducerFarmFieldCrops = producerFarmFieldCrops
                            ?.filter(crop =>
                                crop.producer_farm_id == f.id
                            )
                            ?.sort((a, b) =>
                                a.commodity_name?.localeCompare(b.commodity_name)
                                || a.type_name?.localeCompare(b.type_name)
                                || a.practice_name?.localeCompare(b.typpractice_namee_name)
                            );
                    })
                }

                setProducerFarms(farms);
            } else {
                setProducerFarms([]);
            }

            setIsLoading(false);
        } catch (err) {
            setProducerFarms(null);
            setIsLoading(false);
            console.warn(err);
            onError && onError(err);
        }
    }

    useEffect(() => {
        if (globalState?.producer_token && crop_year && producerFarmFieldCrops?.length) {
            loadProducerFarms();
        } else {
            setProducerFarms(null);
        }
    }, [globalState?.producer_token, crop_year, producerFarmFieldCrops]);

    // ---

    const [isLoadingCounties, setIsLoadingCounties] = useState(true);
    const [counties, setCounties] = useState(null);

    const loadCounties = async () => {
        try {
            if (producerFarms?.length) {
                const _counties = getUniqueCounties(producerFarms);

                if (_counties?.length) {
                    _counties.forEach(cp => {
                        cp.ProducerFarms = producerFarms
                            ?.filter(f =>
                                f.location_state_name == cp.location_state_name
                                && f.location_county_name == cp.location_county_name
                            )
                            ?.sort((a, b) =>
                                (a?.fsa_farm_number || 0) - (b?.fsa_farm_number || 0)
                            );
                    });

                    setCounties(_counties);
                } else {
                    setCounties(null);
                }
            } else {
                setCounties(null);
            }

            setIsLoadingCounties(false);
        } catch (err) {
            setCounties(null);
            setIsLoadingCounties(false);
            console.warn(err);
            onError && onError(err);
        }
    }

    useEffect(() => {
        if (globalState?.producer_token && crop_year && producerFarms?.length) {
            loadCounties();
        } else {
            setCounties(null);
        }
    }, [globalState?.producer_token, crop_year, producerFarms]);

    // ---

    const [isLoadingCountyPractices, setIsLoadingCountyPractices] = useState(true);
    const [countyPractices, setCountyPractices] = useState(null);

    const loadCountyPractices = async () => {
        try {
            if (producerFarms?.length) {
                const _allCountyPractices = [];
                producerFarms?.forEach(currentFarm => {
                    currentFarm.ProducerFarmFieldCrops.forEach(currentCrop => {
                        _allCountyPractices.push({
                            ...currentFarm,
                            ...currentCrop
                        })
                    })
                })
                const countyPractices = _getUniqueCountyPractices(_allCountyPractices);

                setCountyPractices(countyPractices);
            } else {
                setCountyPractices(null);
            }

            setIsLoadingCountyPractices(false);
        } catch (err) {
            setCountyPractices(null);
            setIsLoadingCountyPractices(false);
            console.warn(err);
            onError && onError(err);
        }
    }

    useEffect(() => {
        if (globalState?.producer_token && crop_year && producerFarms?.length) {
            loadCountyPractices();
        } else {
            setCountyPractices(null);
        }
    }, [globalState?.producer_token, crop_year, producerFarms]);

    // ---

    const { state: producerFarm_union_Crops, actions: producerFarm_union_CropActions } = useCardController();


    return ({
        counties,
        countyPractices,

        producerFarms,

        isLoadingProducerFarmFieldCrops,
        producerFarmFieldCrops,

        producerFarm_union_Crops,

        isLoading,
        isLoadingCounties
    });
}