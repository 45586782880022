import { ProducerFarmHelper } from '@datanac/datanac-api-toolkit'
import { getCurrentReinsuranceYear } from '@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper'
import { useCardController, useDialog } from '@datanac/datanac-mui-toolkit'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { Box, Card, CardContent, Chip, Divider, Grid, Menu, MenuItem, Skeleton, Stack, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { AppContext } from 'App'
import { useArcPlc } from 'api-toolkit/useArcPlc'
import { UsersApiHelper } from 'api/ApiHelper'
import EmptyCard from 'components/Cards/EmptyCard'
import CountyDataView from 'components/County/CountyDataView'
import { ArcPlcIcon } from 'components/Icons/Icons'
import { getUniqueBaseAcreCrops } from 'components/Vault/Farm/helpers'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import WorkspaceViewSelector from 'components/Workspaces/WorkspaceModeSelector'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatPrice } from 'utility/formatters'
import ArcPlcCountyCropCard from './components/ArcPlcCountyCropCard'
import ArcPlcProducerFarmBaseAcreDataTable from './components/ArcPlcProducerFarmBaseAcreDataGrid'

export default function ArcPlcCountyWorkspace() {
    const producerFarmHelper = new ProducerFarmHelper();

    const { globalState, globalDispatch } = useContext(AppContext);
    const {
        crop_year,
        location_state_name, location_county_name
    } = useParams();
    const navigate = useNavigate();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingBaseAcres, setIsLoadingBaseAcres] = useState(true);

    const [workspaceMode, setWorkspaceMode] = useState(WORKSPACE_VIEW_CARD);
    const { state: producerFarmCrops, actions: producerFarmCropActions } = useCardController();

    const [countyPractices, setCountyPractices] = useState([]);
    const [selectedCountyPractice, setSelectedCountyPractice] = useState({});

    const loadData = async () => {
        try {
            setIsLoading(true);

            const producerFilter = {
                producer_token: globalState.producer_token,
                location_state_name,
                location_county_name,
                crop_year,
                is_active: true
            };
            const farms = await UsersApiHelper.selectProducerFarms(producerFilter, globalState);
            producerFarmCropActions.replace(farms);

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            producerFarmCropActions.replace([]);
            console.warn(err);
            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (globalState?.producer_token) {
            loadData();
        } else {
            setIsLoading(false);
        }
    }, [globalState?.producer_token]);


    // --- --- ---

    const [baseAcresWorkspaceMode, setBaseAcresWorkspaceMode] = useState(WORKSPACE_VIEW_CARD);
    const { state: baseAcres, actions: baseAcreActions } = useCardController([]);

    const arcPlc = useArcPlc({
        baseAcres: baseAcres.reduce((accumulator, currentValue) => {
            return [...accumulator, ...currentValue.baseAcres]
        }, [])
    });

    const loadBaseAcres = async () => {
        try {
            setIsLoadingBaseAcres(true);

            const baseAcresFilter = {
                crop_year,
                producer_token: globalState.producer_token,
                location_state_name, location_county_name,
                is_active: true
            };
            const baseAcres = await UsersApiHelper.selectProducerFarmBaseAcres(baseAcresFilter, globalState);

            const _uniqueCrops = getUniqueBaseAcreCrops(baseAcres);

            baseAcreActions.replace(_uniqueCrops);

            setIsLoadingBaseAcres(false);
        } catch (err) {
            setIsLoadingBaseAcres(false);
            baseAcreActions.replace([]);
            console.warn(err);
            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (location_state_name && location_county_name && globalState?.producer_token) {
            loadBaseAcres();
        }
    }, [location_state_name, location_county_name, globalState?.producer_token])

    // --- --- ---

    const [totals, setTotals] = useState({});
    const calculateTotals = async () => {
        const _base_acreage = baseAcres.reduce((accumulator, currentValue) =>
            accumulator + currentValue?.base_acreage, 0);

        setTotals({
            base_acreage: _base_acreage
        });
    }

    useEffect(() => {
        if (baseAcres?.length) {
            calculateTotals();
        }
    }, [globalState?.producer_token, baseAcres]);

    // --- --- ---

    const calculateCropTotals = (_currentBaseAcres) => {
        const _arcplc = arcPlc?.crops?.find(_crop => _crop.commodity_name == _currentBaseAcres?.commodity_name)
        return _arcplc;
    }

    // --- --- ---

    return (
        <>
            <WorkspaceHeader
                title={Boolean(location_state_name && location_county_name) ?
                    (location_county_name + ", " + location_state_name)
                    : "ARC/PLC"}
                breadCrumbs={[
                    {
                        title: 'ARC/PLC ' + crop_year,
                        path: '/arcplc',
                        icon: <ArcPlcIcon fontSize='3pt' />
                    },
                ]}
            />

            <Typography variant='h1'>
                Program Comparison by County
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={5} xl={3}>
                    <Card sx={{ height: "100%" }}>
                        <CardContent>
                            <Stack direction="column" spacing={1} sx={{ justifyContent: "space-between", height: "100%" }}>
                                {!(arcPlc?.isLoading && (isLoadingBaseAcres ? true : Boolean(baseAcres?.length))) && <>
                                    {(arcPlc?.totals?.arcPayment == null) &&
                                        <Typography variant='h1'>--</Typography>}
                                    {(arcPlc?.totals?.arcPayment == 0) &&
                                        <Chip icon={<InfoOutlined />} label={"No ARC-CO payment projected"} />}
                                    {Boolean(arcPlc?.totals?.arcPayment) &&
                                        <Typography variant='h1'>{formatPrice(arcPlc?.totals?.arcPayment, 0)}</Typography>}
                                </>}
                                {(arcPlc?.isLoading && (isLoadingBaseAcres ? true : Boolean(baseAcres?.length))) &&
                                    <Typography variant='h1'>
                                        <Skeleton />
                                    </Typography>}
                                <Typography variant='body1'>
                                    ARC-CO<br />
                                    PAYMENT
                                </Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6} lg={5} xl={3}>
                    <Card sx={{ height: "100%" }}>
                        <CardContent>
                            <Stack direction="column" spacing={1} sx={{ justifyContent: "space-between", height: "100%" }}>
                                {!(arcPlc?.isLoading && (isLoadingBaseAcres ? true : Boolean(baseAcres?.length))) && <>
                                    {(arcPlc?.totals?.plcPayment == null) &&
                                        <Typography variant='h1'>--</Typography>}
                                    {(arcPlc?.totals?.plcPayment == 0) &&
                                        <Chip icon={<InfoOutlined />} label={"No PLC payment projected"} />}
                                    {Boolean(arcPlc?.totals?.plcPayment) &&
                                        <Typography variant='h1'>{formatPrice(arcPlc?.totals?.plcPayment, 0)}</Typography>}
                                </>}
                                {(arcPlc?.isLoading && (isLoadingBaseAcres ? true : Boolean(baseAcres?.length))) &&
                                    <Typography variant='h1'>
                                        <Skeleton />
                                    </Typography>}
                                <Typography variant='body1'>
                                    PLC<br />
                                    PAYMENT
                                </Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Divider />

            {workspaceMode == WORKSPACE_VIEW_CARD && <>
                <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                    <Typography variant='h1'>
                        Crops
                    </Typography>
                    <WorkspaceViewSelector workspaceMode={workspaceMode} setWorkspaceMode={setWorkspaceMode} />
                </Stack>
                <Box className='cardContainer'>
                    {baseAcres.map((currentBaseAcres, index) => {
                        const _currentArcPlc = calculateCropTotals(currentBaseAcres);
                        return <ArcPlcCountyCropCard key={index}
                            producerFarm={producerFarmCrops}
                            baseAcres={currentBaseAcres}
                            arcPlc={_currentArcPlc}
                            isLoadingArcPlc={arcPlc?.isLoading}
                            isErrorArcPlc={arcPlc?.isError} />
                    }
                    )}
                    {!Boolean(baseAcres?.length) &&
                        <EmptyCard
                            text="No base acres listed"
                            secondaryText="Setup base acres in the farm vault"
                            isLoading={isLoading}
                        />}
                </Box>
            </>}

            {workspaceMode == WORKSPACE_VIEW_TABLE &&
                <ArcPlcProducerFarmBaseAcreDataTable producerFarm={producerFarmCrops} producerFarmBaseAcres={baseAcres}
                    title={
                        <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                            <Typography variant='h1'>
                                Crops
                            </Typography>
                            <WorkspaceViewSelector workspaceMode={workspaceMode} setWorkspaceMode={setWorkspaceMode} />
                        </Stack>
                    } />}

            {Boolean(countyPractices?.length)
                && location_state_name
                && location_county_name
                && selectedCountyPractice?.commodity_name
                && selectedCountyPractice?.type_name
                && selectedCountyPractice?.practice_name && <>
                    <Divider />
                    <Typography variant='h1' display="inline">
                        County History
                    </Typography>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                            <>
                                <Button variant="text" {...bindTrigger(popupState)}>
                                    <ArrowDropDownOutlined />
                                </Button>
                                <Menu {...bindMenu(popupState)}>
                                    {countyPractices.map((cp, index) =>
                                        <MenuItem key={index} onClick={() => {
                                            setSelectedCountyPractice(cp);
                                            popupState.close();
                                        }}>
                                            {cp.commodity_name}
                                            / {cp.type_name == "No Type Specified" ? "" : cp.type_name + " /"}
                                            {cp.practice_name}
                                        </MenuItem>)}
                                </Menu>
                            </>
                        )}
                    </PopupState>

                    {selectedCountyPractice
                        && location_state_name
                        && location_county_name
                        && selectedCountyPractice?.commodity_name
                        && selectedCountyPractice?.type_name
                        && selectedCountyPractice?.practice_name && <>
                            <CountyDataView countyPractice={{
                                ...selectedCountyPractice,
                                reinsurance_year: getCurrentReinsuranceYear(),
                                location_state_name,
                                location_county_name,
                            }} />
                        </>}
                </>}

            {confirmDialogComponent}
        </>
    )
}
