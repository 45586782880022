import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import SearchOutlined from '@mui/icons-material/SearchOutlined'
import { Box, CircularProgress, Dialog, DialogTitle, InputAdornment, List, Skeleton, Stack, Tooltip, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { AppContext } from 'App'
import EmptyCard from 'components/Cards/EmptyCard'
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import MemberDataTable, { MEMBER_ACTION_MANAGE_ACCOUNT } from 'components/Member/components/MemberDataTable'
import UserGrantInviteDialog from 'components/UserGrants/components/UserGrantEditPanel'
import UserGrantItem from 'components/UserGrants/components/UserGrantItem'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { USER_ROLE_ADMIN, USER_ROLE_AGENT } from 'core/useSession'
import { uniqBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { UsersApiHelper } from '../../api/ApiHelper'
import PortfolioItem from './PortfolioItem'

export default function Portfolio({
}) {
    const { globalState, globalDispatch } = useContext(AppContext);
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [searchText, setSearchText] = useState(globalState?.search_text_portfolio);
    const handleSearchOnChange = (event) => {
        setSearchText(event?.target?.value);
        persistSearchText(event?.target?.value);
    }

    const persistSearchText = async (searchText) => {
        globalDispatch({
            type: 'update',
            payload: {
                key: "search_text_portfolio",
                value: searchText,
            },
        });
    }

    const [members, setMembers] = useState([]);
    const searchMembers = async () => {
        try {
            const __authTokens = { ...globalState };
            if (globalState.user_role == USER_ROLE_ADMIN) {
                delete __authTokens.agent_token;
            }
            delete __authTokens.producer_token;

            let _membersLastName = UsersApiHelper.selectMembers({ last_name: searchText }, __authTokens);
            let _membersBusinessName = UsersApiHelper.selectMembers({ business_name: searchText }, __authTokens);

            const _members = [...(await _membersLastName), ...(await _membersBusinessName)];
            const _uniqueMembers = uniqBy(_members, p => p.id);

            const searchTokens = searchText
                ?.split(/,| /)
                ?.filter(t => t)
                ?.map(t => t?.toLowerCase());
            if (searchTokens?.length) {
                _uniqueMembers.forEach(p => {
                    let searchScore = 0;
                    p.first_name?.split(' ')?.forEach(token => {
                        searchScore += (searchTokens.filter(t => t == token?.toLowerCase()))?.length;
                    });
                    p.last_name?.split(' ')?.forEach(token => {
                        searchScore += (searchTokens.filter(t => t == token?.toLowerCase()))?.length;
                    });
                    p.business_name?.split(' ')?.forEach(token => {
                        searchScore += (searchTokens.filter(t => t == token?.toLowerCase()))?.length;
                    });

                    p.searchScore = searchScore;
                });
            }

            const _uniqueMembersSorted = _uniqueMembers.sort((a, b) => (b?.searchScore || 0) - (a?.searchScore || 0));

            setMembers(_uniqueMembersSorted);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setMembers([]);
            console.warn(err);
            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
        }
    };

    const [userGrants, setUserGrants] = useState([]);
    const loadUserGrants = async () => {
        try {
            const _userGrants = await UsersApiHelper.selectUserGrants(
                { user_id: globalState.agent_token },
                { user_role: globalState?.user_role, agent_token: globalState?.agent_token }
            );
            setUserGrants(_userGrants);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setUserGrants([]);
            console.warn(err);
            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
        }
    }

    const calculateActiveUserGrants = () => {
        if (userGrants?.length) {
            return userGrants
                .filter(ug => ug.is_active)
                .filter(ug => !searchText?.length || ug.producer_description?.toLowerCase()?.includes(searchText));
        } else {
            return [];
        }
    }

    const calculatePendingUserGrants = () => {
        if (userGrants?.length) {
            return userGrants.filter(ug => ug.is_active == null);
        } else {
            return [];
        }
    }

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const handleAddNewClick = () => {
        setIsDialogOpen(true);
    }

    const handleAddNewAdminClick = () => {
        navigate("/account/0");
    }

    const isValid = (currentUserGrant) => {
        return (
            currentUserGrant?.id
            && (currentUserGrant?.producer_token || currentUserGrant?.producer_email)
        );
    }
    // useBlockerPrompt(!isValid(), "Portfolio");

    const handleDialogOnChange = (currentUserGrant) => {
        currentUserGrant.id = uuidv4();
        currentUserGrant.agent_token = globalState?.agent_token;

        if (isValid(currentUserGrant)) {
            UsersApiHelper.inviteUserGrant(currentUserGrant, globalState)
                .then(() => {
                    setIsDialogOpen(false);
                    navigate(`/userGrant/${currentUserGrant?.producer_token || "0"}/${currentUserGrant.id}`);
                })
                .catch(err => {
                    console.warn(err);
                    enqueueSnackbar("Could not create this request.", { preventDuplicate: true, variant: 'error' });
                });
        }
    }

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true);

        if (globalState.user_role) {
            if (searchText && globalState.user_role == USER_ROLE_ADMIN) {
                // searchProducers();
                searchMembers();
            } else if (globalState.user_role != USER_ROLE_ADMIN) {
                loadUserGrants();
            } else {
                setIsLoading(false);
            }
        }
    }, [searchText, globalState.user_role, globalState.agent_token])

    const handleProducerItemClick = (currentProducer) => {
        const _userGrant = userGrants?.find(ug => ug?.producer_token?.toLowerCase() == currentProducer?.producer_token?.toLowerCase());
        if (_userGrant) {
            navigate(`/userGrant/${currentProducer?.producer_token || 0}/${_userGrant?.id}`)
        }
    }

    const handlePortfolioItemClick = (currentProducer) => {
        globalDispatch({
            type: 'setProducer',
            payload: {
                producer_token: currentProducer?.producer_token,
                agent_token: globalState?.agent_token
            },
        });
        navigate(`/vault`)
    }

    const handleItemAction = (currentMember, action) => {
        if (action == MEMBER_ACTION_MANAGE_ACCOUNT) {
            navigate(`/account/${currentMember?.id}`)
        }
    }

    return (
        <>
            <WorkspaceHeader
                title='Members'
                breadCrumbs={[]}
            />

            <Stack spacing={1}>
                <Typography variant='h5'>
                    Search Members:
                </Typography>

                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <StatefulControlledTextField
                        onChange={handleSearchOnChange}
                        defaultValue={globalState?.search_text_portfolio || ""}
                        fullWidth={isSmall}
                        sx={{ minWidth: '2in', maxWidth: '100%' }}
                        endAdornment={
                            <Tooltip title='Search'>
                                <InputAdornment position='end'>
                                    <IconButton edge="end" color="primary">
                                        <SearchOutlined />
                                    </IconButton>
                                </InputAdornment>
                            </Tooltip>
                        } />

                    {isLoading && <CircularProgress />}
                </Box>

                {Boolean(calculateActiveUserGrants()?.length || members?.length) && <>
                    <div>
                        <Divider />
                    </div>
                    {globalState.user_role != USER_ROLE_ADMIN &&
                        <Typography variant='h5'>
                            Active Members:
                        </Typography>}
                    {globalState.user_role == USER_ROLE_ADMIN &&
                        <Typography variant='h5'>
                            Search Results:
                        </Typography>}
                    <div>
                        {globalState?.user_role == USER_ROLE_ADMIN &&
                            <MemberDataTable title="Members"
                                members={members || calculateActiveUserGrants()}
                                onItemAction={(currentMember, action) => handleItemAction(currentMember, action)}
                                onItemSelect={(currentMember) => handlePortfolioItemClick(currentMember)} />
                        }

                        {globalState?.user_role != USER_ROLE_ADMIN && <>
                            <List dense sx={{ p: 0 }}>
                                {calculateActiveUserGrants()?.map((ug, index) => (
                                    <PortfolioItem userGrant={ug} key={ug.producer_token} onClick={handlePortfolioItemClick} />
                                ))}
                            </List>
                            <List dense sx={{ p: 0 }}>
                                {members?.map((currentMember, index) => (
                                    <PortfolioItem producer={currentMember} key={currentMember.producer_token} onClick={handlePortfolioItemClick} />
                                ))}
                            </List>
                        </>}
                    </div>
                </>}
                {isLoading
                    && !Boolean(calculateActiveUserGrants()?.length || members?.length)
                    && !Boolean(calculatePendingUserGrants()?.length) && <>
                        <Typography variant="h1">
                            <Skeleton />
                        </Typography><Typography variant="h1">
                            <Skeleton />
                        </Typography><Typography variant="h1">
                            <Skeleton />
                        </Typography>
                    </>}

                {globalState?.user_role == USER_ROLE_AGENT
                    && !isLoading
                    && !Boolean(calculateActiveUserGrants()?.length || members?.length)
                    && !Boolean(calculatePendingUserGrants()?.length) && <>
                        <EmptyCard text='No active members' secondaryText='Start by inviting a member' />
                    </>}

                {globalState?.user_role == USER_ROLE_ADMIN
                    && !isLoading
                    && !Boolean(calculateActiveUserGrants()?.length || members?.length)
                    && !Boolean(calculatePendingUserGrants()?.length) && <>
                        <EmptyCard text='No search results' secondaryText='Search for a producer' />
                    </>}

                {Boolean(calculatePendingUserGrants()?.length) && <>
                    <Divider />
                    <div>
                        <Typography variant='h5'>
                            Pending Invitations:
                        </Typography>
                        <List dense sx={{ p: 0 }}>
                            {calculatePendingUserGrants()?.map((ug, index) => (
                                <UserGrantItem userGrant={ug} isSelected={false} key={index}
                                    onClick={() => handleProducerItemClick(ug)}
                                />
                            ))}
                        </List>
                    </div>
                </>}
            </Stack>

            <Dialog open={isDialogOpen} fullWidth>
                <DialogTitle>
                    Invite New Member
                </DialogTitle>
                <UserGrantInviteDialog
                    producer_token={null} user_grant_id={null}
                    onChange={handleDialogOnChange}
                    onClose={() => setIsDialogOpen(false)} />
            </Dialog>

            <WorkspaceActionBar content={<>
                {globalState?.user_role == USER_ROLE_AGENT && <Button
                    variant="contained"
                    sx={{ width: '100%' }}
                    onClick={handleAddNewClick}
                >
                    <AddCircleOutline sx={{ mr: 1 }} />
                    Invite {!isSmall ? ' Member' : ''}
                </Button>}

                {globalState?.user_role == USER_ROLE_ADMIN && <Button
                    variant="contained"
                    sx={{ width: '100%' }}
                    onClick={handleAddNewAdminClick}
                >
                    <AddCircleOutline sx={{ mr: 1 }} />
                    New {!isSmall ? ' Member' : ''}
                </Button>}
            </>}
            />
        </>
    )
}
