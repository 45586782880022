import { USER_ROLE_PRODUCER } from '@datanac/datanac-api-toolkit/dist/api-helper'
import { getCurrentReinsuranceYear } from '@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper'
import useDialog from '@datanac/datanac-mui-toolkit/dist/hooks/useDialog'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import CurrencyExchangeOutlined from '@mui/icons-material/CurrencyExchangeOutlined'
import EastOutlined from '@mui/icons-material/EastOutlined'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import SearchOutlined from '@mui/icons-material/SearchOutlined'
import { Alert, Box, Button, Checkbox, Dialog, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Radio, RadioGroup, Select, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { AppContext } from 'App'
import { AipPassApiHelper, UsersApiHelper } from 'api/ApiHelper'
import DataFormSelect from 'components/Core/DataFormSelect'
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import { propUpdateReducer } from 'components/Core/propUpdateReducer'
import { MemberManagementIcon } from 'components/Icons/Icons'
import { useBlockerPrompt } from 'components/Menu/useBlockerPrompt'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { USER_ROLE_AGENT } from 'core/useSession'
import { SkeletonWrapper } from 'mui-toolkit/SkeletonWrapper'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatPrice } from 'utility/formatters'
import { v4 as uuidv4 } from 'uuid'
import AgentUserGrantDataTable from './components/AgentUserGrantDataTable'
import MemberUserDataTable from './components/MemberUserDataTable'
import { NewMemberDialog } from './components/NewMemberDialog'
import { NewUserDialog } from './components/NewUserDialog'
import ProducerSearchDialog from './components/ProducerSearchDialog'

export default function MemberAccountWorkspace({
}) {
    const { globalState, globalDispatch } = useContext(AppContext);

    const { member_id } = useParams();
    const navigate = useNavigate();
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const USER_ROLE_LENDER = "agent/lender";

    // --- --- ---

    const paymentTypes = [
        { payment_type: "STRIPE", name: "Stripe Payment" },
        { payment_type: "PAPER_CHECK", name: "Paper Check" },
    ]

    const [isLoadingSubscriptionTypes, setIsLoadingSubscriptionTypes] = useState(true);
    const [subscriptionTypes, setSubscriptionTypes] = useState(null);

    const loadSubscriptionTypes = async () => {
        try {
            const _subscriptionTypes = await UsersApiHelper.users_selectObject("SubscriptionType", { is_active: true }, {});
            if (_subscriptionTypes?.length) {
                const _orderedSubscriptionTypes = _subscriptionTypes
                    .sort((a, b) =>
                        a.subscription_type?.localeCompare(b.subscription_type)
                    )
                setSubscriptionTypes(_subscriptionTypes);
            } else {
                enqueueSnackbar("Could not load Subscription Types. Please check the billing service and try again.", { preventDuplicate: true, variant: 'error' });
            }

            setIsLoadingSubscriptionTypes(false);
        } catch (err) {
            setIsLoadingSubscriptionTypes(false);
            setSubscriptionTypes(null);

            console.warn(err);
            enqueueSnackbar(err?.detail || err?.message || err, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        loadSubscriptionTypes();
    }, [globalState])

    // --- --- ---

    const [isLoadingAgentTokens, setIsLoadingAgentTokens] = useState(true);
    const [agentTokens, setAgentTokens] = useState(null);

    const loadAgentTokens = async () => {
        try {
            const _agentTokens = await AipPassApiHelper.pass_selectObject("AgentTokens", {}, {});
            if (_agentTokens?.length) {
                const _orderedAgentTokens = _(_agentTokens)
                    .uniqBy(agent => agent.last_name + ", " + agent.first_name)
                    .value()
                    .sort((a, b) =>
                        a.last_name?.localeCompare(b.last_name)
                        || a.first_name?.localeCompare(b.first_name)
                    )
                setAgentTokens(_orderedAgentTokens);
            } else {
                enqueueSnackbar("Could not load Agents. Please check the connection and try again.", { preventDuplicate: true, variant: 'error' });
            }

            setIsLoadingAgentTokens(false);
        } catch (err) {
            setIsLoadingAgentTokens(false);
            setAgentTokens(null);

            console.warn(err);
            enqueueSnackbar(err?.detail || err?.message || err, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        loadAgentTokens();
    }, [globalState])

    // --- --- ---

    const [isLoading, setIsLoading] = useState(true);

    const [member, dispatchMember] = useReducer(propUpdateReducer, {
        country_code: "US",
        is_active: true
    });

    const loadMember = async (_memberID) => {
        try {
            const _memberFilter = {
                id: _memberID || member_id
            }
            const members = await UsersApiHelper.users_selectObject("Member", _memberFilter, {});
            if (members?.length) {
                dispatchMember({
                    type: 'updateMany',
                    payload: members[0]
                });
            }

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            dispatchMember({
                type: 'replace',
                payload: {}
            });

            console.warn(err);
            enqueueSnackbar(err?.detail || err?.message || err, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (member_id && member_id != 0) {
            loadMember();
        } else if (member_id == 0 && !member?.id) {
            const _newMemberID = uuidv4();
            dispatchMember({
                type: 'update',
                payload: { key: "id", value: _newMemberID },
            });

            setIsLoading(false);
        }
    }, [globalState, member?.id, member_id])

    // ---

    useEffect(() => {
        if (member?.user_role == USER_ROLE_PRODUCER) {
            if (member_id == 0 && member?.producer_token == null) {
                const _producer_token = uuidv4();
                dispatchMember({
                    type: 'update',
                    payload: { key: "producer_token", value: _producer_token },
                });
            }
        } else if (member?.user_role == USER_ROLE_AGENT || member?.user_role == USER_ROLE_LENDER) {
            // Agent/Lender:
            if (!member?.id) {
                if (member?.id) {
                    dispatchMember({
                        type: 'update',
                        payload: { key: "my_agent_token", value: member?.id },
                    });
                }
            }
        }
    }, [member?.user_role, member?.id, member?.agent_token, member?.producer_token])

    useEffect(() => {
        // Opening existing record:
        if (member?.id && member?.producer_token) {
            dispatchMember({
                type: 'updateMany',
                payload: {
                    user_role: USER_ROLE_PRODUCER
                }
            });
        } else if (member?.id && !member?.producer_token) {
            dispatchMember({
                type: 'updateMany',
                payload: {
                    my_agent_token: member?.id,
                    user_role: USER_ROLE_AGENT
                }
            });
        }
    }, [member?.producer_token, member?.id])

    // ---

    const handleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        dispatchMember({
            type: 'update',
            payload: { key: event.target.name, value: value },
        });
    }

    const handleChangeStateSelect = (event) => {
        dispatchMember({
            type: 'update',
            payload: { key: event.target.name, value: event.target.value },
        });
    }

    const handleChangeStateCheckbox = (event) => {
        dispatchMember({
            type: 'update',
            payload: { key: event?.target?.id, value: event?.target?.checked },
        });
    }

    // --- --- ---

    const [isLoadingUsers, setIsLoadingUsers] = useState(true);
    const [users, setUsers] = useState(null);

    const loadUsers = async () => {
        try {
            const _usersFilter = {
                member_id: member?.id
            }
            const _users = await UsersApiHelper.users_selectObject("User", _usersFilter, {});
            if (_users?.length) {
                setUsers(_users);
            } else {
                setUsers(null);
            }

            setIsLoadingUsers(false);
        } catch (err) {
            setIsLoadingUsers(false);
            setUsers(null);

            console.warn(err);
            enqueueSnackbar(err?.detail || err?.message || err, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (member?.id) {
            loadUsers();
        }
    }, [globalState, member?.id, member_id])

    // ---

    const [userGrants, setUserGrants] = useState([]);
    const loadUserGrants = async () => {
        try {
            const _userGrants = await UsersApiHelper.selectUserGrants({
                user_id: member?.id,
                agent_token: member?.id,
                is_active: true
            }, {});
            setUserGrants(_userGrants);
        } catch (err) {
            setIsLoading(false);
            setUserGrants([]);

            console.warn(err);
            enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
        }
    };

    useEffect(() => {
        if (member?.id && (member?.user_role == USER_ROLE_AGENT || member?.user_role == USER_ROLE_LENDER)) {
            loadUserGrants();
        }
    }, [globalState, member?.user_role, member?.id])

    // --- --- ---

    useEffect(() => {
        if (isValid() && member?.id) {
            const _newState = { ...member };
            if (member?.user_role != USER_ROLE_PRODUCER) {
                _newState.agent_token = member?.my_agent_token;
                _newState.producer_token = null;
            } else {
                _newState.agent_token = null;
            }

            // This is an admin function.
            // Do not pass globalState to updateMember (producer_token).
            UsersApiHelper.updateMember(_newState, {});
        }
    }, [member, globalState])

    // --- --- ---

    const [selectedSubscriptionType, setSelectedSubscriptionType] = useState(null);
    useEffect(() => {
        if (subscriptionTypes?.length && member?.subscription_type) {
            const _foundSubscriptionType = subscriptionTypes?.find(t =>
                t.subscription_type == member?.subscription_type
            );
            if (_foundSubscriptionType) {
                setSelectedSubscriptionType(_foundSubscriptionType);
            } else {
                setSelectedSubscriptionType(null);
            }
        }
    }, [globalState, subscriptionTypes, member?.subscription_type])


    // --- --- ---

    const [isProducerSearchDialogOpen, setIsProducerSearchDialogOpen] = useState(false);

    const handleProducerSearchItemSelect = async (currentProducer) => {
        if (currentProducer?.producer_token) {
            dispatchMember({
                type: 'update',
                payload: { key: "producer_token", value: currentProducer?.producer_token },
            });
        }
    }

    // --- --- ---

    const [isNewUserDialogOpen, setIsNewUserDialogOpen] = useState(false);
    const handleAddNewUserClick = async () => {
        setIsNewUserDialogOpen(true);
    }

    const [isNewMemberDialogOpen, setIsNewMemberDialogOpen] = useState(false);
    const handleAddNewMemberClick = async () => {
        setIsNewMemberDialogOpen(true);
    }

    // --- --- ---

    const formMaxWidthShort = '800px';
    const formMaxWidth = '1000px';

    const isValid = () => {
        return (
            ((member?.last_name && member?.first_name) || member?.business_name)
            && member?.email && member?.phone
            && member?.address1 && member?.city && member?.state_abbreviation && member?.zip
            && ((member?.subscription_type && member?.payment_type) || member?.subscription_is_posted == true)
        );
    }
    useBlockerPrompt(!isValid(), "Member");

    const validationErrors = useMemo(() => {
        const _validationErrors = [];
        if (!isValid() && !isLoading) {
            if (!((member?.last_name && member?.first_name) || member?.business_name)) {
                _validationErrors.push('First/Last name or business name must be supplied');
            }

            if (!isValid()) {
                _validationErrors.push('Please enter all required fields');
            }
        }

        return _validationErrors;
    }, [member, isValid()]);

    useEffect(() => {
        if (isValid()) {
            const _description = member?.business_name ?
                member?.business_name
                : member?.first_name + " " + member?.last_name;
            dispatchMember({
                type: 'update',
                payload: { key: "description", value: _description },
            });
        }
    }, [member?.last_name, member?.first_name, member?.business_name, member?.id, isValid()])

    // ---

    const [tax, setTax] = useState(null);
    const [isLoadingTax, setIsLoadingTax] = useState(false);
    const loadTax = async () => {
        setIsLoadingTax(true);

        const _subscription = {
            ...member,
            subtotal: selectedSubscriptionType?.subtotal,
        };

        UsersApiHelper.selectMemberSubscriptionTaxAmount(_subscription, globalState)
            .then((apiResult) => {
                if (apiResult) {
                    setTax(apiResult);
                } else {
                    setTax(0.00);
                }
                setIsLoadingTax(false);
            })
            .catch(err => {
                setTax(0.00);
                setIsLoadingTax(false);

                console.warn(err);
                enqueueSnackbar(err?.detail || err?.message || err, { preventDuplicate: true, variant: 'error' });
            })
    }

    useEffect(() => {
        if (member?.country_code && member?.address1 && member?.city && member?.state_abbreviation && member?.zip && selectedSubscriptionType) {
            loadTax();
        }
    }, [member?.country_code, member?.address1, member?.city, member?.state_abbreviation, member?.zip, selectedSubscriptionType])

    // ---

    const handleTransactionPostClick = async () => {
        confirmDialogActions.confirm({
            title: "Post transaction?",
            prompt: "Are you sure you want to post this transaction to accounting?",
            promptDetail: null,
            callback: handleTransactionPostClick_Intern
        })
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const handlePaymentLinkClick = async () => {
        const url = await UsersApiHelper.selectMemberPaymentSessionUrl(member, globalState);
        openInNewTab(url);
    }

    const handleTransactionPostClick_Intern = async () => {
        if (isValid() && member?.id) {
            const _newState = { ...member };
            _newState.subscription_is_posted = true;

            if (member?.user_role != USER_ROLE_PRODUCER) {
                _newState.agent_token = member?.my_agent_token;
            }

            try {
                // This is an admin function.
                // Do not pass globalState to updateMember (producer_token).
                await UsersApiHelper.updateMemberSubscription(_newState, {});
                loadMember(_newState?.id);
                loadUserGrants();
            } catch (err) {
                const errorMessage = err?.detail || err?.message || err;
                if (errorMessage?.includes("UQ_")) {
                    console.warn(err);
                    enqueueSnackbar("The member name already has a subscription.  Member names must be unique.  Please find the other account or choose a new name.", { preventDuplicate: true, variant: 'error' });
                } else {
                    console.warn(err);
                    enqueueSnackbar(err?.detail || err?.message || err, { preventDuplicate: true, variant: 'error' });
                }
            }
        }
    }

    const [isLoadingPayments, setIsLoadingPayments] = useState(true);
    const [payments, setPayments] = useState(null);
    const loadPayments = async () => {
        try {
            if (member?.subscription_is_posted) {
                const payments = await UsersApiHelper.selectMemberPayments(member, globalState);
                if (payments?.length) {
                    setPayments(payments);
                    setIsLoadingPayments(false);
                } else {
                    setPayments(null);
                    setIsLoadingPayments(false);
                }
            }
        } catch (err) {
            setIsLoadingPayments(false);
            setPayments(null);

            console.warn(err);
            enqueueSnackbar(err?.detail || err?.message || err, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        loadPayments();
    }, [member?.id, member?.subscription_is_posted])

    // --- --- ---

    return (
        <>
            <WorkspaceHeader
                title={member_id != 0 ? 'Manage Member' : 'New Member'}
                breadCrumbs={[
                    {
                        title: 'Members',
                        path: '/account',
                        icon: <MemberManagementIcon />
                    },
                ]}
            />

            <List>
                {validationErrors.map((err, index) =>
                    <ListItem key={index}>
                        <ListItemAvatar>
                            <ErrorOutline color='error' />
                        </ListItemAvatar>
                        <ListItemText>
                            <Typography color="error">
                                {err}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                )}
            </List>

            <Box sx={{ width: '100%', maxWidth: formMaxWidthShort }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant='h5'>
                            Name
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="First Name"
                                name="first_name"
                                isRequired={true}
                                defaultValue={member?.first_name || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                isLoading={isLoading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Last Name"
                                name="last_name"
                                isRequired={true}
                                defaultValue={member?.last_name || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                isLoading={isLoading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Business Name"
                                name="business_name"
                                isRequired={false}
                                defaultValue={member?.business_name || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                isLoading={isLoading}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Box sx={{ width: '100%', maxWidth: formMaxWidthShort }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant='h5'>
                            Contact
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Email"
                                name="email"
                                isRequired={true}
                                defaultValue={member?.email || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                isLoading={isLoading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Phone"
                                name="phone"
                                isRequired={true}
                                defaultValue={member?.phone || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                isLoading={isLoading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Address"
                                name="address1"
                                isRequired={true}
                                defaultValue={member?.address1 || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                isLoading={isLoading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Address 2"
                                name="address2"
                                isRequired={false}
                                defaultValue={member?.address2 || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                isLoading={isLoading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="City"
                                name="city"
                                isRequired={true}
                                defaultValue={member?.city || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                isLoading={isLoading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DataFormSelect
                            value={member?.state_abbreviation}
                            name="state_abbreviation"
                            onChange={handleChangeState}
                            objectName="State"
                            itemFilter={{ reinsurance_year: getCurrentReinsuranceYear() - 1 }}
                            itemGetKey={item => item.state_abbreviation}
                            itemGetLabel={item => item.state_abbreviation}
                            doFetch={true}
                            isLoading={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Zip"
                                name="zip"
                                isRequired={true}
                                defaultValue={member?.zip || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                isLoading={isLoading}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Box sx={{ width: '100%', maxWidth: formMaxWidthShort }}>
                <Grid item xs={12} lg={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Stack direction="column" spacing={0}>
                                <Typography variant='h5'>
                                    Account
                                </Typography>
                                {member?.subscription_is_posted &&
                                    <Alert severity="info">
                                        <Typography variant='body1'>Subscription is submitted. Further changes are not allowed.</Typography>
                                    </Alert>}
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <FormLabel id="account-type-label">Account Type</FormLabel>
                                <SkeletonWrapper isLoading={isLoadingAgentTokens}>
                                    <RadioGroup row
                                        defaultValue=""
                                        aria-labelledby="account-type-label"
                                        name="user_role"
                                        value={(() => {
                                            if (member?.user_role) {
                                                return member?.user_role;
                                            } else {
                                                if (member?.agent_token) {
                                                    return USER_ROLE_AGENT;
                                                } else {
                                                    return USER_ROLE_PRODUCER;
                                                }
                                            }
                                        })()}
                                        onChange={handleChangeState}
                                    >
                                        <FormControlLabel value={USER_ROLE_PRODUCER} control={<Radio />} label="Producer"
                                            disabled={member?.subscription_is_posted == true} />
                                        <FormControlLabel value={USER_ROLE_AGENT} control={<Radio />} label="Agent"
                                            disabled={member?.subscription_is_posted == true} />
                                        <FormControlLabel value={USER_ROLE_LENDER} control={<Radio />} label="Lender"
                                            disabled={member?.subscription_is_posted == true} />
                                    </RadioGroup>
                                </SkeletonWrapper>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            {/* 
                            <AutoCompleteTextField
                                    label="Agent"
                                    name="agent_token"
                                    value={member.agent_token || ''}
                                    onChange={handleChangeState}
                                    values={agentTokens || []}
                                    itemGetValue={item => item?.agent_token}
                                    itemGetLabel={item => item?.last_name + ", " + item?.first_name}
                                    isRequired={false}
                                    disabled={false}
                                    freeSolo={false}
                                    maskType={null}
                                    doShowLabel={true}
                                />
                         */}
                            {(member?.user_role == USER_ROLE_PRODUCER) &&
                                <FormControl fullWidth>
                                    <InputLabel id='agent_tokenLabel'>Agent</InputLabel>
                                    <SkeletonWrapper isLoading={isLoadingAgentTokens}>
                                        <Select
                                            labelId='agent_tokenLabel'
                                            displayEmpty
                                            value={(member?.user_role == USER_ROLE_PRODUCER && member?.agent_token) || ''}
                                            name={"agent_token"}
                                            onChange={handleChangeStateSelect}
                                            label={'Agent'}
                                            disabled={member?.user_role != USER_ROLE_PRODUCER || member?.subscription_is_posted == true}
                                        >
                                            {agentTokens?.map((currentItem, index) => {
                                                return <MenuItem key={index} value={currentItem?.agent_token}>{currentItem?.last_name}, {currentItem?.first_name}</MenuItem>
                                            })}
                                        </Select>
                                    </SkeletonWrapper>
                                </FormControl>}

                            {member?.user_role != USER_ROLE_PRODUCER &&
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth={true}>
                                        <FormControlLabel control={<Checkbox id="do_create_demo_account" onChange={handleChangeStateCheckbox} />} label="Automatically create demo account?" />
                                    </FormControl>
                                </Grid>}
                        </Grid>

                        {member?.user_role == USER_ROLE_PRODUCER &&
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth={true}>
                                    <StatefulControlledTextField
                                        label="DATANAC Producer Token"
                                        name="producer_token"
                                        isRequired={false}
                                        defaultValue={member?.producer_token || ''}
                                        variant="outlined"
                                        onChange={handleChangeState}
                                        InputLabelProps={{ shrink: true }}
                                        isLoading={isLoading}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton edge="end" color="primary" onClick={() => setIsProducerSearchDialogOpen(true)}>
                                                    <Tooltip title='Search for producer'>
                                                        <SearchOutlined />
                                                    </Tooltip>
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>}

                        {member?.user_role != USER_ROLE_PRODUCER &&
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth={true}>
                                    <StatefulControlledTextField
                                        label="DATANAC Agent Token"
                                        name="my_agent_token"
                                        isRequired={false}
                                        defaultValue={member?.my_agent_token || ''}
                                        variant="outlined"
                                        onChange={handleChangeState}
                                        InputLabelProps={{ shrink: true }}
                                        isLoading={isLoading}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton edge="end" color="primary" onClick={() => setIsProducerSearchDialogOpen(true)}>
                                                    <Tooltip title='Search for agent'>
                                                        <SearchOutlined />
                                                    </Tooltip>
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>}
                    </Grid>
                </Grid>
            </Box>

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Box sx={{ width: '100%', maxWidth: formMaxWidthShort }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={12}>
                        <Typography variant='h5'>
                            Billing
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id='sales_representative_tokenLabel'>Sales Representative</InputLabel>
                            <SkeletonWrapper isLoading={isLoadingAgentTokens}>
                                <Select
                                    labelId='sales_representative_tokenLabel'
                                    displayEmpty
                                    value={member?.sales_representative || ''}
                                    name={"sales_representative"}
                                    onChange={handleChangeStateSelect}
                                    label={'Sales Representative'}
                                    disabled={member?.subscription_is_posted || false}
                                >
                                    {agentTokens?.map((currentItem, index) => {
                                        return <MenuItem key={index} value={currentItem?.agent_token}>{currentItem?.last_name}, {currentItem?.first_name}</MenuItem>
                                    })}
                                </Select>
                            </SkeletonWrapper>
                        </FormControl>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id='subscription_typeLabel'>Subscription Type</InputLabel>
                            <SkeletonWrapper isLoading={isLoadingSubscriptionTypes}>
                                <Select
                                    labelId='subscription_typeLabel'
                                    displayEmpty
                                    value={member?.subscription_type || ''}
                                    name={"subscription_type"}
                                    onChange={handleChangeStateSelect}
                                    label={'Subscription Type'}
                                    disabled={member?.subscription_is_posted || false}
                                >
                                    {subscriptionTypes?.map((currentItem, index) => {
                                        return <MenuItem key={index} value={currentItem?.subscription_type}>{currentItem?.name}</MenuItem>
                                    })}
                                </Select>
                            </SkeletonWrapper>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id='payment_typeLabel'>Payment Type</InputLabel>
                            <SkeletonWrapper isLoading={isLoading}>
                                <Select
                                    labelId='payment_typeLabel'
                                    value={member?.payment_type}
                                    name={"payment_type"}
                                    onChange={handleChangeStateSelect}
                                    label={'Payment Type'}
                                    disabled={member?.subscription_is_posted || false}
                                >
                                    {paymentTypes?.map((currentItem, index) => {
                                        return <MenuItem key={index} value={currentItem?.payment_type}>{currentItem?.name}</MenuItem>
                                    })}
                                </Select>
                            </SkeletonWrapper>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container>
                            <Grid item xs={6}>Subtotal</Grid>
                            <Grid item xs={6}>{formatPrice(selectedSubscriptionType?.subtotal, 2)}</Grid>

                            <Grid item xs={6} sx={{ borderBottom: "1px solid black" }}>Tax</Grid>
                            <Grid item xs={6} sx={{ borderBottom: "1px solid black" }}>
                                <SkeletonWrapper isLoading={isLoadingTax}>
                                    <Typography>
                                        {formatPrice(tax, 2)}
                                    </Typography>
                                </SkeletonWrapper>
                            </Grid>

                            <Grid item xs={6}>Total</Grid>
                            <Grid item xs={6}>
                                <SkeletonWrapper isLoading={isLoadingTax}>
                                    <Typography>
                                        {formatPrice(selectedSubscriptionType?.subtotal + tax, 2)}
                                    </Typography>
                                </SkeletonWrapper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} display="flex" sx={{ width: "100%", justifyContent: "flex-end" }}>
                            <Button variant="outlined"
                                disabled={isLoadingTax || !selectedSubscriptionType || member?.subscription_is_posted}
                                onClick={handleTransactionPostClick}
                            >
                                <CurrencyExchangeOutlined sx={{ mr: 1 }} />
                                Submit Transaction
                            </Button>

                            <Button variant="outlined"
                                disabled={!member?.subscription_is_posted || isLoadingPayments || Boolean(payments?.length)}
                                onClick={handlePaymentLinkClick}
                            >
                                Payment Link <EastOutlined sx={{ ml: 1 }} />
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Box sx={{ width: '100%', maxWidth: formMaxWidthShort }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                            <Typography variant='h5'>
                                Users
                            </Typography>

                            <Box>
                                <Button
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    onClick={handleAddNewUserClick}
                                    disabled={!isValid() || isLoadingUsers}
                                >
                                    <AddCircleOutline sx={{ mr: 1 }} />
                                    New {!isSmall ? ' User' : ''}
                                </Button>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <SkeletonWrapper isLoading={isLoadingUsers}>
                            <MemberUserDataTable users={users} />
                        </SkeletonWrapper>
                    </Grid>
                </Grid>
            </Box>
            <Divider sx={{ mt: 2, mb: 2 }} />

            <Box sx={{ width: '100%', maxWidth: formMaxWidthShort }}>
                <Grid container spacing={2}>
                    {member?.user_role == USER_ROLE_AGENT && <>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                                <Typography variant='h5'>
                                    Members
                                </Typography>

                                <Box>
                                    <Button
                                        variant="outlined"
                                        sx={{ width: '100%' }}
                                        onClick={handleAddNewMemberClick}
                                        disabled={!isValid() || isLoadingUsers}
                                    >
                                        <AddCircleOutline sx={{ mr: 1 }} />
                                        New {!isSmall ? ' Member' : ''}
                                    </Button>
                                </Box>
                            </Stack>
                            <AgentUserGrantDataTable userGrants={userGrants} />
                        </Grid>
                    </>}
                </Grid>
            </Box>

            <Dialog open={isNewUserDialogOpen}>
                <NewUserDialog member={member} onClose={() => { setIsNewUserDialogOpen(false); loadUsers(); }} />
            </Dialog>

            <Dialog open={isNewMemberDialogOpen}>
                <NewMemberDialog member={member} onClose={() => { setIsNewMemberDialogOpen(false); loadUserGrants(); }} />
            </Dialog>

            <Dialog open={isProducerSearchDialogOpen} PaperProps={{ sx: { minWidth: "50%" } }}>
                <ProducerSearchDialog onClose={() => { setIsProducerSearchDialogOpen(false) }}
                    onItemSelect={(currentProducer) => { handleProducerSearchItemSelect(currentProducer); setIsProducerSearchDialogOpen(false); }} />
            </Dialog>

            {
                globalState?.user_role == USER_ROLE_AGENT &&
                <WorkspaceActionBar content={<></>} />
            }

            {confirmDialogComponent}
        </>
    )
}
