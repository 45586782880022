import { useDialog } from '@datanac/datanac-mui-toolkit'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, Button, Chip, CircularProgress, Fade, Link, useMediaQuery } from '@mui/material'
import {
    Aggregate,
    AggregateColumnDirective,
    AggregateColumnsDirective,
    AggregateDirective,
    AggregatesDirective,
    ColumnDirective,
    ColumnsDirective,
    ExcelExport,
    Filter,
    GridComponent,
    Group,
    Inject,
    Page,
    PdfExport,
    Sort,
    Toolbar,
} from '@syncfusion/ej2-react-grids'
import { AppContext } from 'App'
import EmptyCard from 'components/Cards/EmptyCard'
import { propUpdateReducer } from 'components/Core/propUpdateReducer'
import { FarmVaultIcon } from 'components/Icons/Icons'
import { getUniqueProducerFarmCrops } from 'components/Vault/Farm/helpers'
import { useFarmController } from 'components/Vault/Farm/useFarmController'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { formatNumber } from 'utility/formatters'

export default function FarmsReport() {
    const { globalState } = useContext(AppContext);
    const {
        crop_year,
    } = useParams();
    const navigate = useNavigate();

    const location = useLocation();

    // ---

    const [filterLocationStateName, setFilterLocationStateName] = useState(null);
    const [filterLocationCountyName, setFilterLocationCountyName] = useState(null);

    //useEffect for location?.search - querystring changes
    useEffect(() => {
        const searchParams = new URLSearchParams(location?.search);
        const location_state_name = searchParams.get('location_state_name');
        if (location_state_name?.length) {
            setFilterLocationStateName(location_state_name);
        } else {
            setFilterLocationStateName(null);
        }
    }, [location?.search]);
    useEffect(() => {
        const searchParams = new URLSearchParams(location?.search);
        const location_county_name = searchParams.get('location_county_name');
        if (location_county_name?.length) {
            setFilterLocationCountyName(location_county_name);
        } else {
            setFilterLocationCountyName(null);
        }
    }, [location?.search]);

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || err, { preventDuplicate: true, variant: 'error' });
    }

    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const [currentYear, setCurrentYear] = useState(crop_year);
    const [filter, dispatchFilter] = useReducer(propUpdateReducer, {
        commodity_name: [],
        practice_name: [],
    });

    const {
        counties,
        // countyPractices,
        // producerFarms,
        // producerFarmFieldCrops,
        // producerFarm_union_Crops,
        // isLoading,
        isLoadingCounties,
    } = useFarmController({
        crop_year: currentYear,
        filter: filter,
        onError: handleError
    });

    const [allProducerFarmCrops, setAllProducerFarmCrops] = useState([]);

    useEffect(() => {
        // Reducer counties[ii].ProducerFarmCrops
        var producerFarmCrops = [];
        counties
            ?.filter((county) => {
                const isCountyMatch = filterLocationCountyName == null || county?.location_county_name == filterLocationCountyName;
                const isStateMatch = filterLocationStateName == null || county?.location_state_name == filterLocationStateName;
                return isCountyMatch && isStateMatch;
            })
            ?.forEach((county) => {
                county?.ProducerFarms?.forEach((producerFarm) => {
                    producerFarm?.ProducerFarmFieldCrops?.forEach((producerFarmFieldCrop) => {
                        producerFarmCrops.push({
                            ...producerFarmFieldCrop,
                            CustomerName: producerFarm?.customer_name,
                            OrderDate: producerFarm?.order_date,
                            Freight: producerFarm?.freight,
                            ShippedDate: producerFarm?.shipped_date,
                        });
                    });
                });
            });

        const _uniqueProducerFarmCrops = getUniqueProducerFarmCrops(producerFarmCrops);
        setAllProducerFarmCrops(_uniqueProducerFarmCrops)
        // setAllProducerFarmCrops(producerFarmCrops);
    }, [counties, filterLocationStateName, filterLocationCountyName]);

    // ---


    const filterHandleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        dispatchFilter({
            type: 'update',
            payload: { key: event.target.name, value: value },
        });
    }

    // ---

    const handleFarmNavigateClick = (_currentProducerFarm) => {
        navigate(`/vault/${crop_year}/${_currentProducerFarm?.location_state_name}/${_currentProducerFarm?.location_county_name}/${_currentProducerFarm?.id}`)
    }

    // ---

    const [isDetailViewOn, setIsDetailViewOn] = useState(false);

    // ---

    const captionTemplate = (props) => {
        let groupLabel = props?.field;
        let groupValue = "";
        let groupCount = 0;
        if (props?.items.records?.length && props?.items.records[0] && props?.items.records[0][props?.field]) {
            groupValue = props?.items.records[0][props?.field]
        } else if (props?.items?.length && props.items[0]) {
            groupValue = props?.items[0][props?.field];
        }

        return (
            <span className="groupItems">
                <Link href={`/reports/vault/FarmsReport/${crop_year}?${groupLabel}=${groupValue}`}>
                    <strong>{groupValue}</strong> ({groupCount})
                </Link>
            </span>
        );
    }

    // Template for group footer aggregate
    const groupFooterSumTemplate = (props) => {
        return <span>Sum: <strong>{formatNumber(props.Sum, 0)}</strong></span>;
    };

    // Template for group footer aggregate
    const groupFooterAverageTemplate = (props) => {
        return <span>Avg: <strong>{formatNumber(props.Average, 0)}</strong></span>;
    };

    // Template for group caption aggregate
    const groupCaptionSumTemplate = (props) => {
        return <span>Sum: {formatNumber(props.Sum, 0)}</span>;
    };

    const handleGridToolbarClick = async (args) => {
        if (_reportGrid && args?.item?.id?.toLowerCase().includes("excel")) {
            _reportGrid.excelExport();
        } else if (_reportGrid && args?.item?.id?.toLowerCase().includes("pdf")) {
            _reportGrid.pdfExport();
        }
    }

    const pageOptionsSummary = {
        pageSize: 1000,
        pageSizes: false
    };
    const pageOptionsDetail = {
        pageSize: 10,
        pageSizes: true
    };

    return (
        <>
            <WorkspaceHeader
                title={"Planted Acres by Farm"}
                breadCrumbs={[
                    {
                        title: 'Farm Vault ' + crop_year,
                        path: '/vault',
                        icon: <FarmVaultIcon fontSize='3pt' />
                    },
                ]}
            />

            {!isLoadingCounties && !Boolean(counties?.length) && <>
                <EmptyCard text="No search results" secondaryText="Remove some filters or add a new farm in the Farm Vault" />
            </>}

            <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2, gap: 2 }}>
                <Button onClick={() => setIsDetailViewOn(!isDetailViewOn)} variant='text'
                    endIcon={isDetailViewOn ? <ExpandLess /> : <ExpandMore />}>
                    Detail
                </Button>

                {filterLocationStateName && <Chip label={filterLocationStateName} onDelete={() => setFilterLocationCountyName(null)} />}
                {filterLocationCountyName && <Chip label={filterLocationCountyName} onDelete={() => setFilterLocationCountyName(null)} />}
            </Box>

            {isLoadingCounties && <CircularProgress />}

            {!isLoadingCounties && Number(counties?.length) && <GridComponent
                cssClass={'tilley ' + (isDetailViewOn ? 'tilley-grid-detail' : 'tilley-grid-summary')}
                visible={!isLoadingCounties && Number(counties?.length)}
                style={{ color: "#8CC63F" }}
                dataSource={allProducerFarmCrops}
                allowSorting={true}
                allowFiltering={isDetailViewOn}
                allowGrouping={true}
                allowPaging={true}
                pageSettings={isDetailViewOn ? pageOptionsDetail : pageOptionsSummary}
                toolbar={["ExcelExport", "PdfExport"]}
                toolbarClick={handleGridToolbarClick}
                allowPdfExport={true}
                allowExcelExport={true}
                groupSettings={{
                    showDropArea: isDetailViewOn,
                    columns: isDetailViewOn ? ['location_state_name', 'location_county_name'] : [],
                    // Header:
                    captionTemplate: captionTemplate,
                }}
            >
                <ColumnsDirective>
                    <ColumnDirective field='location_county_name' headerText='County' width='100' textAlign='Left' />
                    <ColumnDirective field='location_state_name' headerText='State' width='100' textAlign='Left' />

                    <ColumnDirective field='fsa_farm_number' headerText='Farm Number' width='100' textAlign='Right' />
                    <ColumnDirective field='unit_count' headerText='# Fields' width='100' textAlign='Right' visible={isDetailViewOn} />

                    <ColumnDirective field='crop_year' headerText='Year' width='150' textAlign='Right' visible={isDetailViewOn} />

                    <ColumnDirective field='reported_acreage' headerText='Reported Acreage' width='150' textAlign='Right' />
                    <ColumnDirective field='share_percent' headerText='Share Percent' width='150' textAlign='Right' visible={isDetailViewOn} format="P0" />

                    <ColumnDirective field='commodity_name' headerText='Commodity' width='150' />
                    <ColumnDirective field='type_name' headerText='Type' width='150' visible={isDetailViewOn} />
                    <ColumnDirective field='practice_name' headerText='Practice' width='150' />

                    <ColumnDirective field='approved_yield_weighted' headerText='Approved Yield' width='150' textAlign='Right' />
                </ColumnsDirective>
                <AggregatesDirective>
                    <AggregateDirective>
                        <AggregateColumnsDirective>
                            <AggregateColumnDirective
                                field="approved_yield"
                                type="Average"
                                groupFooterTemplate={isDetailViewOn ? groupFooterAverageTemplate : "<span/>"}
                            />
                        </AggregateColumnsDirective>
                    </AggregateDirective>
                </AggregatesDirective>
                <Inject services={[Sort, Filter, Group, Aggregate, Page, Toolbar, ExcelExport, PdfExport]} />
            </GridComponent>}

            {confirmDialogComponent}
        </>
    )
}
