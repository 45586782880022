import SettingsInputSvideoOutlined from '@mui/icons-material/SettingsInputSvideoOutlined';
import { Auth } from 'aws-amplify'
import { jwtDecode } from 'jwt-decode';

// ---

export const GROUP_TILLEY_LENDERS = 'Tilley-Lenders';

export function AmplifySessionManager() {
    var _isAuthenticated = false;

    const getBearerToken = async () => {
        //console.log("auth getBearerToken");

        const session = await Auth.currentSession();
        const token = session.getIdToken();
        const jwt = token.getJwtToken();

        // console.log(`token: ${JSON.stringify(token)}`);
        // console.log(`jwt: ${jwt}`);

        return jwt;
    }

    const getUserGroups = async () => {
        try {
            const token = await getBearerToken();
            const decodedToken = jwtDecode(token);
            const groups = decodedToken['cognito:groups'] || [];
            return groups;
        } catch (error) {
            console.error('Error getting user groups:', error);
        }
    };
    
    const setBearerToken = async (value) => {
        // NOOP
    }

    // ---

    const getRefreshToken = async () => {
        // https://github.com/aws-amplify/amplify-js/issues/2560
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();

        let refreshToken = null;
        await cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
            console.warn('refreshSession:', err, session);
            const { refreshToken } = session;

            return refreshToken;
        });

        return currentSession?.refreshToken?.token;
    }

    const setRefreshToken = async (value) => {
        // noop
    }

    // ---

    const setIsAuthenticated = (__isAuthenticated) => {
        //console.log("auth SessionManager setIsAuthenticated", __isAuthenticated);
        _isAuthenticated = __isAuthenticated;
    }

    const getIsAuthenticated = () => {
        //console.log("auth SessionManager getIsAuthenticated", _isAuthenticated);
        return _isAuthenticated;
    }

    return {
        setBearerToken,
        getBearerToken,
        setRefreshToken,
        getRefreshToken,

        getUserGroups,

        setIsAuthenticated,
        getIsAuthenticated
    }
}
