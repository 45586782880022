import { CloseOutlined, SaveOutlined } from "@mui/icons-material"
import { Button, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Stack, useMediaQuery } from "@mui/material"
import { AppContext } from "App";
import { UsersApiHelper } from "api/ApiHelper";
import { StatefulControlledTextField } from "components/Core/StatefulControlledTextField";
import { propUpdateReducer } from "components/Core/propUpdateReducer";
import { useSnackbar } from "notistack";
import { useContext, useReducer, useState } from "react";

export const NewUserDialog = ({
    member,

    onClose
}) => {

    const { globalState, globalDispatch } = useContext(AppContext);

    const { enqueueSnackbar } = useSnackbar();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

    // --- --- ---

    const isValid = () => {
        return Boolean(
            user?.email
            && user?.description
        );
    }

    // --- --- ---

    const [isLoading, setIsLoading] = useState(false);

    const [user, dispatchUser] = useReducer(propUpdateReducer, member);

    const handleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        dispatchUser({
            type: 'update',
            payload: { key: event.target.name, value: value },
        });
    }

    const handleChangeStateSelect = (event) => {
        dispatchUser({
            type: 'update',
            payload: { key: event.target.name, value: event.target.value },
        });
    }

    // --- --- ---

    const handleSaveClick = async () => {
        try {
            const _newUser = {
                ...user,
                member_id: user?.id,
                is_active: true,
            }
            await UsersApiHelper.createUser(_newUser, {});
            onClose && onClose();
        } catch (err) {
            enqueueSnackbar(err.detail || err.message || err, { preventDuplicate: true, variant: 'error' });
        }
    }

    return (<>
        <DialogTitle>
            Add User
        </DialogTitle>

        <DialogContent>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth={true}>
                        <StatefulControlledTextField
                            label="Description"
                            name="description"
                            isRequired={true}
                            defaultValue={member.description || ''}
                            variant="outlined"
                            onChange={handleChangeState}
                            InputLabelProps={{ shrink: true }}
                            isLoading={isLoading}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControl fullWidth={true}>
                        <StatefulControlledTextField
                            label="Email"
                            name="email"
                            isRequired={true}
                            defaultValue={member.email || ''}
                            variant="outlined"
                            onChange={handleChangeState}
                            InputLabelProps={{ shrink: true }}
                            isLoading={isLoading}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </DialogContent>

        <DialogActions>
            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                <Button variant='contained' color='secondary' autoFocus onClick={onClose}>
                    <CloseOutlined sx={{ mr: 1 }} />
                    Cancel
                </Button>
                <Button variant='contained' color='primary' onClick={handleSaveClick} disabled={!isValid()}>
                    <SaveOutlined sx={{ mr: 1 }} />
                    Save
                </Button>
            </Stack>
        </DialogActions>
    </>)
}