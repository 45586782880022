import { useDialog } from '@datanac/datanac-mui-toolkit'
import { useCardController } from '@datanac/datanac-mui-toolkit/dist/hooks'
import CloseOutlined from '@mui/icons-material/CloseOutlined'
import CloudDownloadOutlined from '@mui/icons-material/CloudDownloadOutlined'
import EastOutlined from '@mui/icons-material/EastOutlined'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Paper, Select, Stack, Typography, useMediaQuery } from '@mui/material'
import { green } from '@mui/material/colors'
import { AppContext } from 'App'
import { AipPassApiHelper, UsersApiHelper } from 'api/ApiHelper'
import { getHistoryYears } from 'components/Budget/helpers'
import EmptyCard from 'components/Cards/EmptyCard'
import { propUpdateReducer } from 'components/Core/propUpdateReducer'
import { CloudVaultIcon, FarmVaultIcon } from 'components/Icons/Icons'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { getUniqueCounties } from '../../helpers'
import FarmCountyCard from '../FarmCountyCard'

export default function ProducerFarmCopyAipPassDialog({
    onClose
}) {
    const { globalState, globalDispatch } = useContext(AppContext);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const [isLoadingHistory, setIsLoadingHistory] = useState(false);
    const { state: history, actions: historyActions } = useCardController();

    // ---

    const [state, dispatch] = useReducer(propUpdateReducer, {
        crop_year: globalState?.crop_year,
    });
    const handleChangeStateSelect = (event) => {
        dispatch({
            type: 'update',
            payload: { key: event.target.name, value: event.target.value },
        });
    }
    const handleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        dispatch({
            type: 'update',
            payload: { key: event.target.name, value: value },
        });
    }

    const isValid = useMemo(() => {
        const isValid = (
            state?.crop_year
            && history?.length > 0
        );

        return isValid;
    }, [state, history]);

    const validationErrors = useMemo(() => {
        const _validationErrors = [];
        if (!isValid) {
            if (!state?.crop_year) {
                _validationErrors.push('Please enter all required fields');
            }
        }

        return _validationErrors;
    }, [state, isValid]);

    const [isLoadingAction, setIsLoadingAction] = useState(false);
    const handleActionClick = () => {
        if (isValid) {
            setIsLoadingAction(true);

            const _parms = {
                producer_token: globalState?.producer_token,
                crop_year: state?.crop_year
            }
            UsersApiHelper.updateProducerFarmCopyAipPass(_parms)
                .then(() => {
                    setIsLoadingAction(false);
                    onClose && onClose(state?.crop_year);
                })
                .catch(err => {
                    setIsLoadingAction(false);
                    console.warn(err);
                });
        }
    }

    // ---

    const loadHistory = async () => {
        try {
            const producerFilter = {
                producer_token: globalState.producer_token,
                reinsurance_year: state?.crop_year,
            };
            const farms = await AipPassApiHelper.selectProducerFarmCrop(producerFilter, globalState);
            const countyPractices = getUniqueCounties(farms);
            historyActions.replace(countyPractices);

            setIsLoadingHistory(false);
        } catch (err) {
            setIsLoadingHistory(false);
            historyActions.replace([]);
            console.warn(err);
            enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (state.crop_year && globalState.producer_token) {
            setIsLoadingHistory(true);
            loadHistory();
        } else {
            historyActions.replace([]);
        }
    }, [state.crop_year, globalState.producer_token])

    // ---

    return (
        <>
            <DialogTitle>
                Import from History
            </DialogTitle>
            <DialogContent>
                <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center" }}>
                    <Box sx={{ minWidth: "35%" }}>
                        <FormControl fullWidth>
                            <InputLabel id='crop_year'>
                                {isSmall ? "From" : "Import From"}
                            </InputLabel>
                            <Select
                                labelId='crop_year'
                                value={state?.crop_year || ''}
                                name={"crop_year"}
                                onChange={handleChangeStateSelect}
                                required={true}
                                label={isSmall ? 'From' : 'Import from'}
                            >
                                {getHistoryYears()?.map((currentItem, index) => {
                                    return <MenuItem key={index} value={currentItem}>{currentItem}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Box>

                    <EastOutlined />

                    <Box sx={{ minWidth: "35%", pt: "16px" }}>
                        <Paper sx={{
                            display: "flex",
                            p: 2, height: "50px",
                            backgroundColor: "#f8f8f8", border: "1px solid #c0c0c0", borderRadius: "7px",
                            justifyContent: "center", alignContent: "center",
                            alignItems: "center",
                        }}>
                            <FarmVaultIcon sx={{ mr: 1 }} />
                            <Typography display="inline-block">Farm Potential</Typography>
                        </Paper>
                    </Box>
                </Stack>

                <List>
                    {validationErrors.map((err, index) =>
                        <ListItem key={index}>
                            <ListItemAvatar>
                                <ErrorOutline color='error' />
                            </ListItemAvatar>
                            <ListItemText>
                                <Typography color="error">
                                    {err}
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    )}
                </List>

                {!Boolean(history?.length) &&
                    <EmptyCard text="No history found" secondaryText="Please choose another year" isLoading={isLoadingHistory} />}

                <Grid container spacing={1}>
                    {history.map((currentCountyPractice, index) =>
                        <Grid key={index} item xs={12} md={6}>
                            <FarmCountyCard currentCounty={currentCountyPractice} icon={<CloudVaultIcon fontSize="large" />} isNavigation={false} />
                        </Grid>
                    )}
                </Grid>

            </DialogContent>

            <DialogActions>
                <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                    <Button variant='contained' color='secondary' autoFocus onClick={() => onClose(null)}>
                        <CloseOutlined sx={{ mr: 1 }} />
                        Cancel
                    </Button>

                    <Box sx={{ position: "relative" }}>
                        <Button variant='contained' color='primary' onClick={handleActionClick}
                            disabled={!isValid || isLoadingAction}
                        >
                            <CloudDownloadOutlined sx={{ mr: 1 }} />
                            Import
                        </Button>
                        {isLoadingAction && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Stack>
            </DialogActions>

            {confirmDialogComponent}
        </>
    )
}
