import { useCardController } from '@datanac/datanac-mui-toolkit/dist/hooks'
import { Box, Grid, useMediaQuery } from '@mui/material'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { AppContext } from 'App'
import EmptyCard from 'components/Cards/EmptyCard'
import { SecurityIcon } from 'components/Icons/Icons'
import UserGrantCard from 'components/UserGrants/components/UserGrantCard'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AipPassApiHelper, UsersApiHelper } from '../../api/ApiHelper'
import { useSnackbar } from 'notistack'

export default function UserGrantWorkspace({
}) {
    const { globalState, globalDispatch } = useContext(AppContext);

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { producer_token } = useParams();

    const [producer, setProducer] = useState([]);
    const selectProducer = async () => {
        try {
            const _producer = await AipPassApiHelper.pass_selectObject("ProducerTokens", { producer_token: producer_token }, globalState);
            if (_producer?.length) {
                setProducer(_producer[0]);
            }
        } catch (err) {
            setIsLoading(false);
            setProducer(null);

            console.warn(err);
            enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
        }
    };

    const { state: userGrants, actions: userGrantActions } = useCardController();
    const selectUserGrants = async () => {
        try {
            const _userGrants = await UsersApiHelper.selectUserGrants({ producer_token: producer_token }, globalState);
            userGrantActions.replace(_userGrants);
        } catch (err) {
            setIsLoading(false);
            userGrantActions.replace([]);

            console.warn(err);
            enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
        }
    };

    const { state: userGrantItems, actions: userGrantItemActions } = useCardController();
    const selectUserGrantItems = async () => {
        try {
            const _userGrantItems = await UsersApiHelper.selectUserGrantItems({ producer_token: producer_token }, globalState);
            userGrantItemActions.replace(_userGrantItems);
        } catch (err) {
            setIsLoading(false);
            userGrantItemActions.replace([]);

            console.warn(err);
            enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
        }
    };

    const [isLoading, setIsLoading] = useState(false);
    const loadData = async () => {
        setIsLoading(true);
        const producerPromise = selectProducer();
        const userGrantPromise = selectUserGrants();
        const userGrantItemsPromise = selectUserGrantItems();

        await Promise.all([producerPromise, userGrantPromise, userGrantItemsPromise])
            .then(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        loadData();
    }, [globalState.user_role, globalState.agent_token, globalState.producer_token]);

    return (
        <>
            <WorkspaceHeader
                title={
                    <div style={{ textTransform: 'capitalize' }}>
                        {producer?.first_name?.toLowerCase()}
                        <> </>
                        {producer?.middle_name?.toLowerCase()}
                        <> </>
                        {producer?.last_name?.toLowerCase()}
                        <> </>
                        {producer?.business_name?.toLowerCase()}
                    </div>
                }
                breadCrumbs={[
                    {
                        title: 'Security',
                        path: '/userGrant',
                        icon: <SecurityIcon />
                    },]}
            />

            {Boolean(userGrants
                .filter(ug => ug.is_active == null)?.length) && <>
                    <Typography variant='h1'>
                        Pending Requests
                    </Typography>
                    <Box className='cardContainer'>
                        {userGrants
                            .filter(ug => !ug.is_active)
                            .map((currentUserGrant, index) =>
                                <UserGrantCard key={index}
                                    userGrant={currentUserGrant}
                                    userGrantItems={userGrantItems.filter(ugi => ugi.user_grant_id == currentUserGrant.id)}
                                />
                            )}
                    </Box>
                    <Divider sx={{ mt: 2, mb: 2 }} />
                </>}

            <Typography variant='h1'>
                Sharing Permissions
            </Typography>

            <Box className='cardContainer'>
                {userGrants
                    .filter(ug => ug.is_active)
                    .map((currentUserGrant, index) =>
                        <UserGrantCard key={index}
                            userGrant={currentUserGrant}
                            userGrantItems={userGrantItems.filter(ugi => ugi.user_grant_id == currentUserGrant.id)}
                        />
                    )}

                {!Boolean(userGrants?.filter(ug => ug.is_active)?.length) &&
                    <EmptyCard
                        isLoading={isLoading}
                        text="No accounts shared"
                        secondaryText="Check back for pending invitations" />}
            </Box>
        </>
    )
}
