import { useDialog } from '@datanac/datanac-mui-toolkit'
import { AddOutlined, HourglassTopOutlined, QuestionMarkOutlined, WarningOutlined } from '@mui/icons-material'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined'
import EditNoteOutlined from '@mui/icons-material/EditNoteOutlined'
import UploadFileOutlined from '@mui/icons-material/UploadFileOutlined'
import { Box, CircularProgress, Dialog, Divider, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { downloadDocument, downloadDocumentByUrl, getFileIcon, getFileTypeName } from 'api-toolkit/FilesHelper'
import { UsersApiHelper } from 'api/ApiHelper'
import { AppContext } from 'App'
import { getBudgetYears } from 'components/Budget/helpers'
import { FinancialIcon } from 'components/Icons/Icons'
import ImportUploadFileDialog from 'components/Vault/Farm/components/Import/ImportUploadFileDialog'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import WorkspaceViewSelector from 'components/Workspaces/WorkspaceModeSelector'
import _ from 'lodash'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { ButtonLargeSquare } from 'mui-toolkit/ButtonLargeSquare'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatDate } from 'utility/formatters'
import { DOCUMENT_CATEGORY_FARMS, FINANCIAL_DOCUMENTS, TEMPLATE_URL_PATH } from './FinancialHelpers'

export default function FinancialDocumentsWorkspace() {
    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const [currentYear, setCurrentYear] = useState(globalState?.crop_year);

    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'crop_year',
                value: currentYear
            },
        });
    }, [currentYear])

    // --- --- ---

    const [documents, setDocuments] = useState(null);
    const loadDocuments = async () => {
        try {
            setIsLoading(true);

            const _documentsFilter = {
                year: currentYear,
                is_active: true
            }
            const _documents = await UsersApiHelper.users_selectObject("FinancialDocument", _documentsFilter, globalState);
            if (_documents?.length) {
                setDocuments(_documents);
            } else {
                setDocuments(null);
            }
            setIsLoading(false);
        } catch (err) {
            console.warn(err);
            enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (currentYear && globalState?.producer_token) {
            loadDocuments();
        }
    }, [currentYear, globalState?.producer_token]);

    const [isUploadFileDialogOpen, setIsUploadFileDialogOpen] = useState(false);

    const handleDownloadClick = async (_financialDocument) => {
        try {
            await downloadDocument(_financialDocument, globalState)
                .then(isSuccess => {
                    if (!isSuccess) {
                        enqueueSnackbar("There is no file available for this document.", { preventDuplicate: true, variant: 'info' });
                    }
                });
        } catch (err) {
            console.warn(err);
            enqueueSnackbar(err?.detail || err?.message || err, { preventDuplicate: true, variant: 'error' });
        }
    }

    const handlePublicDownloadClick = async (_publicDocument) => {
        const urlResponse = await UsersApiHelper.getPublicDocument({
            document_path: TEMPLATE_URL_PATH,
            document_name: _publicDocument?.templateFileName
        }, globalState);

        if (urlResponse?.url) {
            try {
                await downloadDocumentByUrl(urlResponse?.url, _publicDocument);
            } catch (err) {
                console.warn(err);
                enqueueSnackbar(err?.detail || err?.message || err, { preventDuplicate: true, variant: 'error' });
            }
        }
    }

    const handleDeleteDocumentClick = async (_financialDocument) => {
        confirmDialogActions.confirm({
            title: "Delete this document?",
            prompt: "Are you sure you want to delete this document?",
            promptDetail: null,
            callback: () => {
                handleDeleteDocumentClick_Intern(_financialDocument);
            }
        })
    }

    const handleDeleteDocumentClick_Intern = async (_financialDocument) => {
        try {
            _financialDocument.is_active = false;
            await UsersApiHelper.updateFinancialDocument(_financialDocument, globalState);
            loadDocuments();
        } catch (err) {
            console.warn(err);
            enqueueSnackbar(err?.detail || err?.message || err, { preventDuplicate: true, variant: 'error' });
        }
    }

    // ---

    const [chatMessages, setChatMessages] = useState({
        chatTimestamp: Date(),
        messages: []
    });
    const sendChat = async (text) => {
        const _chatMessages = chatMessages?.messages;
        _chatMessages.push({ message: text, direction: "outgoing" });
        setChatMessages({
            chatTimestamp: Date(),
            messages: _chatMessages
        });

        UsersApiHelper.sendChatRequest({ text }, globalState)
            .then((chatResponse) => {
                const _chatMessages = chatMessages?.messages;
                _chatMessages.push({ message: chatResponse, direction: "incoming" });
                setChatMessages({
                    chatTimestamp: Date(),
                    messages: _chatMessages
                });
            });
    }

    // --- --- ---

    return (
        <>
            <WorkspaceHeader
                title='Documents'
                breadCrumbs={[
                    {
                        title: 'Farm Vault',
                        path: '/vault',
                        icon: <FinancialIcon />
                    },
                ]}
            />

            <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                <Typography variant='h1'>
                    Your Documents
                </Typography>
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <>
                            <Button variant="outlined" {...bindTrigger(popupState)}>
                                {currentYear} <ArrowDropDownOutlined />
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                {getBudgetYears().map((year, index) =>
                                    <MenuItem key={index} onClick={() => {
                                        setCurrentYear(year);
                                        popupState.close();
                                    }}>
                                        {year}
                                    </MenuItem>)}
                            </Menu>
                        </>
                    )}
                </PopupState>
                <WorkspaceViewSelector workspaceMode={workspaceMode} setWorkspaceMode={setWorkspaceMode} />
                {isLoading && <CircularProgress />}
            </Stack>

            <Stack direction="column">
                {Boolean(FINANCIAL_DOCUMENTS?.length)
                    && (_.uniq(FINANCIAL_DOCUMENTS.map(cat => cat.category))).map((category, index) => <Box key={index}>
                        <Box sx={{ mb: 1 }}>
                            <Typography sx={{ textTransform: "capitalize", mb: 1 }}>
                                {category}
                            </Typography>

                            <Box className='flexWrapContainer' sx={{ alignItems: "stretch", mb: 2 }}>
                                {FINANCIAL_DOCUMENTS?.filter(doc => doc.category == category)?.map((template, documentIndex) => {
                                    const myDocuments = documents
                                        ?.filter(doc => doc.document_type_name == template.document_type_name);
                                    if (myDocuments?.length) {
                                        return myDocuments?.map((currentDocument, currentDocumentIndex) => {
                                            const fileTypeName = getFileTypeName(currentDocument);
                                            if (fileTypeName != "Other") {
                                                return <Box key={documentIndex * 1000 + currentDocumentIndex}>
                                                    <ButtonLargeSquare
                                                        icon={getFileIcon(currentDocument)}
                                                        title={currentDocument?.document_type_name}
                                                        subHeader={formatDate(currentDocument?.lastupdate)}
                                                        onClick={() => handleDownloadClick(currentDocument)}
                                                        action={() => handleDeleteDocumentClick(currentDocument)}
                                                        actionIcon={<DeleteForeverOutlined />}
                                                    />
                                                </Box>
                                            } else {
                                                return <Box key={documentIndex * 1000 + currentDocumentIndex}>
                                                    <ButtonLargeSquare
                                                        sx={{ backgroundColor: "#efefef" }}
                                                        icon={getFileIcon(currentDocument)}
                                                        title={template?.document_type_name}
                                                        subHeader={"Entered Manually"}
                                                        action={() => handleDeleteDocumentClick(currentDocument)}
                                                        actionIcon={<DeleteForeverOutlined />}
                                                    />
                                                </Box>
                                            }
                                        })
                                    } else {
                                        if (isLoading) {
                                            return <Box key={documentIndex}>
                                                <ButtonLargeSquare
                                                    sx={{}}
                                                    icon={<HourglassTopOutlined sx={{ fontSize: "3rem", color: "GrayText" }} />}
                                                    title={template?.document_type_name}
                                                    subHeader={"Loading..."}
                                                />
                                            </Box>
                                        }
                                        else if (template?.category == DOCUMENT_CATEGORY_FARMS) {
                                            // Not found: optional documents
                                            return <Box key={documentIndex}>
                                                <ButtonLargeSquare
                                                    sx={{}}
                                                    icon={<QuestionMarkOutlined sx={{ fontSize: "3rem", color: "GrayText" }} />}
                                                    title={template?.document_type_name}
                                                    subHeader={template?.subHeader || " "}
                                                    disabled={true}
                                                    action={() => setIsUploadFileDialogOpen(true)}
                                                    actionIcon={<AddOutlined />}
                                                />
                                            </Box>
                                        } else {
                                            // Not found: required documents
                                            return <Box key={documentIndex}>
                                                <ButtonLargeSquare
                                                    sx={{}}
                                                    icon={<WarningOutlined sx={{ fontSize: "3rem", color: "red", opacity: 0.7 }} />}
                                                    title={template?.document_type_name}
                                                    subHeader={template?.subHeader || " "}
                                                    disabled={true}
                                                    action={() => setIsUploadFileDialogOpen(true)}
                                                    actionIcon={<AddOutlined />}
                                                />
                                            </Box>
                                        }
                                    }
                                })}
                            </Box>
                        </Box>
                    </Box>)}
                <br />
            </Stack >

            <Divider />

            <Typography variant='h1'>
                Templates
            </Typography>

            <Box className='flexWrapContainer' sx={{ alignItems: "stretch" }}>
                {FINANCIAL_DOCUMENTS
                    ?.filter(doc => doc.templateFileName)
                    ?.map((d, index) =>
                        <Box key={index}>
                            <ButtonLargeSquare
                                icon={<EditNoteOutlined fontSize="large" />}
                                title={d.title}
                                subHeader={d.subHeader || " "}
                                onClick={() => handlePublicDownloadClick(d)}
                            />
                        </Box>
                    )}
            </Box>

            <WorkspaceActionBar content={<>
                <Stack direction="row" spacing={2}>
                    <PopupState variant="popover" popupId="file-menu-popper">
                        {(popupState) => (
                            <>
                                <Button variant="contained" onClick={() => {
                                    setIsUploadFileDialogOpen(true);
                                }}>
                                    <UploadFileOutlined sx={{ mr: 1 }} />
                                    Add Document
                                </Button>
                                {/* <Button variant="contained" {...bindTrigger(popupState)}>
                                    <ArrowDropDownOutlined sx={{ mr: 1 }} />
                                    Add Document
                                </Button>
                                <Menu {...bindMenu(popupState)}>
                                    <MenuItem onClick={() => {
                                        setIsUploadFileDialogOpen(true);
                                        popupState.close();
                                    }}>
                                        <UploadFileOutlined sx={{ mr: 1 }} /> Add a Document ...
                                    </MenuItem>
                                </Menu> */}
                            </>
                        )}
                    </PopupState>

                    {/* 
                    <PopupState variant="popper" popupId="chat-popper">
                        {(popupState) => (
                            <>
                                <Button variant="contained" {...bindToggle(popupState)}>
                                    <AssistantOutlined />
                                </Button>
                                <Popper {...bindPopper(popupState)}>
                                    <Box sx={{ minWidth: "300px", width: "25vw", height: "calc(100vh - 100px)", paddingRight: "16px", paddingTop: "4px" }}>
                                        <MainContainer sx={{ borderRadius: "8px !important" }}>
                                            <ChatContainer>
                                                <MessageList sx={{ flex: 1 }}>
                                                    {chatMessages?.messages?.map((m, index) =>
                                                        <MessageGroup key={index}
                                                            direction={m?.direction || "outgoing"}
                                                            sender={m?.direction == "incoming" ? "Tilley" : ""}
                                                        >
                                                            {m?.direction == "incoming" &&
                                                                <Avatar src="/favicon-32x32.png" name="Tilley" />
                                                            }
                                                            <MessageGroup.Messages>
                                                                <Message key={index}
                                                                    model={{
                                                                        message: m?.message || "",
                                                                        sentTime: "just now",
                                                                    }} />
                                                            </MessageGroup.Messages>
                                                        </MessageGroup>
                                                    )}
                                                </MessageList>
                                                <MessageInput placeholder='Ask me a question' attachButton={false} onSend={sendChat} />
                                            </ChatContainer>
                                        </MainContainer>
                                    </Box>
                                </Popper>
                            </>
                        )}
                    </PopupState> 
                    */}

                </Stack>
            </>} />


            {
                isUploadFileDialogOpen && <Dialog open={isUploadFileDialogOpen} fullWidth={isSmall} PaperProps={{ sx: {} }}>
                    <ImportUploadFileDialog year={currentYear} onClose={() => { setIsUploadFileDialogOpen(false); loadDocuments(); }} />
                </Dialog>
            }


            {confirmDialogComponent}
        </>
    )
}
