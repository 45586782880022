import { AddCircleOutline, CloseOutlined, EastOutlined, WestOutlined } from "@mui/icons-material";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, Stack, Step, StepLabel, Stepper, Typography, useMediaQuery } from "@mui/material";
import { AppContext } from "App";
import { RmaApiHelper, UsersApiHelper } from "api/ApiHelper";
import AutoCompleteTextField from "components/Core/AutoCompleteTextField";
import DataFormAutoComplete from "components/Core/DataFormAutoComplete";
import DataFormSelect from "components/Core/DataFormSelect";
import { MASK_NUMBER_UNFORMATTED, MASK_NUMERIC, StatefulControlledTextField } from "components/Core/StatefulControlledTextField";
import { propUpdateReducer } from "components/Core/propUpdateReducer";
import { useSnackbar } from "notistack";
import { useEffect, useMemo } from "react";
import { Component, useContext, useReducer, useState } from "react";
import { formatNumber } from "utility/formatters";
import { v4 as uuidv4, v4 } from 'uuid'

export const ProducerFarmFieldCropDialog = ({
    crop_year,
    location_state_name,
    location_county_name,

    onClose
}) => {

    const { globalState } = useContext(AppContext);

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const isLg = useMediaQuery('(min-width:1440px)');

    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);

    // --- --- ---

    const [state, dispatch] = useReducer(propUpdateReducer, {
        crop_year: crop_year,
        is_active: true,
        location_state_name,
        location_county_name,
    });

    const handleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        if (event?.target?.name == "commodity_name" && value != state.commodity_name) {
            dispatch({
                type: 'updateMany',
                payload: {
                    commodity_name: value,
                    type_name: '',
                    practice_name: ''
                }
            });
        } else {
            dispatch({
                type: 'update',
                payload: { key: event.target.name, value: value },
            });
        }
    }

    // ---

    const STEP_KEY_COUNTY = "county";
    const STEP_KEY_FARM = "farm";
    const STEP_KEY_CROP = "crop";

    const [activeStep, setActiveStep] = useState(0);
    const steps = [
        {
            label: 'County',
            description: 'Select a state & county',
            key: STEP_KEY_COUNTY,
            isValid: () => (
                state?.location_state_name
                && state?.location_county_name
            )
        },
        {
            label: 'Farm',
            description: '',
            key: STEP_KEY_FARM,
            isValid: () => (
                state?.fsa_farm_number
                && state?.fsa_tract_number
                && state?.fsa_field_number
            )
        },
        {
            label: 'Crop',
            description: '',
            key: STEP_KEY_CROP,
            isValid: () => (
                state?.location_state_name
                && state?.location_county_name

                && state?.fsa_farm_number
                && state?.fsa_tract_number
                && state?.fsa_field_number

                && state?.crop_year
                && state?.commodity_name
                && state?.type_name
                && state?.practice_name
                && state?.reported_acreage
                && state?.share_percent

                && state?.approved_yield
                && state?.rate_yield
            )
        },
    ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    class HorizontalStepContent extends Component {
        render() {
            const thisStep = this?.props?.step;

            return (
                <>
                    {steps[activeStep]?.key == thisStep?.key &&
                        <Typography>{thisStep?.description}</Typography>}
                </>
            );
        }
    }

    // ---

    const [producerFarms, setProducerFarms] = useState(null);
    const loadProducerFarms = async () => {
        try {
            const _farmsFilter = {
                producer_token: globalState.producer_token,
                location_state_name: state?.location_state_name,
                location_county_name: state?.location_county_name,
                crop_year: state?.crop_year,
                is_active: true
            };
            const farms = await UsersApiHelper.selectProducerFarms(_farmsFilter, globalState);
            if (farms?.length) {
                setProducerFarms(farms);
            } else {
                setProducerFarms(null);
            }
        } catch (err) {
            setProducerFarms(null);
            console.warn(err);
            enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (globalState?.producer_token && state?.location_state_name && state?.location_county_name && state?.crop_year) {
            loadProducerFarms();
        }
    }, [globalState?.producer_token, state?.location_state_name, state?.location_county_name, state?.crop_year]);

    useEffect(() => {
        if (globalState?.producer_token && producerFarms && state?.fsa_farm_number) {
            // Assign farm ID if exists:
            const _producer_farm_id = producerFarms?.find(f =>
                f.fsa_farm_number == state?.fsa_farm_number
            )?.id;

            if (_producer_farm_id) {
                dispatch({
                    type: 'update',
                    payload: { key: "producer_farm_id", value: _producer_farm_id },
                });
            } else {
                dispatch({
                    type: 'update',
                    payload: { key: "producer_farm_id", value: null },
                });
            }
        }
    }, [globalState?.producer_token, producerFarms, state?.fsa_farm_number]);

    // --- 

    const [producerFarmFields, setProducerFarmFields] = useState(null);
    const loadProducerFarmFields = async () => {
        try {
            const _producer_farm_id = producerFarms?.find(f =>
                f.fsa_farm_number == state?.fsa_farm_number
            )?.id;

            if (_producer_farm_id) {
                const fieldsFilter = {
                    producer_token: globalState.producer_token,
                    producer_farm_id: _producer_farm_id,
                    is_active: true
                };
                const fields = await UsersApiHelper.selectProducerFarmFields(fieldsFilter, globalState);
                if (fields?.length) {
                    setProducerFarmFields(fields);
                } else {
                    setProducerFarmFields(null);
                }
            } else {
                setProducerFarmFields(null);
            }
        } catch (err) {
            setProducerFarmFields(null);
            console.warn(err);
            enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (globalState?.producer_token && producerFarms && state?.fsa_farm_number) {
            loadProducerFarmFields();
        }
    }, [globalState?.producer_token, producerFarms, state?.fsa_farm_number]);

    useEffect(() => {
        if (globalState?.producer_token && producerFarmFields && state?.fsa_tract_number && state?.fsa_field_number) {
            // Assign field ID if exists:
            const _producer_farm_field_id = producerFarmFields?.find(f =>
                f.fsa_tract_number == state?.fsa_tract_number
                && f.fsa_field_number == state?.fsa_field_number
            )?.id;

            if (_producer_farm_field_id) {
                dispatch({
                    type: 'update',
                    payload: { key: "producer_farm_field_id", value: _producer_farm_field_id },
                });
            } else {
                dispatch({
                    type: 'update',
                    payload: { key: "producer_farm_field_id", value: null },
                });
            }
        }
    }, [globalState?.producer_token, producerFarmFields, state?.fsa_tract_number, state?.fsa_field_number]);

    // --- 

    const commodityDataFormSelect = useMemo(() => {
        return (<DataFormSelect
            value={state.commodity_name || ""}
            name="commodity_name"
            isRequired={false}
            onChange={handleChangeState}
            objectName="InsuranceOffer"
            objectNameLabel="Commodity"
            itemFilter={{
                reinsurance_year: String(state.crop_year),
                state_name: state.location_state_name,
                county_name: state.location_county_name,
            }}
            itemGetKey={(item) => item.commodity_name}
            itemGetLabel={(item) => item.commodity_name}
            doFetch={
                state.crop_year != null
                && state?.location_state_name
                && state?.location_county_name
            }
            isLoading={isLoading}
        />);
    }, [state.crop_year, state?.location_state_name, state?.location_county_name, state.commodity_name, isLoading]);

    const typeDataFormSelect = useMemo(() => {
        return (<DataFormSelect
            value={state.type_name || ""}
            name="type_name"
            isRequired={false}
            onChange={handleChangeState}
            objectName="InsuranceOffer"
            objectNameLabel="Commodity"
            itemFilter={{
                reinsurance_year: String(state.crop_year),
                state_name: state.location_state_name,
                county_name: state.location_county_name,
                commodity_name: state.commodity_name,
            }}
            itemGetKey={(item) => item.type_name}
            itemGetLabel={(item) => item.type_name}
            doFetch={
                state.crop_year != null
                && state?.location_state_name
                && state?.location_county_name
                && state.commodity_name
            }
            isLoading={isLoading}
        />);
    }, [state?.location_state_name, state?.location_county_name, state.commodity_name, state.type_name,
        isLoading]);

    const practiceDataFormSelect = useMemo(() => {
        return (<DataFormSelect
            value={state.practice_name || ""}
            name="practice_name"
            isRequired={false}
            onChange={handleChangeState}
            objectName="InsuranceOffer"
            objectNameLabel="Practice"
            itemFilter={{
                reinsurance_year: String(state.crop_year),
                state_name: state.location_state_name,
                county_name: state.location_county_name,
                commodity_name: state.commodity_name,
                type_name: state.type_name
            }}
            itemGetKey={(item) => item.practice_name}
            itemGetLabel={(item) => item.practice_name}
            doFetch={
                state.crop_year != null
                && state?.location_state_name
                && state?.location_county_name
                && state.commodity_name
                && state.type_name
            }
            isLoading={isLoading}
        />);
    }, [state?.location_state_name, state?.location_county_name, state.commodity_name, state.type_name, state.practice_name,
        isLoading]);

    // ---

    const [countyData, setCountyData] = useState({});
    const loadCountyData = async () => {
        const countyParameters = {
            reinsurance_year: String(state?.crop_year),
            state_name: state.location_state_name,
            county_name: state.location_county_name,
            commodity_name: state.commodity_name,
            type_name: state.type_name,
            practice_name: state.practice_name
        };

        RmaApiHelper.selectCountyData(countyParameters).then(_prices => {
            if (_prices?.length) {
                setCountyData(_prices[0]);
            }
        });
    }

    useEffect(() => {
        if (
            state?.location_state_name
            && state?.location_county_name
            && state?.crop_year
            && state?.commodity_name
            && state?.type_name
            && state?.practice_name
        ) {
            loadCountyData();
        }
    }, [state?.location_state_name, state?.location_county_name, state?.crop_year, state?.commodity_name, state?.type_name, state?.practice_name])

    // ---

    const handleAddClick = async () => {
        let _producer_farm_id = state?.producer_farm_id;
        let _producer_farm_field_id = state?.producer_farm_field_id;

        if (!_producer_farm_id) {
            _producer_farm_id = uuidv4();

            const _newProducerFarmState = { ...state };
            _newProducerFarmState.id = _producer_farm_id;
            await UsersApiHelper.updateProducerFarm(_newProducerFarmState, globalState);
        }

        if (!_producer_farm_field_id) {
            _producer_farm_field_id = uuidv4();

            const _newProducerFarmFieldState = {
                ...state,
                producer_farm_id: _producer_farm_id,
            };
            _newProducerFarmFieldState.id = _producer_farm_field_id;
            await UsersApiHelper.updateProducerFarmField(_newProducerFarmFieldState, globalState);
        }

        if (_producer_farm_id && _producer_farm_field_id) {
            const _newProducerFarmFieldCropState = {
                ...state,
                producer_farm_id: _producer_farm_id,
                producer_farm_field_id: _producer_farm_field_id,
            };
            _newProducerFarmFieldCropState.id = uuidv4();
            await UsersApiHelper.updateProducerFarmFieldCrop(_newProducerFarmFieldCropState, globalState);
            onClose && onClose();
        }
    }

    // --- --- ---

    return (<>
        <DialogTitle>
            Add a Crop
        </DialogTitle>
        <DialogContent>
            {steps[activeStep].key == STEP_KEY_COUNTY && <>
                <Typography variant='h1'>
                    County
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <DataFormSelect
                            value={state.location_state_name}
                            name="location_state_name"
                            onChange={handleChangeState}
                            objectName="State"
                            itemFilter={{ reinsurance_year: state.crop_year }}
                            itemGetKey={item => item.state_name}
                            itemGetLabel={item => item.state_name}
                            doFetch={state.crop_year != null}
                            isLoading={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DataFormSelect
                            value={state.location_county_name}
                            name="location_county_name"
                            onChange={handleChangeState}
                            objectName="County"
                            itemFilter={{
                                reinsurance_year: state.crop_year,
                                state_name: state.location_state_name,
                            }}
                            itemGetKey={item => item.county_name}
                            itemGetLabel={item => item.county_name}
                            doFetch={state.crop_year && state.location_state_name}
                            isLoading={false}
                        />
                    </Grid>
                </Grid>
            </>}

            {steps[activeStep].key == STEP_KEY_FARM && <>
                <Typography variant='h1'>
                    Farm
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <AutoCompleteTextField
                            label="FSN #"
                            name="fsa_farm_number"
                            value={state.fsa_farm_number || ''}
                            onChange={handleChangeState}
                            values={producerFarms || []}
                            itemGetLabel={item => item?.fsa_farm_number}
                            isRequired={false}
                            disabled={false}
                            freeSolo={true}
                            maskType={MASK_NUMBER_UNFORMATTED}
                            doShowLabel={true}
                        />
                    </Grid>
                    {!isSmall && <Grid item xs={12} md={6}>
                    </Grid>}

                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl fullWidth={true}>
                            <AutoCompleteTextField
                                label="Tract #"
                                name="fsa_tract_number"
                                value={state.fsa_tract_number || ''}
                                onChange={handleChangeState}
                                values={producerFarmFields || []}
                                itemGetLabel={item => item?.fsa_tract_number}
                                isRequired={false}
                                disabled={false}
                                freeSolo={true}
                                maskType={MASK_NUMBER_UNFORMATTED}
                                doShowLabel={true}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl fullWidth={true}>
                            <AutoCompleteTextField
                                label="Field #"
                                name="fsa_field_number"
                                value={state.fsa_field_number || ''}
                                onChange={handleChangeState}
                                values={producerFarmFields || []}
                                itemGetLabel={item => item?.fsa_field_number}
                                isRequired={false}
                                disabled={false}
                                freeSolo={true}
                                doShowLabel={true}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </>}

            {steps[activeStep].key == STEP_KEY_CROP && <>
                <Typography variant='h1'>
                    Crop Profile
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} lg={3}>
                        {commodityDataFormSelect}
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        {typeDataFormSelect}
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        {practiceDataFormSelect}
                    </Grid>

                    <Grid item xs={0} md={12}>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Acres"
                                name="reported_acreage"
                                isRequired={true}
                                is
                                defaultValue={state.reported_acreage || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                maskType={MASK_NUMERIC}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Share %"
                                name="share_percent"
                                isRequired={true}
                                is
                                defaultValue={state.share_percent || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                maskType={MASK_NUMERIC}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Divider />

                <Grid container spacing={2}>
                    <Grid item xs={0} md={12}>
                        <Typography variant='h1'>
                            Yields
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Approved Yield (APH)"
                                name="approved_yield"
                                isRequired={true}
                                is
                                defaultValue={state.approved_yield || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                maskType={MASK_NUMERIC}
                            />
                        </FormControl>

                        <br />

                        {countyData?.expected_index_value &&
                            <Box sx={{
                                border: "1px solid #f8f8f8",
                                backgroundColor: "#fcfcfc",
                                borderRadius: "7px",
                                mt: 1,
                                p: 1
                            }}>
                                <Typography variant="body2" color="secondary">RMA Expected Yield</Typography>
                                <Typography variant="body1">{formatNumber(countyData?.expected_index_value)}</Typography>
                            </Box>}
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Rate Yield"
                                name="rate_yield"
                                isRequired={true}
                                is
                                defaultValue={state.rate_yield || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                maskType={MASK_NUMERIC}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Adjusted Yield"
                                name="adjusted_yield"
                                isRequired={false}
                                is
                                defaultValue={state.adjusted_yield || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                maskType={MASK_NUMERIC}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Average Yield"
                                name="average_yield"
                                isRequired={false}
                                is
                                defaultValue={state.average_yield || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                maskType={MASK_NUMERIC}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </>}
        </DialogContent>

        <DialogActions>
            {!isSmall &&
                <Stepper activeStep={activeStep} orientation="horizontal"
                    sx={{ flexGrow: "1", mr: (isLg ? "200px" : 0) }}
                >
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepLabel
                                optional={null}
                            >
                                {step.label || ''}
                            </StepLabel>
                            <HorizontalStepContent step={step}>
                                <Typography>{step.description}</Typography>
                            </HorizontalStepContent>
                        </Step>))}
                </Stepper>
            }

            <Stack direction="row" spacing={1} justifyContent='flex-end'>
                <Button variant='contained' color='secondary' autoFocus onClick={onClose} sx={{ mr: { xs: 0, lg: 5 } }}>
                    <CloseOutlined sx={{ mr: 1 }} />
                    Cancel
                </Button>

                {!isSmall && <Button
                    variant="contained" color="primary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                >
                    <WestOutlined sx={{ mr: 1 }} />
                    Back
                </Button>}

                {!(steps[activeStep]?.key == STEP_KEY_CROP) &&
                    <Button
                        variant="contained" color="primary"
                        onClick={handleNext}
                        disabled={!steps[activeStep].isValid()}
                    >
                        {activeStep === steps.length - 1 ? 'Next' : 'Next'}
                        <EastOutlined sx={{ ml: 1 }} />
                    </Button>}

                {steps[activeStep]?.key == STEP_KEY_CROP &&
                    <Button variant="contained"
                        disabled={!steps[activeStep].isValid()}
                        onClick={handleAddClick}>
                        Add <AddCircleOutline sx={{ ml: 1 }} />
                    </Button>}
            </Stack>
        </DialogActions>
    </>)
}