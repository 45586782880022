import { useDialog } from '@datanac/datanac-mui-toolkit'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import { Alert, Box, CircularProgress, Divider, FormControl, Grid, Menu, MenuItem, Stack, Table, TableCell, TableHead, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { AppContext } from 'App'
import { UsersApiHelper } from 'api/ApiHelper'
import { getBudgetYears } from 'components/Budget/helpers'
import { MASK_NUMERIC, StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import { FinancialIcon } from 'components/Icons/Icons'
import { MODULE_FINANCIAL } from 'components/Menu/NavigationMenu'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { ValueLabel } from 'mui-toolkit/ValueLabel'
import { useFormStatus } from 'mui-toolkit/useFormStatus'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { formatPrice } from 'utility/formatters'
import { v4 as uuidv4 } from 'uuid'
import useFinanceReport from '../useFinanceReport'
import { TableRow } from '@aws-amplify/ui-react'

export default function ScheduleFWorkspace() {
    const { globalState, globalDispatch, session } = useContext(AppContext);

    const navigate = useNavigate();
    const [queryString] = useSearchParams();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar } = useSnackbar();
    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const { component: formStatusComponent, actions: formStatusActions } = useFormStatus();

    const [currentYear, setCurrentYear] = useState(queryString.get("year") || globalState.crop_year);

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const {
        isLoading,

        isLoadingScheduleF,
        scheduleF,
        loadScheduleF,
    } = useFinanceReport({
        onError: handleError,
        currentYear,

        loadModules: [MODULE_FINANCIAL]
    })

    useEffect(() => {
        if (currentYear != queryString.get("year")) {
            globalDispatch({
                type: 'update',
                payload: {
                    key: 'crop_year',
                    value: currentYear
                },
            });
        }
    }, [currentYear])

    const calculateContainersIncome = () => {
        return _.uniq(scheduleF?.income?.values?.map(v => v.container));
    }

    const calculateContainersExpenses = () => {
        return _.uniq(scheduleF?.expenses?.values?.map(v => v.container));
    }

    // --- --- ---

    const handleChangeState_LineItem = async (event, _currentDocumentDictionary) => {
        if (event?.target?.value != scheduleF?.values.find(v => v.id == _currentDocumentDictionary?.id)?.value) {
            formStatusActions.setIsFormDirty(true, true);

            const _newValue = {
                ..._currentDocumentDictionary,
                id: _currentDocumentDictionary?.id || uuidv4(),
                value: event?.target?.value || 0
            }

            const _uploadFormData = {
                producer_token: globalState.producer_token,
                agent_token: globalState.agent_token,
                Document: {
                    id: _newValue?.document_id || uuidv4(),
                    values: [_newValue]
                }
            };

            await UsersApiHelper.updateFinancialDocument(scheduleF, globalState);

            UsersApiHelper.updateDocumentDictionary(_uploadFormData, globalState)
                .then(() => {
                    formStatusActions.setIsSaving(false);
                    loadScheduleF();
                })
                .catch(err => {
                    console.warn(err);
                    formStatusActions.setIsFormDirty(true, false);
                    enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
                });
        }
    }

    const formatName = (value) => {
        if (value) {
            return value.replaceAll("_", " ")
        }
    }

    // --- --- ---

    return (
        <>
            <WorkspaceHeader
                title='Schedule F'
                subHeader='Form 1040'
                breadCrumbs={[
                    {
                        title: 'Financial Documents',
                        path: '/financial/documents',
                        icon: <FinancialIcon />
                    },
                ]}
            />

            <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                <Typography variant='h1'>
                    Year
                </Typography>
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <>
                            <Button variant="outlined" {...bindTrigger(popupState)}>
                                {currentYear} <ArrowDropDownOutlined />
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                {getBudgetYears().map((year, index) =>
                                    <MenuItem key={index} onClick={() => {
                                        setCurrentYear(year);
                                        popupState.close();
                                    }}>
                                        {year}
                                    </MenuItem>)}
                            </Menu>
                        </>
                    )}
                </PopupState>
                {isLoading && <CircularProgress />}
                {formStatusComponent}
            </Stack>

            {scheduleF?.values?.length == 0 && <>
                <Alert severity="warning">
                    No Schedule F form is found.
                    <Divider />
                    Please upload or enter the document and try again.
                </Alert>
            </>}

            {Boolean(scheduleF?.values?.length) && <>
                <Typography variant="h6" sx={{ mb: 2 }}>Part I - Farm Income</Typography>
                {calculateContainersIncome()?.map(container =>
                    <Box key={container} sx={{ mt: 2, mb: 2 }}>
                        <Typography variant="h6" sx={{ mb: 2, textTransform: "capitalize" }}>
                            {formatName(container)}
                        </Typography>

                        <Grid container spacing={2}>
                            {Boolean(scheduleF?.values?.length)
                                && scheduleF?.values?.filter(v => v.container == container).map((v, index) =>
                                    <Grid item key={index} xs={6}>
                                        <FormControl fullWidth={true}>
                                            <StatefulControlledTextField
                                                label={formatName(v.item)}
                                                name={v.item}
                                                isRequired={false}
                                                defaultValue={v.value}
                                                variant="outlined"
                                                onChange={(e) => handleChangeState_LineItem(e, v)}
                                                InputLabelProps={{ shrink: true, sx: { textTransform: "capitalize" } }}
                                                maskType={MASK_NUMERIC}
                                            />
                                        </FormControl>

                                        <br />
                                    </Grid>
                                )}
                        </Grid>
                    </Box>
                )}

                <Typography variant="h6" sx={{ mb: 2 }}>Part II - Farm Expenses</Typography>
                {calculateContainersExpenses()?.map(container =>
                    <Box key={container} sx={{ mt: 2, mb: 2 }}>
                        <Typography variant="h6" sx={{ mb: 2, textTransform: "capitalize" }}>
                            {formatName(container)}
                        </Typography>

                        <Grid container spacing={2}>
                            {Boolean(scheduleF?.values?.length)
                                && scheduleF?.values?.filter(v => v.container == container).map((v, index) =>
                                    <Grid item key={index} xs={6}>
                                        <FormControl fullWidth={true}>
                                            <StatefulControlledTextField
                                                label={formatName(v.item)}
                                                name={v.item}
                                                isRequired={false}
                                                defaultValue={v.value}
                                                variant="outlined"
                                                onChange={(e) => handleChangeState_LineItem(e, v)}
                                                InputLabelProps={{ shrink: true, sx: { textTransform: "capitalize" } }}
                                                maskType={MASK_NUMERIC}
                                            />
                                        </FormControl>

                                        <br />
                                    </Grid>
                                )}
                        </Grid>
                    </Box>
                )}
            </>}

            <WorkspaceActionBar content={<>
            </>} />

            {confirmDialogComponent}
        </>
    )
}
